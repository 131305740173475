import React, { Fragment, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { Dialog, Transition } from '@headlessui/react';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { ReactComponent as Select } from '../../assets/images/select_for_role.svg';
import { ReactComponent as DeSelect } from '../../assets/images/deselect.svg';
import dayjs from 'dayjs';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ExportReport = ({ ExportPop, setExportPop, headers, MaxCount }) => {
  const [CustomValue, setCustomValue] = useState({
    start: '',
    end: '',
  });
  const [Start, setStart] = useState('');
  const [End, setEnd] = useState('');
  const [IsAll, setIsAll] = useState(false);

  const exportApi = () => {
    if (CustomValue.start || CustomValue.end) {
      const body = {
        start_date: CustomValue.start,
        end_date: CustomValue.end,
        is_all: IsAll,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/user/list/partner/xoxo_days/export`, body, {
          headers,
        })
        .then((response) => {
          const fileUrl = response.data.data[0].url;

          // Create a new link element
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank'; // Open in a new tab (if needed)
          link.download = 'xoxo_days_report.xlsx'; // Specify the filename

          // Append the link to the document body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
          setExportPop(false);
          setStart('');
          setEnd('');
          setCustomValue({ ...CustomValue, start: '', end: '' });
        })
        .catch((error) => {
          const finalLink = error.response.data;
          toast.error(finalLink.message);
        });
    } else {
      toast.error('Give Start Date & End Date');
    }
  };

  return (
    <Transition appear show={ExportPop} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={() => {
          setExportPop(false);
          setStart('');
          setEnd('');
          setCustomValue({ ...CustomValue, start: '', end: '' });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" w-[30%] flex flex-col items-center transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center head border-b w-full p-4 2xl:text-[18px] xl:text-[16px] lg:text-[14px] font-semibold">
                  Export Report
                  <Close
                    className="cursor-pointer"
                    onClick={() => {
                      setExportPop(false);
                      setStart('');
                      setEnd('');
                      setCustomValue({ ...CustomValue, start: '', end: '' });
                    }}
                  />
                </div>
                <div className="main-content w-full">
                  <div className="form flex flex-col px-6 pt-4">
                    <span className="text-[#6F6F6F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] gap-2 flex flex-col">
                      <div>
                        Start Date
                        <div className="border border-[#E6E6E6] py-[5px] px-[10px] rounded-[10px]">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              sx={{
                                width: '100%',
                                // .MuiInputBase-root-MuiFilledInput-root.Mui-error:before, .MuiInputBase-root-MuiFilledInput-root.Mui-error:after
                                '& .MuiInputBase-root.MuiFilledInput-root.Mui-error': {
                                  '&:before': {
                                    border: 'none !important',
                                    borderBottom: '0px !important',
                                  },
                                  '&:after': {
                                    borderBottom: '0px !important',
                                    border: 'none !important',
                                  },
                                },

                                '& .MuiInputBase-root.MuiFilledInput-root': {
                                  '&:before': {
                                    border: 'none !important',
                                    borderBottom: '0px !important',
                                  },
                                  '&:after': {
                                    borderBottom: '0px !important',
                                    border: 'none !important',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  bgcolor: 'white !important',
                                  width: '100% !important',
                                  '& .MuiInputBase-input': {
                                    width: '100%',
                                    fontFamily: 'poppins',
                                    padding: '0px',
                                    borderBottom: '0px !important',
                                    '&:before': {
                                      border: 'none !important',
                                      borderBottom: '0px !important',
                                    },
                                    '&:after': {
                                      borderBottom: '0px !important',
                                      border: 'none !important',
                                    },
                                  },
                                  '& .MuiSvgIcon-root': {
                                    color: 'black',
                                  },
                                  '& .MuiFilledInput-root': {
                                    '&:before': {
                                      border: 'none !important',
                                      borderBottom: 'none !important',
                                    },
                                    '&:after': {
                                      borderBottom: 'none !important',
                                      border: 'none !important',
                                    },
                                  },
                                  '& .MuiFilledInput-input': {
                                    paddingTop: '0px !important',
                                  },
                                  '& .MuiIconButton-root': {
                                    paddingBottom: '13px !important',
                                  },
                                },
                              }}
                              slotProps={{ textField: { variant: 'filled' } }}
                              format="DD/MM/YYYY"
                              value={Start} // Replace with your initial date value if needed
                              onChange={(e) => {
                                const date = e;
                                setStart(date);
                                setEnd('');
                                let _date = moment(date?.$d).format('YYYY-MM-DD');
                                setCustomValue({ ...CustomValue, start: _date, end: '' });
                              }}
                              disableFuture
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div>
                        End Date
                        <div className="border border-[#E6E6E6] py-[5px] px-[10px] rounded-[10px]">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              sx={{
                                width: '100%',
                                // .MuiInputBase-root-MuiFilledInput-root.Mui-error:before, .MuiInputBase-root-MuiFilledInput-root.Mui-error:after
                                '& .MuiInputBase-root.MuiFilledInput-root.Mui-error': {
                                  '&:before': {
                                    border: 'none !important',
                                    borderBottom: '0px !important',
                                  },
                                  '&:after': {
                                    borderBottom: '0px !important',
                                    border: 'none !important',
                                  },
                                },

                                '& .MuiInputBase-root.MuiFilledInput-root': {
                                  '&:before': {
                                    border: 'none !important',
                                    borderBottom: '0px !important',
                                  },
                                  '&:after': {
                                    borderBottom: '0px !important',
                                    border: 'none !important',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  bgcolor: 'white !important',
                                  width: '100% !important',
                                  '& .MuiInputBase-input': {
                                    width: '100%',
                                    fontFamily: 'poppins',
                                    padding: '0px',
                                    borderBottom: '0px !important',
                                    '&:before': {
                                      border: 'none !important',
                                      borderBottom: '0px !important',
                                    },
                                    '&:after': {
                                      borderBottom: '0px !important',
                                      border: 'none !important',
                                    },
                                  },
                                  '& .MuiSvgIcon-root': {
                                    color: 'black',
                                  },
                                  '& .MuiFilledInput-root': {
                                    '&:before': {
                                      border: 'none !important',
                                      borderBottom: 'none !important',
                                    },
                                    '&:after': {
                                      borderBottom: 'none !important',
                                      border: 'none !important',
                                    },
                                  },
                                  '& .MuiFilledInput-input': {
                                    paddingTop: '0px !important',
                                  },
                                  '& .MuiIconButton-root': {
                                    paddingBottom: '13px !important',
                                  },
                                },
                              }}
                              slotProps={{ textField: { variant: 'filled' } }}
                              format="DD/MM/YYYY"
                              value={End} // Replace with your initial date value if needed
                              onChange={(e) => {
                                const date = e;
                                let _date = moment(date?.$d).format('YYYY-MM-DD');
                                // console.log(
                                //   'first',
                                //   Start,
                                //   moment(date?.$d).isSameOrBefore(moment(Start.$d))
                                // );
                                if (Start && moment(date?.$d).isSameOrBefore(moment(Start.$d))) {
                                  toast.error('End date must be greater than start date');
                                } else {
                                  setEnd(date);
                                  setCustomValue({ ...CustomValue, end: _date });
                                }
                              }}
                              disableFuture
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => setIsAll(!IsAll)}
                      >
                        Is All
                        {IsAll ? <Select className="ml-2" /> : <DeSelect className="ml-2" />}
                      </div>
                    </span>
                    <br />
                    <div className="flex justify-center">
                      <div
                        className="cursor-pointer text-center w-[80%] text-[#FFFFFF] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] py-[5px]"
                        onClick={() => {
                          exportApi();
                        }}
                      >
                        Export
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
