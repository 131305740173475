import { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';

export default function MealInputDropdown({
  query,
  setQuery,
  selected,
  setSelected,
  data,
  className,
  mealtype,
  setInputMealVal,
  inputMealVal,
}) {
  //   const [dropdownDirection, setDropdownDirection] = useState('');
  //   const handleClick = (e) => {
  //     const spaceBelow = window.innerHeight - e.target.getBoundingClientRect().bottom;
  //     const dropdownHeight = 200; // replace with your dropdown height
  //     if (spaceBelow < dropdownHeight) {
  //       setDropdownDirection('up');
  //     } else {
  //       setDropdownDirection('');
  //     }
  //   };

  const [userInput, setUserInput] = useState('');

  const handleSelect = (selectedOption) => {
    if (selectedOption) {
      setSelected(selectedOption);
      setQuery(selectedOption.meal_name);
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    setUserInput(inputValue);
    setSelected({});
    // Check if the input matches a predefined option and set it as selected
    const selectedOption = data.find((option) => option.meal_name === inputValue);
    if (selectedOption) {
      setSelected(selectedOption);
    } else {
      setInputMealVal(inputValue);
    }
  };

  return (
    <div>
      <Combobox
        value={selected}
        onChange={handleSelect}
        // className={`dropdown ${dropdownDirection}`}
        // onClick={handleClick}
      >
        <div className={`${className} relative mr-[10px] w-[65px]`}>
          <div className="relative w-full 2xl:-mt-1 lg:mt-[6px] cursor-default overflow-hidden  focus:outline-none ">
            <Combobox.Input
              className="focus:outline-none py-1 w-full  border-b"
              displayValue={(meal) => (meal.meal_name ? meal.meal_name : inputMealVal)}
              onChange={handleChange}
              placeholder="Meal Type"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <div className="h-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Combobox.Options}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // afterLeave={() => setQuery('')}
          >
            {query?.length > 0 && data?.length > 0 && (
              <Combobox.Options className="dropdown-u absolute rounded-lg shadow-2xl mt-1 h-[140px] w-full overflow-auto bg-white text-xs z-50 2xl:text-xs xl:text-[10px] lg:text-[8px]">
                {data?.map((meal, ind) => (
                  <Combobox.Option
                    key={ind}
                    className={({ active }) =>
                      `relative cursor-default select-none border-b px-3 py-3 ${
                        active ? 'bg-shades-2 text-white' : 'text-gray-900'
                      }`
                    }
                    value={meal}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {meal.meal_name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <div className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
