import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as Tag } from '../../../assets/images/tagged.svg';
import { ReactComponent as UnTag } from '../../../assets/images/untagged.svg';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';

export default function History({ headers, userId }) {
  const initialRows = Array(8).fill({
    session_type: -1,
    expert_name: -1,
    date: -1,
    time: -1,
    feedback: -1,
    customer_joined: -1,
  });
  const [History, setHistory] = useState({
    columns: [
      { id: 0, column_name: 'Session Type', map_column: 'session_type', type: 'string' },
      { id: 1, column_name: `Expert's Name`, map_column: 'expert_name', type: 'string' },
      { id: 1, column_name: 'Date', map_column: 'date', type: 'string' },
      { id: 1, column_name: 'Time', map_column: 'time', type: 'string' },
      { id: 1, column_name: 'Feedback', map_column: 'feedback', type: 'string' },
      { id: 1, column_name: 'Customer Joined', map_column: 'customer_joined', type: 'string' },
    ],
    rows: initialRows,
  });
  const [sorting, setSorting] = useState([]);
  const [familyDetails, setfamilyDetails] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const { columns } = History;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [History]);

  const table = useReactTable({
    data: History.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchHistory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/consultations/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data[0];
        const nutrionDetail = finalData.tag_history.flatMap((plan, index) => [
          {
            label: `${plan.role}'s Name`,
            data: plan.name,
            tagged: plan.status,
            tagging: plan.assignment_type,
            assign: plan.assigned_by,
          },
        ]);
        setfamilyDetails(nutrionDetail);
        setHistory({ ...History, rows: finalData.booking_history });
      });
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const uniqueStyles = `
  li:nth-child(odd) {
    background: #e6e6e633;
  }
`;

  return (
    <div className="w-full">
      <style>{uniqueStyles}</style>
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        {familyDetails.length !== 0 && (
          <div>
            <ul className="w-full">
              {familyDetails.length !== 0
                ? familyDetails.map((data, index) => (
                    <li className={`py-[8px] px-[16px]`}>
                      <div className="flex justify-between">
                        <div>
                          <div className="text-[12px] text-[#666666]">{data.label}</div>
                          <div className="text-[16px]">
                            {data.data}{' '}
                            {data.tagging === 'Manual' ? `Assigned by ${data.assign}` : ''}
                          </div>
                        </div>
                        <div className="flex items-center">
                          {data.tagged === 'Tagged' ? (
                            <>
                              <span className="mr-[12px] text-[13px]">{data.tagged}</span>
                              <Tag />
                            </>
                          ) : (
                            <>
                              <span className="text-[#C2C2C2] mr-[12px] text-[13px]">
                                {data.tagged}
                              </span>
                              <UnTag />
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                : Array.from({ length: 4 }).map((_, index) => (
                    <li key={index} className="skeleton-loader">
                      <Skeleton className="ml-[15px]" width="20%" baseColor="#e9d39633"></Skeleton>
                      <Skeleton className="ml-[15px]" width="70%" baseColor="#e9d39633"></Skeleton>
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </div>
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            Consultation History
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      ${
                        header.column.id === 'session_type'
                          ? 'w-[18%]'
                          : header.column.id === 'expert_name'
                          ? 'w-[18%]'
                          : header.column.id === 'date'
                          ? 'w-[12%]'
                          : header.column.id === 'time'
                          ? 'w-[15%]'
                          : header.column.id === 'feedback'
                          ? 'w-[22%]'
                          : header.column.id === 'customer_joined'
                          ? 'w-[15%]'
                          : ''
                      }
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        {array?.length - 1 === index + 10
                          ? null
                          : {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 history-table">
            {table.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    ${
                      cell.column.id === 'session_type'
                        ? 'w-[18%]'
                        : cell.column.id === 'expert_name'
                        ? 'w-[18%]'
                        : cell.column.id === 'date'
                        ? 'w-[12%]'
                        : cell.column.id === 'time'
                        ? 'w-[15%]'
                        : cell.column.id === 'feedback'
                        ? 'w-[22%]'
                        : cell.column.id === 'customer_joined'
                        ? 'w-[15%]'
                        : ''
                    }
                    `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
