import { useEffect, useState, useRef } from 'react';
// import { ReactComponent as Import } from '../../../../assets/images/import.svg';
import { ReactComponent as Export } from '../../../../assets/images/export.svg';
import { ReactComponent as Add } from '../../../../assets/images/add.svg';
import { ReactComponent as Search } from '../../../../assets/images/search.svg';
import { ReactComponent as Down } from '../../../../assets/images/down.svg';
import { ReactComponent as Edit } from '../../../../assets/images/editfordash.svg';
import { ReactComponent as Delete } from '../../../../assets/images/delete.svg';
import { ReactComponent as ErrorImage } from '../../../../assets/images/ingrdient_error.svg';
import { HashLoader } from 'react-spinners';
import Card from '@mui/material/Card';
import { Tooltip } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ExportIngredient } from './ExportIngredient';

export default function IngredientsPanel({ headers, nutritionIngredient }) {
  const navigate = useNavigate();

  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);
  const importExport = permission?.role_permissions?.find(
    (permission) => permission.name === 'Import/Export Ingredients'
  );

  const [Ingredients, setIngredients] = useState([]);
  const [MacroAndTags, setMacroAndTags] = useState({});
  const [Loading, setLoading] = useState();
  const [CardOpenStates, setCardOpenStates] = useState();
  const [Page, setPage] = useState(1);
  const [SearchQuery, setSearchQuery] = useState('');
  const [Loader, setLoader] = useState(false);
  const [ExportPop, setExportPop] = useState(false);
  const [MaxCount, setMaxCount] = useState('');
  const [InactiveMessage, setInactiveMessage] = useState('');
  const ingredientListRef = useRef(null);

  const fetchUserDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/list?page=${Page}&search=${SearchQuery}`,
        {
          headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data;
        const finalCount = response.data.tota_count;

        const newData = finalData.filter(
          (newItem) => !Ingredients.some((existingItem) => existingItem.id === newItem.id)
        );
        setMaxCount(finalCount);
        setInactiveMessage(response.data.inactive_message);

        if (Ingredients.length === 0) {
          setIngredients(finalData);
          setCardOpenStates(finalData.map(() => false));
          setLoading(finalData.map(() => false));
        } else if (Page === 1) {
          setIngredients(finalData);
          setCardOpenStates(finalData.map(() => false));
          setLoading(finalData.map(() => false));
        } else if (newData.length > 0) {
          setIngredients((prevIngredients) => [...prevIngredients, ...newData]);
          setCardOpenStates((prevStates) => [...prevStates, ...newData.map(() => false)]);
          setLoading((prevLoading) => [...prevLoading, ...newData.map(() => false)]);
        }
      })
      .catch((error) => {
        const finalData = error.response.data;
        console.error(finalData);
        if (error.response.data.code === '500') {
          localStorage.clear();
          navigate('/login');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  let searchTimeout;
  useEffect(() => {
    setLoader(true);
    if (SearchQuery) setPage(1);
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      fetchUserDetails();
    }, 2000);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [Page, SearchQuery]);

  useEffect(() => {
    const listRef = ingredientListRef.current;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = listRef;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setPage((prevPage) => prevPage + 1); // Increment the page
      }
    };

    listRef.addEventListener('scroll', handleScroll);
    return () => {
      listRef.removeEventListener('scroll', handleScroll);
    };
  }, [ingredientListRef]);

  const toggleCardOpen = (index, ingredientId) => {
    if (CardOpenStates[index] === false) {
      setLoading((prevStates) => prevStates.map((state, i) => (i === index ? !state : false)));
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/list/${ingredientId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          const finalTagsAndMacros = response.data.data;
          setCardOpenStates((prevStates) =>
            prevStates.map((state, i) => (i === index ? !state : false))
          );
          setMacroAndTags(finalTagsAndMacros[0]);
        })
        .catch((error) => {
          const finalTagsAndMacros = error.response.data;
        })
        .finally(() => {
          setLoading((prevStates) => prevStates.map((state, i) => (i === index ? !state : false)));
          // setLoading(false);
        });
    } else {
      setCardOpenStates((prevStates) =>
        prevStates.map((state, i) => (i === index ? !state : false))
      );
    }
  };

  const deleteIngredient = (ingredientId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/${ingredientId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const delteData = response.data;
        toast.success(delteData.message);
        setPage(1);
        if (Page === 1) fetchUserDetails();
      })
      .catch((error) => {
        const delteData = error.response.data;
        toast.error(delteData.message);
      });
  };

  return (
    <div className="w-full flex justify-between">
      {Loader && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
        </div>
      )}
      <div className="w-full">
        <div className=" font-semibold text-[30px] leading-10 text-body-color-2 mr-[5px] pb-[20px]">
          List Of Ingredients
        </div>
        <div className="flex justify-between w-full">
          <div className="flex">
            {importExport?.can_create && (
              <div
                className="cursor-pointer flex text-[#D2A62C] font-normal border border-[#D2A62C] mr-[20px] rounded-[10px] px-[15px] py-[8px]"
                onClick={() => setExportPop(true)}
                style={{
                  boxShadow: '4px 4px 8px -2px #0000001F',
                }}
              >
                <Export className="mr-[10px]" />
                Export
              </div>
            )}
            {/* <div className="cursor-pointer flex text-[#D2A62C] font-normal border border-[#D2A62C] mr-[20px] rounded-[10px] px-[15px] py-[8px]">
              <Import className="mr-[10px]" />
              Import
            </div> */}
            {nutritionIngredient?.can_create && (
              <div
                className="cursor-pointer flex text-[#FFFFFF] bg-[#D2A62C] font-normal border border-[#D2A62C] mr-[20px] rounded-[10px] px-[15px] py-[8px]"
                onClick={() => {
                  navigate('/nutrition/ingredient/add', {
                    state: 'isAdd',
                  });
                }}
                style={{
                  boxShadow: '4px 4px 8px -2px #0000001F',
                }}
              >
                <Add className="mr-[10px]" />
                Add Ingredient
              </div>
            )}
          </div>
          <div>
            <div
              className="flex text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] px-[10px] py-[8px]"
              style={{ boxShadow: '4px 4px 8px -2px #00000014' }}
            >
              <Search className="my-[4px] mr-[4px]" />
              <input
                placeholder="Search"
                type="text"
                className="min-w-[300px] text-[15px] text-[#666666] outline-none"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-[14px] ingredient-list overflow-auto" ref={ingredientListRef}>
          {Ingredients?.map((ingredient, index) => (
            <Card
              key={ingredient.id}
              sx={{
                minWidth: 300,
                borderRadius: '10px',
                borderColor: '#D4D4D4',
                background: '#FFF',
                margin: '10px 0px',
                boxShadow: 'none',
              }}
            >
              <CardHeader
                title={
                  <Tooltip
                    // title={ingredient.name.length > 60 ? ingredient.name : ''}
                    title={!ingredient.is_active ? InactiveMessage : ''}
                    placement="top"
                    arrow
                  >
                    <span>
                      {ingredient.name.length > 60
                        ? `${ingredient.name.slice(0, 60)}...`
                        : ingredient.name}
                    </span>
                  </Tooltip>
                }
                sx={{
                  backgroundColor: '#FFFFFF',
                  padding: '10px 15px',
                  border: '1px solid #D4D4D4',
                  borderRadius: '10px',
                  '& .MuiCardHeader-action': {
                    margin: 0,
                  },
                  boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.12)',
                  position: 'relative',
                  zIndex: 1,
                }}
                titleTypographyProps={{
                  style: {
                    color: ingredient.is_active ? '#343434' : 'red',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '23px',
                    fontFamily: 'Poppins',
                  },
                }}
                action={
                  <div className="flex">
                    {nutritionIngredient?.can_create && (
                      <>
                        <Delete
                          className="w-5 h-5 mr-[10px] cursor-pointer"
                          onClick={() => {
                            deleteIngredient(ingredient.id);
                          }}
                        />
                        <Edit
                          className="w-5 h-5 cursor-pointer mr-[10px]"
                          onClick={() => {
                            navigate(`/nutrition/ingredient/edit/${ingredient.id}`, {
                              state: ingredient.id,
                            });
                          }}
                        />
                      </>
                    )}
                    {Loading[index] ? (
                      <div className="flex justify-center items-center w-[24px] h-[25px]">
                        <CircularProgress sx={{ color: '#D2A62C' }} size={20} />
                      </div>
                    ) : (
                      <div
                        className={`cursor-pointer transition-transform duration-300 transform ${
                          CardOpenStates[index] ? 'rotate-180' : ''
                        }`}
                        onClick={() => {
                          toggleCardOpen(index, ingredient.id);
                        }}
                      >
                        <Down />
                      </div>
                    )}
                  </div>
                }
              />
              <Collapse
                in={CardOpenStates[index]}
                sx={{ position: 'relative', zIndex: 0, top: '-7px' }}
                timeout="auto"
                unmountOnExit
              >
                <CardContent
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '0px 0px 10px 10px',
                    padding: '34px 19px 27px !important',
                  }}
                >
                  <div>
                    <div className="flex justify-between">
                      {MacroAndTags?.nutrients?.length ? (
                        <div>
                          Nutrients <div className="border-b-2 border-[#D6A725] w-10" />
                          <div className="flex my-[10px] flex-wrap">
                            {MacroAndTags?.nutrients.map((macros, index) => (
                              <div className="border rounded-[9px] p-[8px] text-[#343434] text-[13px] mr-[15px] mb-[15px] bg-[#DADADA]">
                                {macros.name} {macros.value} {macros.unit}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div>
                          Nutrients <div className="border-b-2 border-[#D6A725] w-10" />
                          <div className="flex my-[10px] flex-wrap">
                            <div className="border rounded-[9px] p-[8px] text-[#343434] text-[13px] mr-[15px] mb-[15px] bg-[#DADADA]">
                              No Nutrients
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {MacroAndTags?.tags?.length ? (
                      <div>
                        Tags <div className="border-b-2 border-[#D6A725] w-10" />
                        <div className="flex my-[10px] flex-wrap">
                          {MacroAndTags?.tags.map((macros, index) => (
                            <div className="border rounded-[9px] p-[8px] text-[#343434] text-[13px] mr-[15px] mb-[15px] bg-[#EDEDED] break-all">
                              {macros.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>
                        Tags <div className="border-b-2 border-[#D6A725] w-10" />
                        <div className="flex my-[10px] flex-wrap">
                          <div className="border rounded-[9px] p-[8px] text-[#343434] text-[13px] mr-[15px] mb-[15px] bg-[#DADADA]">
                            No Tags
                          </div>
                        </div>
                      </div>
                    )}
                    {MacroAndTags?.created_by && (
                      <div>
                        Created By <div className="border-b-2 border-[#D6A725] w-10" />
                        <div className="flex mt-[10px] flex-wrap">
                          <div className="p-[8px] text-[#343434] text-[14px] mr-[15px]  break-all">
                            {MacroAndTags?.created_by}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div></div>
                </CardContent>
              </Collapse>
            </Card>
          ))}
          {Ingredients.length === 0 && (
            <div className="flex justify-center h-full items-center">
              <ErrorImage className="ingredient-error" />
            </div>
          )}
        </div>
      </div>
      <ExportIngredient
        ExportPop={ExportPop}
        setExportPop={setExportPop}
        MaxCount={MaxCount}
        headers={headers}
      />
    </div>
  );
}
