import React, { useRef, useState } from 'react';
import { ReactComponent as UpArray } from '../../../../assets/images/up_array.svg';
import { Combobox, Transition } from '@headlessui/react';

export default function UnitDropEdit({ SelectedUnit, setSelectedUnit, MeasureDrop }) {
  const [open, setOpen] = useState(false);

  const comboboxRef = useRef(null);

  return (
    <div className="w-full 2xl:text-[12px] xl:text-[10px] lg:text-[8px]">
      <Combobox
        value={SelectedUnit}
        ref={comboboxRef}
        open={open}
        onChange={(data) => {
          setOpen(!open);
          setSelectedUnit(data);
        }}
      >
        <div className="relative">
          <div
            className="relative flex w-full items-center text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] px-[10px] py-[5px]"
            style={{
              boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
            }}
          >
            <Combobox.Input
              displayValue={(data) => SelectedUnit?.name}
              onChange={(event) => {
                setSelectedUnit('');
              }}
              disabled={true}
              placeholder={'Select Unit'}
              className="w-full outline-none py-[8px] text-body-color-2 font-semibold"
              autocomplete="off"
            />
            <Combobox.Button
              onClick={(e) => {
                setOpen(!open);
              }}
              className="absolute top-0 bottom-0 right-3"
            >
              <UpArray
                className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Combobox.Options}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            style={{ borderRadius: '0px 0px 10px 10px', boxShadow: '4px 4px 8px -2px #0000001F' }}
            className={`absolute max-h-60 z-50 w-full overflow-auto font-normal bg-white`}
            afterLeave={() => {
              setOpen(!open);
            }}
          >
            <>
              {MeasureDrop?.length === 0 ? (
                <div className="relative cursor-default select-none 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] text-gray-700 ">
                  Nothing found.
                </div>
              ) : (
                MeasureDrop?.map((recipe_name, id) => (
                  <Combobox.Option
                    key={id}
                    className={({ active }) =>
                      `  select-none cursor-pointer 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] border-b  ${
                        active ? 'bg-[#D2A62C] text-[#FFFFFF]' : 'text-body-color-2'
                      }`
                    }
                    value={recipe_name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate `}>{recipe_name.name}</span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <div className=" lg:h-4 lg:w-3 md:w-1 md:h-2" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
