import React, { useRef } from 'react';
import DietSection from '../layout/DietSection';
import { ReactComponent as ArrayBack } from '../../assets/images/arrow_back.svg';
import PagesHeader from '../shared/PagesHeader';
import classNames from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import HealthAssessmentModal from '../layout/HealthAssessmentModal';

import VerticalDivider from '../common/VerticalDivider';
import AnalysisAboutDialog from './AnalysisAboutDialog';
import Popover from '@mui/material/Popover';

const UserDetailsPanel = ({
  name,
  secondHeading,
  thirdHeading,
  userData,
  CallApi,
  setCallApi,
  verification,
  setChangeInDetails,
}) => {
  // const [userData, setUserData] = useState(useSelector((state) => state.UserData?.userData));
  const [open, setOpen] = useState(false);
  const [OpenForProgress, setOpenForProgress] = useState(false);
  const [OpenListForReport, setOpenListForReport] = useState(null);
  const opened = Boolean(OpenListForReport);
  const id = opened ? 'simple-popover' : undefined;

  let { userId } = useParams();

  const userPanelRef = useRef(null);

  const [openHealthModal, setOpenHealthModal] = useState(false);

  const closeHealthModal = () => {
    setOpenHealthModal(!openModal);
  };

  const handleResize = () => {
    let userPanelHeight = 0;
    if (userPanelRef.current) {
      userPanelHeight = userPanelRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--user-height', userPanelHeight);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleResize, 30);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const closeModal = () => {
    setOpen(false);
    setOpenForProgress(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  const showDnaReport = (has_report) => {
    if (has_report) {
      window.open(userData?.dna_report?.report, '_blank');
      // window.open(`/pdfview?pdf=${userData?.dna_report?.report}`, '_blank');
    }
  };

  // const showBloodTestReport = (has_report) => {
  //   if (has_report) {
  //     window.open(userData?.blood_report?.report, '_blank');
  //     // window.open(`/pdfview?pdf=${userData?.blood_report?.report}`, '_blank');
  //   }
  // };

  return (
    <div className="lg:mb-3 md:mb-3">
      <PagesHeader
        name={name}
        array={<ArrayBack className="cursor-pointer" />}
        secondHeading={secondHeading}
        thirdHeading={thirdHeading}
      />
      <div className="flex min-w-[-webkit-fill-available] relative">
        <div
          ref={userPanelRef}
          className="flex justify-between  overflow-auto 2xl:px-0 py-[5px] lg:rounded-[14px] md:rounded-[12px] shadow-grid-col bg-white w-full"
        >
          {!userData?.name ? (
            <Skeleton className="h-8 mt-2 ml-5" width="150px" baseColor="#e9d39633"></Skeleton>
          ) : (
            <>
              <DietSection
                className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
                show={userData?.name}
                title={
                  userData?.age +
                  ' , ' +
                  userData?.gender[0] +
                  ' | ' +
                  userData?.weight +
                  ' | ' +
                  userData?.height
                }
              />
              <VerticalDivider />
            </>
          )}
          {userData?.bmi?.value === null ? (
            <Skeleton className="h-8 mt-2 ml-5" width="150px" baseColor="#e9d39633"></Skeleton>
          ) : (
            // 2xl:py-[20px] xl:py-[16px] lg:py-[14px]
            <>
              <DietSection
                className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
                title1={userData?.bmi?.value}
                // + ' ' + userData?.bmi?.verdict
                heading1={'BMI'}
              />
              <VerticalDivider />
            </>
          )}
          <DietSection
            heading1={'Known Allergy'}
            title1={userData?.allergies?.answer_data}
            editabl={userData?.allergies?.is_editable}
            // className={classNames('border-r-2 break-words xl:pr-[17px] xl:py-[8.89px]  lg:pr-[5px] lg:py-[7px]')}
            icon={true}
            userId={userId}
            qid={userData?.allergies?.question_id}
            className={classNames(' break-words 2xl:p-0 xl:p-0  lg:p-0 ')}
            verification={verification}
            setChangeInDetails={setChangeInDetails}
            // icon
          />
          <VerticalDivider />
          <DietSection
            heading1={'Medical Condition'}
            title1={userData?.medical_condition?.answer_data}
            editabl={userData?.medical_condition?.is_editable}
            // className={classNames('border-r-2 xl:pr-[17px] xl:py-[8.89px]  lg:pr-[5px] lg:py-[7px]')}
            icon={true}
            userId={userId}
            qid={userData?.medical_condition?.question_id}
            className={classNames('2xl:p-0 xl:p-0 lg:p-0')}
            verification={verification}
            setChangeInDetails={setChangeInDetails}
            // icon
          />
          <VerticalDivider />
          <DietSection
            heading1={'Progress Tracker'}
            heading={'Progress Tracker'}
            view={'View'}
            Click={() => {
              setOpenForProgress(true);
            }}
            has_report={true}
            className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
          />
          {/* <VerticalDivider /> */}
          {/* <DietSection
            heading1={'DNA'}
            heading={'DNA'}
            view={'View'}
            // className={classNames('border-r-2 xl:pr-[17px] xl:py-[8.89px]  lg:pr-[5px] lg:py-[7px]')}
            has_report={userData?.dna_report?.has_report}
            Click={() => showDnaReport(userData?.dna_report?.has_report)}
            className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
          /> */}
          <VerticalDivider />
          <DietSection
            heading1={'HAF'}
            view={'View'}
            className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
            Click={() => setOpenHealthModal(true)}
            has_report={true}
          />
          {/* 
          <VerticalDivider />
          <DietSection
            heading1={'Blood'}
            view={'View'}
            has_report={userData?.blood_report?.has_report}
            Click={(event) => {
              // showBloodTestReport(userData?.blood_report?.has_report);
              if (userData?.blood_report?.has_report) {
                setOpenListForReport(event.currentTarget);
              }
            }}
            className={classNames(' 2xl:p-0 xl:p-0  lg:p-0')}
          /> */}
        </div>
        <Popover
          id={id}
          open={opened}
          anchorEl={OpenListForReport}
          onClose={() => {
            setOpenListForReport(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="max-h-[342px] text-[13px] overflow-auto">
            {userData?.blood_report_list?.map((report, index) => (
              <div className="p-[10px] border-b ">
                <div className="flex justify-between w-[200px]">
                  <div>
                    {userData?.blood_report_list.length > 1 ? `${index + 1}. ` : ''}{' '}
                    {report.receive_date}
                  </div>
                  <div>{report.receive_time}</div>
                </div>
                <div
                  className="text-[#BD9528] mt-[5px] cursor-pointer"
                  onClick={() => {
                    window.open(report.url, '_blank');
                  }}
                >
                  {report.plan_name}
                </div>
              </div>
            ))}
          </div>
        </Popover>
        {/* <div
          className="onhover-view"
          onClick={() => {
            openModal();
          }}
        >
          <div className="flex justify-around items-center view-note note-icon w-[100px] h-[30px] cursor-pointer rounded-l-[7px] text-white bg-shades-2 text-[12px]">
            <div>
              <NoteIcon className="ml-[7.41px]" />
            </div>
            <div>View Notes</div>
          </div>
        </div>
        <div
          className="cursor-pointer onhover"
          onClick={() => {
            setOpenGuide(true);
          }}
        >
          <div className="flex justify-around items-center consulation-guideline note-icon w-[180px] h-[30px] cursor-pointer rounded-l-[7px] text-white bg-shades-2 text-[12px]">
            <div>
              <GuideLine className="ml-[7.81px]" />
            </div>
            <div>Consultation Guidelines</div>
          </div>
        </div> */}
      </div>
      <AnalysisAboutDialog isOpen={OpenForProgress} isClose={closeModal} viewMore={true} />
      <HealthAssessmentModal OpenIs={openHealthModal} CloseIS={closeHealthModal} userId={userId} />
    </div>
  );
};

export default UserDetailsPanel;
