import { useEffect, useMemo, useState, useRef } from 'react';
import axios from 'axios';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { ReactComponent as ArrayBack } from '../../../assets/images/arrow_back.svg';
import { ReactComponent as Edit } from '../../../assets/images/smalledit.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete_icon.svg';
import { HashLoader } from 'react-spinners';
import Button from '../../common/Button';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getToken } from '../../../Utils/Authenticate';
import { toast } from 'react-toastify';
import PagesHeader from '../../shared/PagesHeader';
import DietPlaneTable from '../DietPlaneTable';
import Confirmation from './Confirmation';

export default function TemplateView({ templates }) {
  let token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let { templateId } = useParams();

  const tableRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [OpenDelete, setOpenDelete] = useState(false);
  const [OpenSave, setOpenSave] = useState(false);
  const [DeletingData, setDeletingData] = useState('');
  const [Loader, setLoader] = useState(false);
  const [TemplateData, setTemplateData] = useState({
    diet_plans: [],
    id: '',
    template_id: '',
    template_name: '',
    changes: false,
  });

  const getTemplate = () => {
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/view`,
        {
          template_id: templateId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data[0];
        setLoader(false);
        setTemplateData({
          diet_plans: finalData.diet_plans,
          id: finalData.id,
          template_id: finalData.template_id,
          template_name: finalData.template_name,
          changes: finalData.changes,
        });
        // if (finalData.changes) {
        //   toast.error('There are unsaved changes. Please Save the template');
        // }
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  useEffect(() => {
    getTemplate();
  }, [templateId]);

  const rowWiseData = useMemo(() => {
    if (!TemplateData.diet_plans || TemplateData.diet_plans?.length === 0) return [];
    let rowSize = TemplateData.diet_plans[0]?.sections?.length;
    let max = 0;
    for (let i = 0; i < TemplateData.diet_plans?.length; i++) {
      max = Math.max(max, TemplateData.diet_plans[i]?.sections?.length);
    }
    rowSize = max;
    let columnSize = TemplateData.diet_plans?.length;
    let table = [];
    for (let i = 0; i < rowSize; i++) {
      table.push([]);
      for (let j = 0; j < columnSize; j++) {
        table[i].push({});
      }
    }
    const undefinedIndex = {};
    for (let i = 0; i < rowSize; i++) {
      for (let j = 0; j < columnSize; j++) {
        const sections = TemplateData.diet_plans[j]?.sections;
        if (sections[i] === undefined && !undefinedIndex[j]) {
          if (i === 0) {
            undefinedIndex[j] = true;
            table[i][j] = 'rowSpan';
          } else {
            table[i][j] = 'empty';
          }
        } else {
          table[i][j] = sections[i];
        }
      }
    }

    return table;
  }, [TemplateData.diet_plans]);

  const addDay = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/add/plan`,
        {
          template_id: templateId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data[0];
        setLoader(true);
        getTemplate();
        setTimeout(() => {
          // Scroll to the right when a new column is added
          tableRef.current?.rows[0].cells[tableRef.current.rows[0].cells.length - 1].scrollIntoView(
            {
              behavior: 'smooth',
              block: 'nearest',
              inline: 'end',
            }
          );
        }, 200);
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  const deleteDay = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/remove/plan?template_id=${templateId}&plan_id=${id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data[0];
        setLoader(true);
        setOpenDelete(false);
        setDeletingData('');
        getTemplate();
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  const uniqueMeals = Array.from(
    new Map(
      TemplateData.diet_plans?.flatMap((data) =>
        data.sections?.map(({ meal_id, meal, meal_time }) => [
          JSON.stringify({ meal, meal_time }),
          { meal_id, meal, meal_time },
        ])
      )
    ).values()
  );

  const saveTemplate = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/save`,
        {
          template_id: templateId,
          meals: uniqueMeals,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data;
        toast.success(finalData.message);
        setOpenSave(false);
        getTemplate();
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  return (
    <div>
      {Loader && (
        <div className="fixed h-screen w-screen z-[99] inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]"></div>
        </div>
      )}
      <PagesHeader
        name={'Template'}
        array={<ArrayBack className="cursor-pointer" />}
        secondHeading={'Template'}
        thirdHeading={'Template'}
        templates={templates}
        changes={TemplateData.changes}
      />
      <div
        id="shadow-Dropdown-Shadow"
        className="shadow-Dropdown-Shadow bg-[#FFF] rounded-[20px] ingredient-box"
      >
        <div className="text-2xl font-normal  w-full">
          <div className="flex items-center justify-between py-[10px] px-4">
            <div>
              <div className="flex items-end">{TemplateData.template_name}</div>
            </div>
            {templates?.can_create && (
              <div className="flex items-center">
                {TemplateData.changes && (
                  <div className="text-base text-red-600 mr-5">
                    There are unsaved changes. Please Save the template
                  </div>
                )}
                <div
                  className="cursor-pointer text-base text-shades-2"
                  onClick={() => {
                    setOpenSave(true);
                  }}
                >
                  Save Template
                </div>
                <Button
                  className={classNames(
                    'bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px] ml-5'
                  )}
                  onClick={() => {
                    addDay();
                  }}
                >
                  <span>
                    <Add className="lg:h-[16px] lg:w-[16px]" />
                  </span>
                  Add
                </Button>
              </div>
            )}
          </div>
          <div className={`flex overflow-auto content-diet-plan4 bg-[#E9D396]/[0.1]`}>
            <div className=" overflow-scroll w-full">
              <>
                <table className="overflow-x-auto" ref={tableRef}>
                  <thead className="sticky top-0 items-center bg-white">
                    <tr className="border-t">
                      {TemplateData.diet_plans?.length > 0 &&
                        TemplateData.diet_plans.map((value, id) => {
                          return (
                            <>
                              <th
                                className="border-r  lg:min-w-[280px] md:min-w-[260px] p-0 border-b px-5 py-2 xl:px-[17.78px] 2xl:py-[10px]"
                                key={id}
                              >
                                <div className="flex justify-between gap-1">
                                  <div className="flex  text-start">
                                    <div>
                                      <div className=" min-w-[180px] max-w-[180px] 2xl:text-xl xl:text-[17px] lg:text-sm font-Regular truncate">
                                        {value.label}
                                      </div>
                                    </div>
                                  </div>
                                  {templates?.can_create && (
                                    <>
                                      {' '}
                                      {value.plan_id === -1 ? (
                                        <div style={{ width: '50px', border: 'none' }}>
                                          <Skeleton
                                            width="50px"
                                            height="10px"
                                            baseColor="#e9d39633"
                                          ></Skeleton>
                                        </div>
                                      ) : (
                                        <>
                                          <div className="flex gap-[10px] mt-2 ">
                                            <div to="/template/edit">
                                              <Edit
                                                onClick={() => {
                                                  navigate(
                                                    `/template/edit/${templateId}/${value.plan_id}`
                                                  );
                                                }}
                                                className={`cursor-pointer`}
                                              />
                                            </div>
                                            <div className={`cursor-pointer`}>
                                              <Delete
                                                onClick={() => {
                                                  setDeletingData(value.plan_id);
                                                  setOpenDelete(true);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </th>
                            </>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className=" ">
                    {rowWiseData?.length > 0 &&
                      rowWiseData.map((data, index, heading) => {
                        // console.log("rwd", rowWiseData)
                        return (
                          <tr className="border-b" key={index}>
                            {data.map((fields = {}, id) => {
                              if (
                                fields?.hasOwnProperty('meal_time') &&
                                fields?.hasOwnProperty('recipes') &&
                                fields?.hasOwnProperty('meal')
                              ) {
                                const { meal_time, recipes, meal } = fields;
                                return (
                                  <td className="align-baseline  border-r">
                                    <div className="flex justify-around 2xl:p-5 xl:p-4 lg:p-3 md:p-2">
                                      {meal_time === '-1' ? (
                                        <div style={{ width: '100%', border: 'none' }}>
                                          <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                        </div>
                                      ) : (
                                        <div className=" lg:w-[70px] md:w-[60px] font-medium text-body-color-1    2xl:text-xs xl:text-[10.67px] lg:text-[8px]">
                                          {meal_time}
                                        </div>
                                      )}

                                      <div className="flex flex-col items-start justify-start  lg:w-[190px] md:w-[185px]">
                                        {meal === '-1' ? (
                                          <div style={{ width: '100%', border: 'none' }}>
                                            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                          </div>
                                        ) : (
                                          <div className="font-medium text-body-color-1  2xl:text-xs xl:text-[10.67px] lg:text-[8.89px]">
                                            {meal}
                                          </div>
                                        )}

                                        {recipes.map(
                                          ({
                                            recipe_name,
                                            serving,
                                            recipe_id,
                                            measurement,
                                            value_in_gm,
                                          }) => {
                                            return (
                                              <>
                                                <div className="flex items-center justify-start mt-1">
                                                  {recipe_name === '-1' ? (
                                                    <div style={{ width: '100%', border: 'none' }}>
                                                      <Skeleton
                                                        width="100%"
                                                        baseColor="#e9d39633"
                                                      ></Skeleton>
                                                    </div>
                                                  ) : (
                                                    <div className="text-body-color-2   2xl:text-xs xl:text-[10.67px] lg:text-[8.89px] font-bold cursor-pointer">
                                                      {recipe_name}({serving}&nbsp;
                                                      {measurement})
                                                    </div>
                                                  )}
                                                  {serving === -1 ? (
                                                    <div style={{ width: '100%', border: 'none' }}>
                                                      <Skeleton
                                                        width="100%"
                                                        baseColor="#e9d39633"
                                                      ></Skeleton>
                                                    </div>
                                                  ) : (
                                                    <div className='"text-body-color-2   lg:text-[8px] md: text-[6px] font-Regular'></div>
                                                  )}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else if (fields === 'rowSpan') {
                                return (
                                  <td
                                    rowSpan={rowWiseData.length}
                                    className="2xl:p-5 xl:p-4 lg:p-3 md:p-2 border-r"
                                    // onClick={() => {
                                    //   const params = userId.split('/');
                                    //   const _userId = params[params.length - 1];
                                    //   navigate(
                                    //     `/createDiet/${_userId}/${TemplateData.diet_plans[id].plan_id}`
                                    //   );
                                    // }}
                                  >
                                    <div className="flex flex-col items-center gap-4 lg:w-[262px] md:w-[247px]">
                                      <Add className=" cursor-pointer" />
                                      <p className="text-center text-body-color-2 lg:text-[12px] md:text-[10px] font-Regular">
                                        Click on edit icon to add the diet plan
                                      </p>
                                    </div>
                                  </td>
                                );
                              } else if (fields === 'empty') {
                                return <td className="px-5 border-r"></td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            </div>
          </div>
        </div>
        <Confirmation
          TitleForModal={'Save'}
          Sureity={'Are you sure you want to save this?'}
          Sureity2={"You won't be able to restore it once it is saved."}
          OpenCall={OpenSave}
          CloseCall={() => {
            setOpenSave(false);
            setDeletingData('');
          }}
          deleteCall={() => saveTemplate()}
        />
        <Confirmation
          TitleForModal={'Delete'}
          Sureity={'Are you sure you want to delete this?'}
          Sureity2={"You won't be able to restore it once it is deleted."}
          OpenCall={OpenDelete}
          CloseCall={() => {
            setOpenDelete(false);
            setDeletingData('');
          }}
          deleteCall={() => deleteDay(DeletingData)}
        />
      </div>
    </div>
  );
}
