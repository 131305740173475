import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as Add } from '../../../../assets/images/add.svg';
import { ReactComponent as Search } from '../../../../assets/images/search.svg';
import { ReactComponent as Edit } from '../../../../assets/images/master_edit.svg';
import { ReactComponent as Cancel } from '../../../../assets/images/cancle.svg';
import { ReactComponent as Check } from '../../../../assets/images/check.svg';
import { useNavigate } from 'react-router';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { toast } from 'react-toastify';
import DropDown from './DropDown';
import UnitDrop from './UnitDrop';
import { Tooltip } from '@mui/material';
import DropForEdit from './DropForEdit';
import UnitDropEdit from './UnitDropEdit';

const columnHelper = createColumnHelper();

export default function MasterPanel({ headers, masterMeasurement }) {
  const navigate = useNavigate();
  const initialRows = Array(12).fill({
    name: -1,
    quantity: -1,
    measurement_state: -1,
    is_multiply: -1,
  });

  const [sorting, setSorting] = useState([]);
  const [EditState, setEditState] = useState('');
  const [SelectedUnitForEdit, setSelectedUnitForEdit] = useState('');
  const [SelectedStateForEdit, setSelectedStateForEdit] = useState('');
  const [EditQuantity, setEditQuantity] = useState('');
  const [EditRowId, setEditRowId] = useState('');
  const [StateForEdit, setStateForEdit] = useState('');
  const [Units, setUnits] = useState('');
  const [IsEdit, setIsEdit] = useState('');
  const [Page, setPage] = useState(1);
  const [Drop, setDrop] = useState([]);
  const [NewName, setNewName] = useState('');
  const [SearchQuery, setSearchQuery] = useState('');
  const [SearchForMaster, setSearchForMaster] = useState('');
  const [State, setState] = useState('');
  const [SelectedValue, setSelectedValue] = useState('');
  const [SelectedState, setSelectedState] = useState('');
  const [SelectedUnit, setSelectedUnit] = useState('');
  const [Unit, setUnit] = useState('');
  const [MeasureDrop, setMeasureDrop] = useState([]);
  const [MeasureDropNew, setMeasureDropNew] = useState([]);
  const [DefalutValue, setDefalutValue] = useState([]);
  const [DefalutValueEdit, setDefalutValueEdit] = useState([]);
  const [StateForId, setStateForId] = useState([]);
  const [TotalCount, setTotalCount] = useState({ final: '', showing: '' });
  const [indexbutton, setIndexButton] = useState([
    {
      id: 1,
    },
  ]);

  const [Data, setData] = useState({
    columns: [
      { id: 0, column_name: 'Name', map_column: 'name', type: 'string' },
      { id: 1, column_name: 'Quantity', map_column: 'quantity', type: 'string' },
      { id: 1, column_name: 'Measurement State', map_column: 'measurement_state', type: 'string' },
    ],
    rows: initialRows,
  });

  useEffect(setPagination, [TotalCount.final]);

  function setPagination() {
    if (Data.length <= 0 || TotalCount.final <= 0) {
      return;
    }
    let no_of_pages = Math.ceil(TotalCount.final / 100);
    setIndexButton(
      Array(no_of_pages)
        .fill()
        .map((_, index) => ({ id: index + 1 }))
    );

    setPage(1);
  }

  const columns = useMemo(() => {
    const { columns } = Data;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className={`break-all`}>{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Data]);

  const table = useReactTable({
    data: Data.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getUnit = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/unit_by_state?state_id=${State}`,
        { headers }
      )
      .then((response) => {
        const finalLoad = response.data;
        setMeasureDrop(finalLoad.data);
      });
  };

  const getUnitNew = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/unit_by_state?state_id=${StateForEdit}`,
        { headers }
      )
      .then((response) => {
        const finalLoad = response.data;
        const findValue = finalLoad.data.find((measure) => measure.name === Units);
        setSelectedUnitForEdit(findValue);
        setMeasureDropNew(finalLoad.data);
      });
  };

  const getMeasurement = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/combinations?page=${Page}&size=100&search=${SearchForMaster}`,
        { headers }
      )
      .then((response) => {
        const finalLoad = response.data.data[0];
        setData({ ...Data, rows: finalLoad.measurements });
        setTotalCount({
          ...TotalCount,
          final: finalLoad.total_count,
          showing: finalLoad.given_records,
        }).catch((error) => {
          if (error.response.data.code === '500') {
            localStorage.clear();
            navigate('/login');
          }
        });

        // if (Data.rows.length === 0) {
        //   setData({ ...Data, rows: finalLoad });
        // } else if (Page === 1) {
        //   setData({ ...Data, rows: finalLoad });
        // } else if (newData.length > 0) {
        //   setData((prevData) => ({
        //     ...prevData,
        //     rows: [...prevData.rows, ...newData],
        //   }));
        // }
      });
  };
  let isLoggingIn = false;

  const addMeasurement = () => {
    if (isLoggingIn) {
      return;
    }
    isLoggingIn = true;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/combinations`,
        {
          name: SelectedValue.name,
          quantity: Number(Unit),
          unit: SelectedUnit.name,
          type: SelectedState.name,
          is_multiply: true,
        },
        { headers }
      )
      .then((response) => {
        getMeasurement();
        setSelectedValue('');
        setSelectedUnit('');
        setSelectedState(DefalutValue);
        setState(DefalutValue.id);
        setUnit('');
      })
      .catch((error) => {
        const finalError = error.response.data;
        toast.error(finalError.message);
      });
  };

  const getNames = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/name?search=${SearchQuery}`,
        { headers }
      )
      .then((response) => {
        const finalLoad = response.data;
        setDrop(finalLoad.data);
      });
  };

  useEffect(() => {
    getNames();
  }, [SearchQuery]);

  const addNewName = () => {
    const body = {
      name: NewName,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/master/measurement/name`, body, {
        headers,
      })
      .then((response) => {
        const finalData = response.data;
        toast.success(finalData.message);
        setNewName('');
        getNames();
      })
      .catch((error) => {
        const finalError = error.response.data;
        toast.error(finalError.message);
      });
  };

  const updateIngredient = (id) => {
    const body = {
      quantity: EditQuantity,
      unit: SelectedUnitForEdit.name,
      type: SelectedStateForEdit.name,
    };
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/admin/master/measurement/combinations/${id}`,
        body,
        {
          headers,
        }
      )
      .then(() => {
        setEditState('');
        setEditRowId('');
        setIsEdit(false);
        getMeasurement();
      })
      .catch((error) => {
        const finalError = error.response.data;
        toast.error(finalError.message);
      });
  };

  useEffect(() => {
    getUnit();
  }, [State]);

  useEffect(() => {
    getUnitNew();
  }, [StateForEdit, Units]);

  useEffect(() => {
    getMeasurement();
  }, [Page, SearchForMaster]);

  return (
    <div className="w-full flex flex-col justify-between">
      {masterMeasurement?.can_create && (
        <div className="flex justify-between">
          <div className="flex">
            <div className="text-[12px] mr-6">
              <span className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] mr-[25px]">
                Measurements Name <span style={{ color: 'red' }}>*</span>
              </span>
              <DropDown
                placeHold="Search Measurement"
                headers={headers}
                SelectedValue={SelectedValue}
                setSelectedValue={setSelectedValue}
                Drop={Drop}
                SearchQuery={SearchQuery}
                setSearchQuery={setSearchQuery}
                StateForId={StateForId}
                setStateForId={setStateForId}
              />
            </div>
            <div className="text-[12px] mr-6">
              <span className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] mr-[25px]">
                Measurement State <span style={{ color: 'red' }}>*</span>
              </span>
              <DropDown
                DefalutValue={DefalutValue}
                setDefalutValue={setDefalutValue}
                placeHold="Select State"
                headers={headers}
                setState={setState}
                SelectedState={SelectedState}
                setSelectedState={setSelectedState}
                setSelectedUnit={setSelectedUnit}
                SearchQuery={SearchQuery}
                setSearchQuery={setSearchQuery}
                StateForId={StateForId}
                setStateForId={setStateForId}
              />
            </div>
            <div className="text-[12px] mr-6">
              <span className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] mr-[25px]">
                Measuring Unit <span style={{ color: 'red' }}>*</span>
              </span>
              <UnitDrop
                MeasureDrop={MeasureDrop}
                SelectedUnit={SelectedUnit}
                setSelectedUnit={setSelectedUnit}
              />
            </div>
            <div className="text-[12px] mr-6">
              <div className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] text-center">
                Value <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div
                  className="flex justify-between items-center border w-full bg-white border-[#E6E6E6] rounded-[10px] px-[10px] py-[5px] 2xl:text-[12px] xl:text-[10px] lg:text-[8px]"
                  style={{ boxShadow: '4px 4px 8px 0px #0000001F' }}
                >
                  <input
                    placeholder=""
                    type="number"
                    value={Unit}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (newValue.split('.').length > 1 && newValue.split('.')[1].length > 2) {
                        return;
                      }
                      if (newValue >= 0) {
                        setUnit(parseFloat(newValue));
                      }
                    }}
                    className="w-full bg-white text-body-color-2 outline-none border-b 2xl:text-[12px] xl:text-[10px] lg:text-[8px] pt-[8px] pb-[7px] text-center font-semibold"
                  />
                  {SelectedUnit.name}
                </div>
              </div>
            </div>
            {/* <div className="text-[12px] mr-6">
            <div className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] text-center">
              Is Multipliable ?
            </div>
            <div className="flex justify-between items-center mt-2">
              <div
                className="flex cursor-pointer justify-center items-center w-full rounded-[10px] px-[10px] py-[5px] h-[47px]"
                onClick={() => setIsMultiply(!IsMultiply)}
              >
                {IsMultiply ? <Select /> : <DeSelect />}
              </div>
            </div>
          </div> */}
            <Tooltip
              title={
                !SelectedState || !SelectedUnit || !SelectedValue || !Unit
                  ? 'Fill out all the feilds'
                  : ''
              }
              placement="top"
            >
              <div
                className={`${
                  !SelectedState || !SelectedUnit || !SelectedValue || !Unit
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                } flex justify-center items-center 2xl:text-[14px] xl:text-[12px] lg:text-[10px] text-[#FFFFFF] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] 2xl:px-[15px] xl:px-[13px] lg:px-[11px] 2xl:py-[6px] xl:py-[4px] lg:py-[2px] mt-[36px] mb-[4px]`}
                onClick={() => {
                  if (!SelectedState || !SelectedUnit || !SelectedValue || !Unit) {
                    return;
                  } else {
                    addMeasurement();
                  }
                }}
                style={{
                  boxShadow: '4px 4px 8px -2px #0000001F',
                }}
              >
                <Add className="2xl:mr-[10px] xl:mr-[8px] lg:mr-[6px] 2xl:h-[22px] 2xl:w-[22px] xl:h-[20px] xl:w-[20px] lg:h-[18px] lg:w-[18px]" />
                Add
              </div>
            </Tooltip>
          </div>
          <div className="text-[12px] mr-6 relative">
            <span className="text-[#9F9F9F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] mr-[80px]">
              Add New Name <span style={{ color: 'red' }}>*</span>
            </span>
            <div className="flex justify-between items-center mt-2 ">
              <input
                placeholder="Type a Name"
                className="flex justify-between items-center border w-full text-[#666666] bg-white border-[#E6E6E6] rounded-[10px] px-[10px] py-[13px] 2xl:text-[12px] xl:text-[10px] lg:text-[8px] outline-none"
                style={{ boxShadow: '4px 4px 8px 0px #0000001F' }}
                value={NewName}
                onChange={(event) => {
                  setNewName(event.target.value);
                }}
              />
            </div>
            <div
              className="absolute -bottom-5 font-bold text-[#D6A725] right-0 cursor-pointer"
              onClick={() => addNewName()}
            >
              + Add New
            </div>
          </div>
        </div>
      )}
      <div
        className={`${masterMeasurement?.can_create ? 'mt-10' : ''} bg-[#FFFFFF] rounded-3xl`}
        style={{
          boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
        }}
      >
        <div className="flex justify-between head border-b px-[20px] py-4 2xl:text-[24px] xl:text-[22px] lg:text-[20px] text-body-color-2">
          <div>Measurement Master</div>
          <div>
            <div
              className="flex text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] px-[10px] py-[8px]"
              style={{
                boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
              }}
            >
              <Search className="my-[4px] mr-[4px]" />
              <input
                placeholder="Search"
                type="text"
                className="min-w-[300px] text-[15px] text-[#666666] outline-none"
                onChange={(e) => {
                  setPage(1);
                  setSearchForMaster(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="tableFixHead">
            <table className="min-w-[-webkit-fill-available] table-margin ">
              <thead className="sticky top-0 items-center thead">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="overflow-hidden flex ">
                    {headerGroup.headers.map((header, index, array) => {
                      return (
                        <th
                          key={header.id}
                          className={`text-start col-${index}-master-ingredient bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-5 last-of-type:border-r-0  py-[9px]  font-medium   2xl:text-xl xl:text-sm lg:text-xs  ${
                            header.id === 'Email Address' && ''
                          }`}
                        >
                          <div className="flex items-center justify-between w-full">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header)}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody
                className={`duration-1000 ${
                  masterMeasurement?.can_create ? 'box-for-master' : 'tbody-cust'
                }`}
              >
                {table.getRowModel().rows.map((row, ind) => (
                  <tr
                    key={row.id}
                    className="clienttable_row even:bg-secondary-3/20 mb-1 flex w-full"
                    // onClick={() => {
                    //   let userId = row.original.id;
                    // }}
                  >
                    {row.getVisibleCells().map((cell, ind) => (
                      <td
                        key={cell.id}
                        className={`duration-1000 col-${ind}-master-ingredient text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3 text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex 2xl:text-base xl:text-sm lg:text-xs`}
                      >
                        {cell.column.id === 'measurement_state' &&
                        EditRowId === cell.row.original.id &&
                        IsEdit ? (
                          <div className="flex w-full justify-between items-center">
                            <div className="">
                              <DropForEdit
                                DefalutValue={DefalutValue}
                                setDefalutValue={setDefalutValue}
                                headers={headers}
                                setState={setStateForEdit}
                                SelectedState={SelectedStateForEdit}
                                setSelectedState={setSelectedStateForEdit}
                                StateForId={StateForId}
                                setStateForId={setStateForId}
                                setStateForEdit={setStateForEdit}
                                setSelectedUnitForEdit={setSelectedUnitForEdit}
                                STATES={cell.row.original.measurement_state}
                              />
                            </div>
                            <div className="mr-6 flex">
                              <Check
                                onClick={() => {
                                  updateIngredient(cell.row.original.id);
                                }}
                                className="cursor-pointer w-5 h-5 mr-2"
                              />
                              <Cancel
                                onClick={() => {
                                  setEditState('');
                                  setEditRowId('');
                                  setIsEdit(false);
                                }}
                                className="cursor-pointer h-4 w-4 mt-1"
                              />
                            </div>
                          </div>
                        ) : cell.column.id === 'quantity' &&
                          EditRowId === cell.row.original.id &&
                          IsEdit ? (
                          <div className="flex justify-center space-x-2">
                            <input
                              placeholder=""
                              className="flex justify-between items-center border w-full text-[#666666] bg-white border-[#E6E6E6] rounded-[10px] px-[10px] py-[13px] 2xl:text-[12px] xl:text-[10px] lg:text-[8px] outline-none"
                              type="number"
                              value={EditQuantity}
                              onChange={(event) => {
                                const newValue = event.target.value;
                                if (
                                  newValue.split('.').length > 1 &&
                                  newValue.split('.')[1].length > 2
                                ) {
                                  return;
                                }
                                if (newValue >= 0) {
                                  setEditQuantity(parseFloat(newValue));
                                }
                              }}
                            />
                            <UnitDropEdit
                              MeasureDrop={MeasureDropNew}
                              SelectedUnit={SelectedUnitForEdit}
                              setSelectedUnit={setSelectedUnitForEdit}
                            />
                          </div>
                        ) : (
                          <>
                            {cell.column.id === 'measurement_state' ? (
                              <div className="flex justify-between w-full">
                                {cell.row.original.measurement_state === -1 ? (
                                  <div style={{ width: '100%', border: 'none' }}>
                                    <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                  </div>
                                ) : (
                                  <div>{cell.row.original.measurement_state}</div>
                                )}
                                <div className="mr-6">
                                  <Edit
                                    onClick={() => {
                                      setEditState(cell.row.original.measurement_state);
                                      const quantityString = cell.row.original.quantity;
                                      const [, quantity, unit] = quantityString.match(
                                        /^(\d*\.?\d+)\s*([a-zA-Z]+)$/
                                      );
                                      setUnits(unit);
                                      setEditQuantity(Number(quantity));
                                      setEditRowId(cell.row.original.id);
                                      setIsEdit(true);
                                    }}
                                    className="cursor-pointer"
                                  />
                                </div>
                              </div>
                            ) : (
                              flexRender(cell.column.columnDef.cell, cell.getContext())
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between md:p-3 text-secondary-1  ">
            <div className="flex ">
              <span className="text-body-color-1 font-Regular 2xl:text-base lg:text-xs">
                Showing {TotalCount.showing} of {TotalCount.final} entries
              </span>
            </div>
            <div className="flex items-center max-w-[50%]">
              <button
                disabled={Page === 1}
                className={`${
                  Page !== 1 ? ' hover:bg-shades-2  hover:text-white ' : 'diabledBtnBg '
                }duration-200 buttons border first-of-type:rounded-s-[10px]   2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                onClick={() => {
                  if (Page > 1) setPage(Page - 1);
                }}
              >
                Previous
              </button>
              <div className="flex overflow-auto" style={{ scrollSnapType: 'x proximity' }}>
                {indexbutton.map((value, id) => {
                  return (
                    <>
                      <button
                        key={id}
                        className={`${
                          Page === value.id
                            ? ' bg-shades-2 text-white duration-1000 '
                            : 'hover:bg-gray-100 duration-300'
                        }  duration-200    buttons border-t border-b  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                        onClick={() => setPage(value.id)}
                        style={{
                          scrollSnapAlign: Page === value.id ? 'start' : 'none',
                        }}
                      >
                        <div className="flex justify-center items-center 2xl:w-[16px] 2xl:h-[24px] xl:w-[19.18px] xl:h-[18px] lg:w-[16px] lg:h-[18px]">
                          {value.id}
                        </div>
                      </button>
                    </>
                  );
                })}
              </div>
              <button
                disabled={Page === indexbutton.length}
                className={`${
                  Page !== indexbutton.length
                    ? ' hover:bg-shades-2  hover:text-white '
                    : 'diabledBtnBg'
                } duration-200    border nxtbutton  last-of-type:rounded-e-[10px]  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                onClick={() => {
                  if (Page < indexbutton.length) setPage(Page + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
