import React, { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import { CountryCode } from './CountryCode';
import { Paper, Popper, TextField } from '@mui/material';
import { ConfirmDelete } from './ConfirmDelete';
import { OptionalPortal } from '@mantine/core';

export const Delete = () => {
  const [Name, setName] = useState('');
  const [OTP, setOTP] = useState(new Array(4).fill(''));
  const [UserID, setUserID] = useState('');
  const [Number, setNumber] = useState('');
  const [Access, setAccess] = useState('');
  const [OtpVerfiy, setOtpVerfiy] = useState(false);
  const [Sent, setSent] = useState(false);
  const [OpenError, setOpenError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({ code: '+91', name: 'India' });

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...OTP];
    newOtp[index] = value.substring(value.length - 1);
    setOTP(newOtp);
    if (value && index < 4 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    const emptyIndex = OTP.indexOf('');
    if (emptyIndex !== -1 && index > 0 && !OptionalPortal[index - 1]) {
      inputRefs.current[emptyIndex].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !OTP[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const sendOTP = () => {
    const body = {
      type: Number ? 'phone' : 'email',
      cc: Number ? selectedCountryCode.code : '',
      identifier: Number ? Number : Name,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/auth/remove/send_otp`, body)
      .then((respone) => {
        const verfiyData = respone.data.data[0];
        const message = respone.data;
        setSent(true);
        setUserID(verfiyData.id);
        toast.success(message.message);
      })
      .catch((error) => {
        const catchingError = error.response.data;
        toast.error(catchingError.message);
      });
  };

  const verifyOTP = () => {
    const body = {
      user_id: UserID,
      otp: OTP.join(''),
    };
    if (!OTP.join('')) {
      return toast.error('Please Enter OTP');
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/auth/remove/verify_otp`, body)
      .then((response) => {
        const finalData = response.data.data[0];
        const message = response.data;
        toast.success(message.message);
        setAccess(finalData.access_token);
        setOtpVerfiy(true);
      })
      .catch((error) => {
        const catchingError = error.response.data;
        toast.error(catchingError.message);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#FEFBF3]">
      <div
        className="flex items-center justify-center bg-[#FFF] w-[80%] rounded-[30px]"
        style={{ boxShadow: '0px 0px 193.3px 40px rgba(185, 185, 185, 0.25)' }}
      >
        <div className="flex justify-center items-center flex-col">
          <br />
          <Logo className="xl:w-[52px] xl:h-[50px]" />
          <span className="font-[600] 2xl:text-[32px] xl:text-[25px] lg:">unlock.fit</span>
          <br />
          <span className="font-[600] 2xl:text-[30px] xl:text-[22px]">Delete Account</span>
          <br />
          <br />
          <div className={`flex flex-col justify-start ${Sent ? 'w-auto' : 'w-[80%]'}`}>
            {!Sent && (
              <>
                <br />
                <div className="flex flex-col">
                  <span className="text-[#9F9F9F]">Email</span>
                  <input
                    type="email"
                    className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                    value={Name}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <br />
                <span className="font-[600] text-[#9F9F9F] text-[15px] text-center">OR</span>
                <br />
                <div className="flex flex-col">
                  <span className="text-[#9F9F9F]">Phone Number</span>
                  <div className="flex justify-between">
                    <div className="w-[30%]">
                      <Autocomplete
                        id="phone"
                        autoHighlight={true}
                        options={CountryCode}
                        getOptionLabel={(option) => option.code}
                        value={selectedCountryCode}
                        onChange={(event, newValue) => {
                          setSelectedCountryCode(newValue);
                        }}
                        filterOptions={(options, state) => {
                          return options.filter(
                            (option) =>
                              option.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                              option.code
                                .replace(/\D/g, '')
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase()) ||
                              option.code.toLowerCase().includes(state.inputValue.toLowerCase())
                          );
                        }}
                        PopperComponent={({ children, ...other }) => (
                          <Popper {...other}>
                            <Paper style={{ width: '300px' }}>{children}</Paper>
                          </Popper>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                padding: '0px',
                                '&:hover fieldset': {
                                  borderColor: '#E6E6E6',
                                },
                                '&:focus': {
                                  outLine: 'none',
                                },
                              },
                              endAdornment: null,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li style={{ fontSize: 15 }} {...props}>
                            <img src={option.flag} alt={option.name} />
                            {`${option.code} ${option.name}`}
                          </li>
                        )}
                        disableClearable={true}
                        style={{
                          backgroundColor: '#fff',
                          borderRadius: 10,
                        }}
                      >
                        <option value="" key="placeholder">
                          Select a country
                        </option>
                      </Autocomplete>
                    </div>
                    <div className="w-[65%]">
                      <input
                        type="phone"
                        className="py-[5px] w-full px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                        value={Number}
                        style={{
                          boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                        }}
                        onChange={(e) => setNumber(e.target.value.replace(/\D/g, ''))}
                      />
                    </div>
                  </div>
                </div>
                <br />
              </>
            )}
            {Sent && (
              <>
                <div className="flex flex-col">
                  <span className="text-[#9F9F9F]">Verify OTP</span>
                  <div className="flex">
                    {OTP.map((value, index) => {
                      return (
                        <input
                          key={index}
                          type="text"
                          className="py-[5px] w-[40px] h-[40px] mr-4 px-[10px] text-center rounded-[10px] border border-[#E6E6E6] outline-none"
                          ref={(input) => (inputRefs.current[index] = input)}
                          value={value}
                          onChange={(e) => handleChange(index, e)}
                          onClick={() => handleClick(index)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                        />
                      );
                    })}
                  </div>
                  {/* <input
                    type="email"
                    className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                    value={OTP}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    onChange={(e) => setOTP(e.target.value)}
                  /> */}
                </div>
                <br />
              </>
            )}
            {OtpVerfiy ? (
              <button
                type="submit"
                className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                onClick={() => {
                  if (Number || Name) {
                    setOpenError(true);
                  } else {
                    toast.error('Please fill out one of the fields');
                  }
                }}
              >
                Delete Account
              </button>
            ) : (
              <button
                type="submit"
                className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                onClick={() => {
                  if (Number || Name) {
                    Sent ? verifyOTP() : sendOTP();
                  } else {
                    toast.error('Please fill out one of the fields');
                  }
                }}
              >
                {Sent ? 'Verify OTP' : 'Send OTP'}
              </button>
            )}
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
        <ConfirmDelete
          setOpenError={setOpenError}
          OpenError={OpenError}
          setNumber={setNumber}
          setselectedCountryCode={setSelectedCountryCode}
          setName={setName}
          setOTP={setOTP}
          Access={Access}
          setSent={setSent}
          setOtpVerfiy={setOtpVerfiy}
        />
      </div>
    </div>
  );
};
