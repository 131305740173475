import { Progress } from '@mantine/core';
import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactComponent as SwapIcon } from '../../../assets/images/swap_vert_small.svg';
import { useEffect } from 'react';
import { getToken } from '../../../Utils/Authenticate';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { ReactComponent as GreenRound } from '../../../assets/images/GreenRound.svg';
import { ReactComponent as DarkRound } from '../../../assets/images/DarkRound.svg';
import { ReactComponent as RedRound } from '../../../assets/images/RedRound.svg';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import { ReactComponent as Collapse } from '../../../assets/images/Colll.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/images/zoom_out_map.svg';
import Tooltip from '@mui/material/Tooltip';

import SortIcon from '../../../assets/images/des.png';
import PopUpForNutrition from '../../shared/PopUpForNutrition';

const columnHelper = createColumnHelper();

const generateMacroTooltipContent = (macros) => (
  <table>
    <thead>
      <tr>
        <th>Nutrition Name</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      {macros?.map((macro, index) => (
        <tr key={index}>
          <td>{macro.name}</td>
          <td>{macro.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const EnergyDistribution = ({
  isFull,
  setIsFull,
  userId,
  planId,
  refreshdata,
  setRefreshData,
  userdata,
  setSelectedTemp,
}) => {
  const [data, setData] = useState(['']);
  const [progValue, setProgValue] = useState(['']);
  const [genValue, setgenValue] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [Macros, setMacros] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);

  const columns = [
    columnHelper.accessor('Nutrition_Name', {
      cell: (info) => (
        <div className="relative">
          {info.row.original.child_macros && info.row.original.child_macros.length > 0 ? (
            <span
              onClick={() => {
                setOpenModal(true);
                setMacros(info.row.original.child_macros);
              }}
              className="capitalize cursor-pointer"
            >
              {info.getValue()}
            </span>
          ) : (
            <span className="">{info.getValue()}</span>
          )}
        </div>
      ),
      header: () => (
        <div className="flex justify-between items-center">
          <span className="w-[165px] 2xl:text-xs xl:text-[10px] lg:text-[8px] font-medium">
            Nutrition Name
          </span>
        </div>
      ),
    }),
    // columnHelper.accessor((row) => row.Required, {
    //   id: 'Required',
    //   cell: (info) => <span>{info.getValue()}</span>,
    //   header: () => (
    //     <div className="flex justify-between items-center">
    //       <span className=" 2xl:text-xs xl:text-[10px] lg:text-[8px]  font-medium">Required</span>
    //     </div>
    //   ),
    // }),
    columnHelper.accessor('Consumed', {
      header: () => (
        <div className="flex justify-between items-center">
          <span className=" 2xl:text-xs xl:text-[10px] lg:text-[8px]  font-medium">Consumed</span>
        </div>
      ),
    }),
    // columnHelper.accessor('progress', {
    //   header: '',
    //   cell: (info) => <i>{info.getValue()}</i>,
    // }),
  ];

  useEffect(() => {
    // setSelectedTemp('');
    let token = getToken();
    let combinedNutritionProfile = [];
    userdata.forEach((meal) => {
      combinedNutritionProfile = combinedNutritionProfile.concat(meal.nutrition_profile);
    });
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/v1/energy_distribution`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        plan_id: planId,
        template_id: userId,
        nutrition: combinedNutritionProfile,
      },
    };
    axios
      .request(config)
      .then(({ data }) => {
        let _data = data.data[0];
        setProgValue(_data.energy_distrubution);
        setgenValue(_data.general_calculation);
        let newData = _data.nutrition_profile.map((row, id) => {
          const num_of_dots = row.dots_data.dot_num;
          const arr = [];
          for (let i = 0; i < 5; i++) {
            if (i < num_of_dots) {
              if (row.dots_data.color === 'red') {
                arr.push(<RedRound className=" lg:w-[10px] lg:h-[10px]  md:h-[8px] md:w-[8px]" />);
              } else if (row.dots_data.color === 'green') {
                arr.push(
                  <GreenRound className=" lg:w-[10px] lg:h-[10px]  md:h-[8px] md:w-[8px]" />
                );
              }
            } else {
              arr.push(<DarkRound className=" lg:w-[10px] lg:h-[10px]  md:h-[8px] md:w-[8px]" />);
            }
          }
          return {
            Nutrition_Name: row.nutrition_name + ' ( ' + row.nutrition_unit + ' )',
            Required: row.rda_value,
            Consumed: row.in_diet,
            child_macros: row.child_macros,
            progress: (
              <div className="flex gap-[10px]">
                {arr.map((ele) => {
                  return ele;
                })}
              </div>
            ),
          };
        });

        setData(newData);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [userdata, userId, planId, refreshdata]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    setSorting([]);
  }, [data]);

  const formattedNutrients = (nutritionProfile) => {
    if (
      !nutritionProfile ||
      nutritionProfile.length === 0 ||
      nutritionProfile.every((nutrient) => !nutrient.key)
    ) {
      return (
        <>
          <div className="2xl:text-base xl:text-sm lg:text-xs">Calories- 0kcal,</div>
          <div className="2xl:text-base xl:text-sm lg:text-xs">Carbs - 0g,</div>
          <div className="2xl:text-base xl:text-sm lg:text-xs">Proteins- 0g,</div>
          <div className="2xl:text-base xl:text-sm lg:text-xs">Fat- 0g</div>
        </>
      );
    } else {
      return nutritionProfile.map((nutrient, index) => {
        const { unit, value, key } = nutrient;
        const formattedValue = value || 0;
        const displayUnit = unit ? ` ${unit}` : '';

        return (
          <div key={index} className="2xl:text-[13px] xl:text-[11px] lg:text-[8px]">
            {key} - {formattedValue}
            {displayUnit}
            {index !== nutritionProfile.length - 1 && ', '}
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className={`border-b lg:px-4 flex justify-between items-center w-full`}>
        <span className=" lg:py-3 md:py-2 flex 2xl:text-base xl:text-sm lg:text-xs  text-body-color-2 font-Regular">
          Energy Distribution
        </span>
        {/* <div className="cursor-pointer" onClick={() => setIsFull(!isFull)}>
          {isFull ? <Collapse /> : <ExpandIcon />}
        </div> */}
      </div>
      <div className=" xl:px-4 lg:px-1 lg:mt-4 md:mt-2 md:px-0 ">
        <div>
          <div className=" lg:mt-4 md:mt-2">
            <div>
              <table className="min-w-[-webkit-fill-available]">
                <thead className="bg-tint-1/20">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, array) =>
                        !isFull && index === 3 ? null : (
                          <th
                            key={header.id}
                            className={`   2xl:text-xs xl:text-[10px] lg:text-[8px]   text-start 2xl:px-[10px] 2xl:py-[11px] lg:py-[7px] lg:px-[8px] md:py-[5px] md:px-[6px]  ${
                              header.id === 'Nutrition_Name' && 'w-[50%]'
                              // || (header.id === 'progress' && '  w-[20%]')
                            }`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <div className="flex items-center justify-between w-full  2xl:text-xs xl:text-[10px] lg:text-[8px] ">
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header)}
                              {array.length - 1 === index
                                ? null
                                : {
                                    asc: (
                                      <img
                                        src={SortIcon}
                                        className="  lg:w-[11.85px] lg:h-[11.85px] md:h-3 md:w-3"
                                        style={{ transform: 'rotate(180deg)' }}
                                        alt="SortIcon"
                                      />
                                    ),
                                    desc: (
                                      <img
                                        src={SortIcon}
                                        className="  lg:w-[11.85px] lg:h-[11.85px] md:h-3 md:w-3"
                                        alt="SortIcon"
                                      />
                                    ),
                                    false: (
                                      <Swap className="  lg:w-[11.85px] lg:h-[11.85px] md:h-3 md:w-3" />
                                    ),
                                  }[header.column.getIsSorted()]}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="even:bg-secondary-3/20">
                      {row.getVisibleCells().map((cell, index) =>
                        !isFull && index === 3 ? null : (
                          <td
                            key={cell.id}
                            className=" text-start    2xl:text-xs xl:text-[10px] lg:text-[8px]   lg:py-[9px] lg:px-[8px] md:py-[7px] md:px-[6px] font-Regular text-body-color-2  "
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            {/* {isFull && flexRender(cell.column.columnDef.cell, cell.getContext())} */}
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PopUpForNutrition OpenModal={OpenModal} setOpenModal={setOpenModal} macros={Macros} />
    </>
  );
};

export default EnergyDistribution;
