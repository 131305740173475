import { useEffect, useState, useMemo } from 'react';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { HashLoader } from 'react-spinners';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Payment({ headers, userId, customer }) {
  const initialRows = Array(15).fill({
    plan_name: -1,
    payment_date: -1,
    payment_method: -1,
    payment_id: -1,
    amount_paid: -1,
    coupon_code: -1,
    channel_partner: -1,
    discount_percentage: -1,
    status: -1,
    is_uploaded: -1,
  });
  const [Payment, setPayment] = useState({
    columns: [
      { id: 0, column_name: 'Plan Name', map_column: 'plan_name', type: 'string' },
      { id: 1, column_name: `Payment Date`, map_column: 'payment_date', type: 'string' },
      { id: 3, column_name: 'Payment Method', map_column: 'payment_method', type: 'string' },
      { id: 4, column_name: 'Payment ID', map_column: 'payment_id', type: 'string' },
      { id: 5, column_name: 'Amount Paid', map_column: 'amount_paid', type: 'string' },
      { id: 6, column_name: 'Coupon Code', map_column: 'coupon_code', type: 'string' },
      { id: 7, column_name: 'Channel Partner', map_column: 'channel_partner', type: 'string' },
      { id: 8, column_name: 'Discount %', map_column: 'discount_percentage', type: 'string' },
      { id: 9, column_name: 'Status', map_column: 'status', type: 'string' },
      customer?.can_view && customer?.can_create
        ? {
            id: 10,
            column_name: 'Invoice',
            map_column: 'is_uploaded',
            type: 'string',
          }
        : null,
    ].filter((item) => item !== null),
    rows: initialRows,
  });
  const [sorting, setSorting] = useState([]);
  const [Overlay, setOverlay] = useState(false);

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const { columns } = Payment;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Payment]);

  const table = useReactTable({
    data: Payment.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchPayment = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/payment_details/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        setPayment({ ...Payment, rows: finalData });
        setOverlay(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  const uploadPdf = (pdf, subscribe) => {
    const formData = new FormData();
    formData.append('files', pdf);
    setOverlay(true);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/user/profile/upload_invoice?subscription_id=${subscribe}&user_id=${userId}`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        const getPDFurl = response.data;
        fetchPayment();
        toast.success(getPDFurl.message);
      })
      .catch((error) => {
        const getPdf = error.response.data;
        toast.error(getPdf.message);
      });
  };

  return (
    <div className="w-full">
      {Overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]">
            Please Wait PDF is Getting Uploaded{' '}
          </div>
        </div>
      )}
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            Invoice
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start payment-${index} bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        {array?.length - 1 === index + 10
                          ? null
                          : {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 sale-table">
            {table.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000 payment-${index} text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    `}
                  >
                    {cell.column.id === 'is_uploaded' ? (
                      <>
                        {cell.row.original.is_uploaded === -1 ? (
                          <div style={{ width: '100%', border: 'none' }}>
                            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                          </div>
                        ) : (
                          <div>
                            {cell.row.original.is_uploaded ? (
                              <div
                                className="cursor-pointer flex justify-center text-[#FFFFFF] text-[13px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] p-[8px]"
                                onClick={() => {
                                  window.open(cell.row.original.invoice_url, '_blank');
                                }}
                              >
                                View
                              </div>
                            ) : (
                              <label htmlFor="fileInput">
                                <input
                                  id="fileInput"
                                  type="file"
                                  accept="application/pdf"
                                  style={{ display: 'none' }}
                                  onChange={(event) => {
                                    const file = event.target.files[0];
                                    if (file) {
                                      const fileType = file.type;
                                      if (fileType !== 'application/pdf') {
                                        toast.error(
                                          'Unsupported file format. Please select an PDF file.'
                                        );
                                      } else if (file.size > 15 * 1024 * 1024) {
                                        toast.error('File Size should not be greater than 15 mb.');
                                      } else {
                                        uploadPdf(file, cell.row.original.subscribe_id);
                                      }
                                    }
                                  }}
                                />
                                <div
                                  className="cursor-pointer flex justify-center text-[#FFFFFF] text-[13px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] px-[15px] py-[8px]"
                                  // onClick={() => setExportPop(true)}
                                >
                                  Upload
                                </div>
                              </label>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
