import { useEffect, useState } from 'react';
import axios from 'axios';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { ReactComponent as Add } from '../../../../assets/images/add.svg';
import { ReactComponent as ArrayBack } from '../../../../assets/images/arrow_back.svg';
import { ReactComponent as Search } from '../../../../assets/images/search.svg';
import { ReactComponent as Delete } from '../../../../assets/images/delete.svg';
import { ReactComponent as DeSelect } from '../../../../assets/images/deselect_for_macro.svg';
import { ReactComponent as Select } from '../../../../assets/images/select_for_macro.svg';
import { ReactComponent as MacroSelect } from '../../../../assets/images/select_for_role.svg';
import { ReactComponent as MacroDeSelect } from '../../../../assets/images/deselect.svg';
import { HashLoader } from 'react-spinners';
import Tooltip from '@mui/material/Tooltip';
import IngrImg from '../../../../assets/images/dish.png';
import CloseIcon from '@mui/icons-material/Close';
import 'react-loading-skeleton/dist/skeleton.css';
import PagesHeader from '../../../shared/PagesHeader';
import MeasurementDropDown from './MeasurementDropDown';
import CustomizedHook from './CustomizedHook';
import { CustomMeasurement } from './CustomMeasurement';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getToken } from '../../../../Utils/Authenticate';
import { toast } from 'react-toastify';

export default function IngredientsAdd() {
  const location = useLocation();
  const navigate = useNavigate();
  const [CusotmPop, setCusotmPop] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [ImageURL, setImageURL] = useState('');
  const Check = location.state;
  const [IngridientDetails, setIngridientDetails] = useState({
    img_url: '',
    name: '',
    description: '',
    has_macros: true,
    tags: [],
    measurement: [],
    macro_nutrients: [
      {
        id: 9,
        name: 'Calories',
        unit: 'Kcal',
        value: 0,
      },
      {
        id: 8,
        name: 'Carbs',
        unit: 'gm',
        value: 0,
      },
      {
        id: 2,
        name: 'Protein',
        unit: 'gm',
        value: 0,
      },
      {
        id: 4,
        name: 'Fat',
        unit: 'gm',
        value: 0,
      },
    ],
  });
  const [Macros, setMacros] = useState([]);
  const [query, setQuery] = useState('');
  const [MeasurementData, setMeasurementData] = useState([]);
  const [SelectedValue, setSelectedValue] = useState('');
  const maxWordCount = 200;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= maxWordCount) {
      setIngridientDetails((prevDetails) => {
        return {
          ...prevDetails,
          description: newText,
        };
      });
    }
  };

  const wordCount = IngridientDetails.description ? IngridientDetails.description.length : '';

  let accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const getMeasurements = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/units/search/?search=${query}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const measurement = response.data;
        setMeasurementData(measurement.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMeasurements();
  }, [query]);

  const getMacros = (MacroQuery) => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/admin/nutrition_plans/v1/macros?size=500&page=1&ingredient_id=${
          Check !== 'isAdd' ? Check : ''
        }&search=${MacroQuery ? MacroQuery : ''}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const getMacros = response.data;
        setMacros(getMacros.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMacros();
  }, []);

  const getDataForEdit = (ingredientId) => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/${ingredientId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const getData = response.data.data;
        setImageURL(getData[0].view_image);
        setIngridientDetails(getData[0]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const addMeasurement = (master) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/measurement`,
        {
          ingredient_id: Check,
          name: master.name,
          value: master.value,
          unit: master.unit,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalChanges = response.data;
        setIngridientDetails((prevDetails) => {
          return {
            ...prevDetails,
            measurement: finalChanges.data,
          };
        });
        setSelectedValue('');
      })
      .catch((error) => {
        const finalChanges = error.response.data;
      });
  };

  const deleteMeasurement = (master) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/measurement/${master}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalChanges = response.data;
        setIngridientDetails((prevDetails) => {
          return {
            ...prevDetails,
            measurement: finalChanges.data,
          };
        });
        setSelectedValue('');
      })
      .catch((error) => {
        const finalChanges = error.response.data;
        toast.error(finalChanges.message);
      });
  };

  useEffect(() => {
    if (Check !== 'isAdd') getDataForEdit(Check);
  }, [Check]);

  const addUpdateIngredient = () => {
    const filteredMeasurement = IngridientDetails.measurement.filter(
      (item) => !((item.id === '' || item.id === 0) && item.name === '' && item.value === '')
    );
    const filterTags = IngridientDetails.tags.map((tag) => tag.name);
    let macroFilterForAdd = [];

    if (IngridientDetails.macro_nutrients.length === 0) {
      macroFilterForAdd = [
        {
          id: 9,
          name: 'Calories',
          unit: 'Kcal',
          value: 0,
        },
        {
          id: 8,
          name: 'Carbs',
          unit: 'gm',
          value: 0,
        },
        {
          id: 2,
          name: 'Protein',
          unit: 'gm',
          value: 0,
        },
        {
          id: 4,
          name: 'Fat',
          unit: 'gm',
          value: 0,
        },
      ];
    } else {
      macroFilterForAdd = IngridientDetails.macro_nutrients.map((macros) => ({
        id: macros.id,
        name: macros.name,
        unit: macros.unit,
        value: Number(macros.value ? macros.value : 0),
      }));
    }

    const filterTagsForUpdate = IngridientDetails.tags.map((tagName) => ({ name: tagName.name }));
    let macroFilterForUpdate = [];

    if (IngridientDetails.macro_nutrients.length === 0) {
      macroFilterForUpdate = [
        {
          name: 'Calories',
          value: 0,
        },
        {
          name: 'Carbs',
          value: 0,
        },
        {
          name: 'Protein',
          value: 0,
        },
        {
          name: 'Fat',
          value: 0,
        },
      ];
    } else {
      macroFilterForUpdate = IngridientDetails.macro_nutrients.map((macros) => ({
        name: macros.name,
        value: Number(macros.value ? macros.value : 0),
      }));
    }
    if (IngridientDetails.name) {
      if (filteredMeasurement.length !== 0) {
        if (filterTags || filterTagsForUpdate) {
          if (Check === 'isAdd') {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient`,
                {
                  ...IngridientDetails,
                  measurement: filteredMeasurement,
                  tags: filterTags,
                  macro_nutrients: macroFilterForAdd,
                },
                {
                  headers: headers,
                }
              )
              .then((response) => {
                const addedData = response.data;
                toast.success(addedData.message);
                const dataForEdit = addedData.data[0];
                navigate(`/nutrition/ingredient/edit/${dataForEdit.id}`, {
                  state: dataForEdit.id,
                });
              })
              .catch((error) => {
                const addedData = error.response.data;
                toast.error(addedData.message);
              });
          } else {
            axios
              .patch(
                `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/${Check}`,
                {
                  ...IngridientDetails,
                  measurement: filteredMeasurement,
                  tags: filterTagsForUpdate,
                  macro_nutrients: macroFilterForUpdate,
                },
                {
                  headers: headers,
                }
              )
              .then((response) => {
                const addedData = response.data;
                getMacros();
                toast.success(addedData.message);
              })
              .catch((error) => {
                const addedData = error.response.data;
                toast.error(addedData.message);
              });
          }
        } else {
          toast.error('Ingredient tags are mandatory. Please add aleast a tag');
        }
      } else {
        toast.error('Please Select a Measurement');
      }
    } else {
      toast.error('Please Enter Ingredient Name');
    }
  };

  const uploadImage = (imag) => {
    const formData = new FormData();
    formData.append('files', imag);
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/admin/nutrition_plans/v1/ingredient/image/upload?ingredient_id=${
          Check !== 'isAdd' ? Check : ''
        }`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        const getImageURL = response.data.data[0];
        setIngridientDetails((prevDetails) => ({
          ...prevDetails,
          img_url: getImageURL.url,
        }));
        setImageURL(getImageURL.view_image);
      })
      .catch((error) => {
        const getImageURL = error.response.data;
        toast.error(getImageURL.message);
      });
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const currentText = IngridientDetails.description || '';
    const newText = currentText + pastedText.substring(0, maxWordCount - currentText.length);
    event.preventDefault();

    setIngridientDetails((prevDetails) => {
      return {
        ...prevDetails,
        description: newText,
      };
    });
  };

  const predefinedNutrients = ['Calories', 'Carbs', 'Protein', 'Fat'];

  // const updateMeasurement = (data, newVal) => {
  //   axios
  //     .patch(
  //       `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/ingredient/measurement/${data.id}`,
  //       {
  //         name: data.name,
  //         unit: data.unit,
  //         value: newVal,
  //       },
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       const finalMeasurement = response.data.data;
  //       console.log('first', finalMeasurement);
  //     });
  // };

  const element = document.getElementById('shadow-Dropdown-Shadow');

  return (
    <div>
      {Loader && (
        <div className="fixed h-screen w-screen z-[99] inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]"></div>
        </div>
      )}
      <PagesHeader
        name={Check === 'isAdd' ? 'Add Ingredient' : 'Edit Ingredient'}
        array={<ArrayBack className="cursor-pointer" />}
        secondHeading={Check === 'isAdd' ? 'Add Ingredient' : 'Edit Ingredient'}
        thirdHeading={Check === 'isAdd' ? 'Add Ingredient' : 'Edit Ingredient'}
      />
      <div
        id="shadow-Dropdown-Shadow"
        className="shadow-Dropdown-Shadow bg-[#FFF] rounded-[20px] ingredient-box overflow-auto"
      >
        <div className="name-and-save flex items-center justify-between 2xl:py-[18px] xl:py-[16px] lg:py-[14px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] border-b">
          <div className="flex w-full items-center">
            <textarea
              placeholder="Enter Ingredient Name"
              value={IngridientDetails.name}
              onChange={(event) => {
                setIngridientDetails({ ...IngridientDetails, name: event.target.value });
              }}
              cols={
                IngridientDetails.name.length === 0
                  ? '30'
                  : IngridientDetails.name.length > 8
                  ? '50'
                  : '10'
              }
              rows={
                IngridientDetails.name.length > 60
                  ? '3'
                  : IngridientDetails.name.length > 40
                  ? '2'
                  : '1'
              }
              className="border-b mr-[10px] min-h-[40px] resize-none outline-none cursor-text 2xl:text-[24px] xl:text-[22px] lg:text-[20px]"
            />
            {/* {EditName ? (
            ) : (
              <div
                data-placeholder="Enter Ingredient Name"
                dangerouslySetInnerHTML={{ __html: IngridientDetails.name }}
                className="border-b mr-[10px] outline-none cursor-text 2xl:text-[24px] xl:text-[22px] lg:text-[20px] break-all"
              />
            )} */}
            {/* {EditName ? (
              <Apply
                className="h-[20px] w-[20px] cursor-pointer"
                onClick={() => {
                  setEditName(false);
                }}
              />
            ) : (
              <CreateOutlinedIcon
                className="text-[#D9D9D9] !text-[20px] cursor-pointer"
                onClick={() => {
                  setEditName(true);
                }}
              />
            )} */}
          </div>
          <div
            className="cursor-pointer h-fit flex justify-center items-center text-[#FFFFFF] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] px-[40px] py-[8px]"
            onClick={() => addUpdateIngredient()}
          >
            Save
          </div>
        </div>
        <div className="img-desc-tags flex p-[20px] w-full">
          <div className="img-for-ingredient flex justify-center w-[20%] 2xl:px-7 xl:px-6 lg:px-5">
            <div className="2xl:w-[200px] 2xl:h-[200px] xl:w-[170px] xl:h-[170px] lg:w-[140px] lg:h-[140px] relative">
              <label
                htmlFor="imageInput"
                className="h-full w-full bg-brand-color rounded-[50%] flex justify-center items-center"
              >
                <img
                  src={ImageURL ? ImageURL : IngrImg}
                  alt="Selected"
                  className="h-[96%] w-[96%] bg-shades-2 rounded-[50%] object-cover object-top flex justify-center items-center text-center"
                />
              </label>
              <div className="absolute 2xl:right-[15px] 2xl:bottom-6 xl:right-[9px] xl:bottom-6 lg:right-[2px] lg:bottom-4 bg-brand-color rounded-[50%]">
                <label htmlFor="fileInput">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      if (file) {
                        const fileType = file.type.split('/')[0];
                        if (fileType !== 'image') {
                          toast.error('Unsupported file format. Please select an image file.');
                        } else if (file.size > 10 * 1024 * 1024) {
                          toast.error('File Size should not be greater than 10 mb.');
                        } else {
                          uploadImage(file);
                        }
                      }
                    }}
                  />
                  <CreateOutlinedIcon className="p-1 text-body-color-3 cursor-pointer" />
                </label>
              </div>
            </div>
          </div>
          <div className="description w-[40%] pr-[20px]">
            <div className="2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
              Description
              <span className="text-[#666666] 2xl:text-[12px] xl:text-[10px] lg:text-[8px] 2xl:ml-[12px] xl:ml-[10px] lg:ml-[8px]">
                (Write a short description about the recipe.)
              </span>
            </div>
            <div className="relative text-[12px] mt-[10px]">
              <textarea
                className="rounded-[10px] 2xl:min-h-[142px] xl:min-h-[132px] lg:min-h-[112px] 2xl:text-[14px] xl:text-[12px] lg:text-[10px] border border-[#E6E6E6] p-[10px] w-full resize-none outline-none"
                placeholder="Write your description here"
                value={IngridientDetails.description}
                onChange={handleTextChange}
                onPaste={handlePaste}
                style={{ boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.12)' }}
              />
              <div className="absolute right-[10px] bottom-[5px] text-[#CCC] 2xl:text-[12px] xl:text-[10px] lg:text-[8px]">
                {wordCount}/{maxWordCount}
              </div>
            </div>
          </div>
          <div className="tags w-[40%]">
            <div className="flex justify-between mb-[10px]">
              <div className="2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
                Tags
                {/* <span className="text-[#666666] 2xl:text-[12px] xl:text-[10px] lg:text-[8px] 2xl:ml-[12px] xl:ml-[10px] lg:ml-[8px]">
                  (upto 5 tags at max.)
                </span> */}
              </div>
              <div
                className="flex items-center cursor-pointer 2xl:text-[16px] xl:text-[14px] lg:text-[12px]"
                onClick={() =>
                  setIngridientDetails((prevDetails) => ({
                    ...prevDetails,
                    has_macros: !prevDetails.has_macros,
                    macro_nutrients: prevDetails.has_macros
                      ? []
                      : [
                          {
                            id: 9,
                            name: 'Calories',
                            unit: 'Kcal',
                            value: '',
                          },
                          {
                            id: 8,
                            name: 'Carbs',
                            unit: 'gm',
                            value: '',
                          },
                          {
                            id: 2,
                            name: 'Protein',
                            unit: 'gm',
                            value: '',
                          },
                          {
                            id: 4,
                            name: 'Fat',
                            unit: 'gm',
                            value: '',
                          },
                        ],
                  }))
                }
              >
                {!IngridientDetails.has_macros ? (
                  <MacroDeSelect className="mr-[5px]" />
                ) : (
                  <MacroSelect className="mr-[5px]" />
                )}
                Update Macros
              </div>
            </div>
            <div
              className="rounded-[10px] overflow-auto 2xl:max-h-[142px] xl:max-h-[132px] lg:max-h-[112px] 2xl:min-h-[142px] xl:min-h-[132px] lg:min-h-[112px] border border-[#E6E6E6] p-[10px] w-full resize-none outline-none text-[12px]"
              style={{ boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.12)' }}
            >
              <div className="flex flex-wrap ">
                {IngridientDetails?.tags?.map((tags, index) => (
                  <>
                    <div className="flex items-center 2xl:text-[14px] xl:text-[12px] lg:text-[10px] mr-[10px] pl-[10px] 2xl:min-h-[30px] xl:min-h-[28px] lg:min-h-[26px] bg-[#E9D396] rounded-[10px] mb-[10px] outline-none">
                      <span className="break-all">{tags.name}</span>
                      <CloseIcon
                        className="2xl:!text-[20px] xl:!text-[18px] lg:!text-[16px] h-[100%!important]"
                        style={{
                          color: '#fff',
                          marginLeft: 5,
                          borderRadius: '0px 10px 10px 0px',
                          backgroundColor: 'ac872e',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setIngridientDetails((prevDetails) => {
                            const newTags = [...prevDetails.tags];
                            newTags.splice(index, 1);
                            return { ...prevDetails, tags: newTags };
                          });
                        }}
                      />
                    </div>
                  </>
                ))}
                <CustomizedHook
                  setIngridientDetails={setIngridientDetails}
                  IngridientDetails={IngridientDetails}
                />
              </div>
              {/* <CustomizedHook
                IngridientDetails={IngridientDetails}
                setIngridientDetails={setIngridientDetails}
              /> */}
            </div>
          </div>
        </div>
        <div className="measure-and-macros flex p-[20px]">
          <div className="measure w-1/2 pr-[20px]">
            <div className="2xl:text-[16px] xl:text-[14px] lg:text-[12px] font-medium">
              Measuring Unit
              <span className="text-[#666666] 2xl:text-[12px] xl:text-[10px] lg:text-[8px] 2xl:ml-[12px] xl:ml-[10px] lg:ml-[8px] font-Regular">
                (Enter the measuring unit for the ingredient)
              </span>
            </div>
            <div className="flex justify-between 2xl:mt-[18px] xl:mt-[16px] lg:mt-[14px]">
              <MeasurementDropDown
                setQuery={setQuery}
                MeasurementData={MeasurementData}
                SelectedValue={SelectedValue}
                setSelectedValue={setSelectedValue}
              />
              <div
                className={`${
                  SelectedValue ? 'cursor-pointer' : ''
                } flex justify-center items-center 2xl:text-[14px] xl:text-[12px] lg:text-[10px] text-[#FFFFFF] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] 2xl:px-[15px] xl:px-[13px] lg:px-[11px] 2xl:py-[6px] xl:py-[4px] lg:py-[2px]`}
                onClick={() => {
                  if (Check !== 'isAdd') {
                    const isDuplicate = IngridientDetails.measurement.some(
                      (existingMeasurement) => {
                        return (
                          existingMeasurement.name === SelectedValue.name &&
                          existingMeasurement.unit === SelectedValue.unit &&
                          existingMeasurement.value === SelectedValue.value
                        );
                      }
                    );
                    if (!isDuplicate) {
                      addMeasurement(SelectedValue);
                    } else {
                      toast.error('Measuring Unit is already selected.');
                      setSelectedValue('');
                    }
                  } else {
                    if (SelectedValue) {
                      const isDuplicate = IngridientDetails.measurement.some(
                        (existingMeasurement) => {
                          return (
                            existingMeasurement.name === SelectedValue.name &&
                            existingMeasurement.unit === SelectedValue.unit &&
                            existingMeasurement.value === SelectedValue.value
                          );
                        }
                      );
                      if (!isDuplicate) {
                        setIngridientDetails((prevDetails) => {
                          return {
                            ...prevDetails,
                            measurement: [...prevDetails.measurement, SelectedValue],
                          };
                        });
                      } else {
                        toast.error('Measuring Unit is already selected.');
                      }
                    }
                    setSelectedValue('');
                  }
                }}
              >
                <Add className="2xl:mr-[10px] xl:mr-[8px] lg:mr-[6px] 2xl:h-[22px] 2xl:w-[22px] xl:h-[20px] xl:w-[20px] lg:h-[18px] lg:w-[18px]" />
                Add
              </div>
            </div>
            <div
              className="rounded-[10px] border border-[#E6E6E6] w-full resize-none outline-none mt-[20px] 2xl:min-h-[190px] xl:min-h-[170px] lg:min-h-[150px] 2xl:max-h-[190px] xl:max-h-[170px] lg:max-h-[150px] overflow-y-auto"
              style={{ boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.12)' }}
            >
              {IngridientDetails?.measurement.length !== 0 ? (
                <ul
                  className={`w-full rounded-[10px] 2xl:text-[14px] xl:text-[12px] lg:text-[10px]`}
                >
                  {IngridientDetails?.measurement?.map((data, index) => (
                    <li
                      className={`flex justify-between items-center w-full 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] ${
                        index % 2 !== 0 ? 'bg-[#D2A62C26]' : ''
                      } `}
                    >
                      <div>{data.name}</div>
                      <Delete
                        className="cursor-pointer 2xl:h-[22px] 2xl:w-[19px] xl:h-[20px] xl:w-[17x] lg:h-[20px] lg:w-[15px]"
                        onClick={() => {
                          if (Check !== 'isAdd') {
                            deleteMeasurement(data.id);
                          } else {
                            setIngridientDetails((prevDetails) => {
                              const updatedMeasurement = [...prevDetails.measurement];
                              updatedMeasurement.splice(index, 1);
                              return { ...prevDetails, measurement: updatedMeasurement };
                            });
                          }
                        }}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="flex justify-center items-center 2xl:min-h-[188px] xl:min-h-[168px] lg:min-h-[148px] 2xl:max-h-[188px] xl:max-h-[168px] lg:max-h-[148px]">
                  No Measurement
                </div>
              )}
            </div>
          </div>
          <div className="macros w-1/2">
            <div className="2xl:text-[16px] xl:text-[14px] lg:text-[12px] font-medium">
              Add Macro-Nutrients
            </div>
            <Tooltip
              title={!IngridientDetails.has_macros ? 'You need to select Update macros' : ''}
              placement="top"
            >
              <div
                className="rounded-[10px] border max-h-[332px] border-[#E6E6E6] w-full resize-none outline-none"
                style={{ boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.12)' }}
              >
                <div
                  className="flex text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] mx-[25px] mt-[10px] mb-[20px] px-[10px] py-[8px]"
                  style={{
                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <Search className="my-[4px] mr-[4px] 2xl:h-[16px] 2xl:w-[16px] xl:h-[14px] xl:w-[14px] lg:h-[12px] lg:w-[12px]" />
                  <input
                    placeholder="Search"
                    type="text"
                    disabled={!IngridientDetails.has_macros}
                    className="w-full 2xl:text-[16px] xl:text-[14px] lg:text-[12px] bg-[#FFFFFF] text-[#666666] outline-none"
                    onChange={(e) => getMacros(e.target.value)}
                  />
                </div>
                <div className="2xl:min-h-[200px] xl:min-h-[180px] lg:min-h-[160px] 2xl:max-h-[200px] xl:max-h-[180px] lg:max-h-[160px] overflow-auto">
                  {Macros?.map((micros, index) => (
                    <>
                      <div
                        className={`flex items-center border-b py-[8px] px-[20px] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] ${
                          !IngridientDetails.has_macros || predefinedNutrients.includes(micros.name)
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`}
                        onClick={() => {
                          setTimeout(() => {
                            element.scrollTop = element.scrollHeight;
                          }, 1000);
                          const isPredefinedNutrient = predefinedNutrients.includes(micros.name);
                          const exists = IngridientDetails.macro_nutrients.some(
                            (macro) =>
                              macro.name.toLocaleLowerCase() === micros.name.toLocaleLowerCase()
                          );
                          if (IngridientDetails.has_macros)
                            if (exists && !isPredefinedNutrient) {
                              setIngridientDetails((prevDetails) => ({
                                ...prevDetails,
                                macro_nutrients: prevDetails.macro_nutrients.filter(
                                  (macro) =>
                                    macro.name.toLocaleLowerCase() !==
                                    micros.name.toLocaleLowerCase()
                                ),
                              }));
                            } else if (!exists) {
                              setIngridientDetails((prevDetails) => ({
                                ...prevDetails,
                                macro_nutrients: [
                                  ...prevDetails.macro_nutrients,
                                  { ...micros, value: 0 },
                                ],
                              }));
                            }
                        }}
                      >
                        <div className="mr-[10px]">
                          {IngridientDetails.macro_nutrients.some(
                            (macro) =>
                              macro.name.toLocaleLowerCase() === micros.name.toLocaleLowerCase()
                          ) ? (
                            <Select />
                          ) : (
                            <DeSelect />
                          )}
                        </div>
                        {micros.name}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
        {IngridientDetails.has_macros && (
          <div className="micro-nutrients">
            <div className="text-[#666] 2xl:text-[24px] xl:text-[22px] lg:text-[20px] border-y py-[15px] px-[10px]">
              Add macro-nutrients according to Table spoon 100gm of ingredient
            </div>
            <div className="mircos flex flex-wrap gap-[15px] mt-[20px] px-[20px] pb-[20px]">
              {IngridientDetails.macro_nutrients?.map((micros, index) => (
                <div
                  className="flex bg-[#FFFFFF] mx-[5px] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] min-w-[23%] justify-between items-center font-normal border border-[#E6E6E6] rounded-[10px] px-[10px] py-[8px]"
                  style={{
                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <div className="mr-3 font-medium">{micros.name}</div>
                  <div className="flex items-center">
                    <input
                      placeholder={`in ${micros?.unit}`}
                      type="number"
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (newValue.split('.').length > 1 && newValue.split('.')[1].length > 2) {
                          return;
                        }
                        if (newValue >= 0) {
                          setIngridientDetails((prevDetails) => ({
                            ...prevDetails,
                            macro_nutrients: prevDetails.macro_nutrients.map((item, i) =>
                              i === index ? { ...item, value: parseFloat(newValue) } : item
                            ),
                          }));
                        }
                      }}
                      value={micros?.value}
                      className="w-[60px] py-[5px] text-[#666666] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] border-b outline-none text-center"
                    />
                    {(micros?.value || micros?.value === 0) && <div>{micros.unit}</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <CustomMeasurement
        headers={headers}
        CusotmPop={CusotmPop}
        getMeasurements={getMeasurements}
        setCusotmPop={setCusotmPop}
        setIngridientDetails={setIngridientDetails}
        IngridientDetails={IngridientDetails}
      />
    </div>
  );
}
