import React, { useEffect, useState, useRef } from 'react';
import _ from 'underscore';
import CustomerInfo from '../layout/DashBoard/CustomerInfo';
import History from '../layout/DashBoard/History';
import Report from '../layout/DashBoard/Report';
import Referal from '../layout/DashBoard/Referal';
import Payment from '../layout/DashBoard/Payment';
import NPSScore from '../layout/DashBoard/NPSScore';
import PagesHeader from '../shared/PagesHeader';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import { ReactComponent as ArrayBack } from '../../assets/images/arrow_back.svg';
import axios from 'axios';

export default function CustomerDashboard() {
  const [CurrentTab, setCurrentTab] = useState('customer_profile');
  let { userId } = useParams();
  const [tabs, settabs] = useState([
    { key: 'customer_profile', label: 'Customer Profile', permission: true },
    { key: 'consultation_history', label: 'Consultation History', permission: true },
    { key: 'reports', label: 'Reports', permission: true },
    { key: 'referrals', label: 'Referrals', permission: true },
    { key: 'nps_score', label: 'NPS Score', permission: true },
    { key: 'payment_details', label: 'Payment Details', permission: true },
  ]);
  const dashboardRef = useRef(null);
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const customer = permission?.role_permissions?.find(
    (permission) => permission.name === 'Customer Dashboard'
  );

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const tabComponents = {
    customer_profile: <CustomerInfo headers={headers} userId={userId} />,
    consultation_history: <History headers={headers} userId={userId} />,
    reports: <Report headers={headers} userId={userId} />,
    referrals: <Referal headers={headers} userId={userId} />,
    nps_score: <NPSScore headers={headers} userId={userId} />,
    payment_details: <Payment headers={headers} userId={userId} customer={customer} />,
  };

  const fetchPermision = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/sections/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalPermission = response.data.data;
        const updatedTabs = tabs.map((tab) => {
          const tabName = tab.key;
          const permission = finalPermission[0][tabName];
          return { ...tab, permission };
        });
        settabs(updatedTabs);
      });
  };

  useEffect(() => {
    fetchPermision();
    if (dashboardRef.current) {
      const scrollOptions = {
        top: 0,
        left: 0,
        behavior: 'smooth',
      };
      dashboardRef.current.scrollTo(scrollOptions);
    }
  }, [userId]);

  return (
    <div>
      <PagesHeader
        name={'Customer Dashboard'}
        array={<ArrayBack className="cursor-pointer" />}
        secondHeading={'Customer Dashboard'}
        thirdHeading={'Customer Dashboard'}
      />
      <div className="flex 2xl:py-[20px] xl:py-[10px] lg:py-[14px] w-full">
        {tabs.map((tab, index) => (
          <>
            {tab.permission ? (
              <div
                key={index}
                className={`px-[20px] cursor-pointer border-b border-b-[#D6A725] ${
                  CurrentTab === tab.key ? 'text-[#D6A725] border-b-[5px]' : 'text-[#878787]'
                }`}
                onClick={() => {
                  setCurrentTab(tab.key);
                }}
              >
                {tab.label}
              </div>
            ) : null}
          </>
        ))}
        <div className="flex flex-grow border-b-[#D6A725] border-b"></div>
      </div>
      <div className="dashboard_height" ref={dashboardRef}>
        {tabComponents[CurrentTab]}
      </div>
    </div>
  );
}
