import React, { useMemo, useState, useEffect } from 'react';
import pdfjsVersion from 'pdfjs-dist/package.json';
import { ReactComponent as Blood } from '../../assets/images/blood.svg';
import { ReactComponent as Download } from '../../assets/images/download.svg';
import { Worker, Viewer, ViewMode, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { selectionModePlugin, SelectionMode } from '@react-pdf-viewer/selection-mode';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { HashLoader } from 'react-spinners';
import axios from 'axios';

export default function BloodReport({ headers, userId }) {
  const [Reports, setReports] = useState([]);
  const [Overlay, setOverlay] = useState(false);
  const [Active, setActive] = useState();
  const [FinalReport, setFinalReport] = useState();
  const [PdfToView, setPdfToView] = useState('');

  const zoomPluginInstance = zoomPlugin({
    enableShortcuts: true,
  });
  const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;
  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: SelectionMode.Hand,
  });
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  const getList = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/blood_report/${userId}`, {
        headers,
      })
      .then((response) => {
        const final = response.data.data;
        const firstOne = final[0];
        // setActive(firstOne.id);
        // getAnalysis(firstOne.id, firstOne.is_past);
        // setOverlay(true);
        setReports(final);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAnalysis = (id, past) => {
    const body = {
      report_id: id,
      is_past: past,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/blood_report/analysis`,
        body,
        {
          headers,
        }
      )
      .then((response) => {
        const final = response.data.data[0];
        setFinalReport(final.analysis_data);
        setOverlay(false);
      })
      .catch((error) => {
        setOverlay(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="content-rda">
      {Overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]"></div>
        </div>
      )}
      <div className="flex h-full">
        <div className="flex w-full">
          <div className="w-[70%]">
            <div className="pt-[20px] px-7 mb-1">
              <span className="text-[#D2A72B] border-b-[5px] border-b-[#D6A725] text-[16px]">
                Blood Report Analysis
              </span>
              <div className="flex flex-grow border-b-[#D2A72B] border-b"></div>
            </div>
            <div className="p-7 reports overflow-auto text-[16px] leading-7">
              {FinalReport ? (
                <div dangerouslySetInnerHTML={{ __html: FinalReport }} />
              ) : (
                <div className="text-center">
                  Click on Generate Analysis on any one of the Report
                </div>
              )}

              {PdfToView && (
                <div
                  className="dnas-report2"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                    <ZoomIn>
                      {(props) => (
                        <button
                          type="button"
                          className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                          onClick={props.onClick}
                        >
                          Zoom In
                        </button>
                      )}
                    </ZoomIn>
                    <ZoomOut>
                      {(props) => (
                        <button
                          type="button"
                          className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                          onClick={props.onClick}
                        >
                          Zoom Out
                        </button>
                      )}
                    </ZoomOut>
                    <Zoom>
                      {(props) => (
                        <button
                          type="button"
                          className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                          onClick={() => props.onZoom(SpecialZoomLevel.PageFit)}
                        >
                          Reset Zoom
                        </button>
                      )}
                    </Zoom>
                  </div>
                  <div className="dnas-report1" style={{ display: 'flex' }}>
                    <div
                      style={{
                        width: '200px',
                        borderRight: '1px solid #ddd',
                        overflowY: 'auto',
                      }}
                    >
                      <Thumbnails />
                    </div>

                    <div
                      style={{ flex: 1, overflowY: 'auto' }}
                      // onWheel={handleScrollZoom}
                    >
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion.version}/build/pdf.worker.min.js`}
                      >
                        <Viewer
                          fileUrl={PdfToView}
                          // fileUrl="https://prod-client-api-bucket.s3.ap-south-1.amazonaws.com/unlockFit_1683013538590.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYEKI5ZAKLQDRD23X/20250110/ap-south-1/s3/aws4_request&X-Amz-Date=20250110T085434Z&X-Amz-Expires=100000&X-Amz-Signature=4f41bf9e27be891cfabb2174471531651c5960fea65114215193d8de6090c7a2&X-Amz-SignedHeaders=host&response-content-disposition=inline&response-content-type=application/pdf"
                          viewMode={ViewMode.SinglePage}
                          defaultScale={SpecialZoomLevel.PageFit}
                          plugins={[
                            thumbnailPluginInstance,
                            zoomPluginInstance,
                            selectionModePluginInstance,
                          ]}
                        />
                      </Worker>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-[30%]">
            <div
              className="my-7 mr-5 reports rounded-[20px]"
              style={{
                boxShadow: '4px 4px 8px 0px #0000001F',
              }}
            >
              <div
                className="font-medium text-xl py-2 px-4"
                style={{
                  boxShadow: '4px 4px 8px 0px #00000008',
                }}
              >
                Blood Reports
              </div>
              <div>
                {Reports.map((datas) => (
                  <div
                    className={`flex p-2 justify-between items-center w-full border-b-[0.5px] border-b-[#F1F1F1] ${
                      Active === datas.id ? 'bg-[#ffe4a0]' : ''
                    }`}
                  >
                    <div className={`flex items-center text-[#3D3D3D] text-[12px]`}>
                      <Blood className="mr-3" />
                      {datas.name}
                    </div>
                    <div className="flex gap-3 justify-center items-center">
                      <button
                        onClick={() => {
                          setOverlay(true);
                          setActive(datas.id);
                          getAnalysis(datas.id, datas.is_past);
                          setPdfToView('');
                        }}
                        type="button"
                        className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                      >
                        Generate Analysis
                      </button>
                      <Download
                        className="cursor-pointer"
                        onClick={() => {
                          setActive(datas.id);
                          setPdfToView(datas.pdf_report);
                          // window.open(datas.pdf_report, '_blank');
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
