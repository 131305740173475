import React, { useEffect, useState } from 'react';
import { ReactComponent as Nutrition } from '../../assets/images/nutrition.svg';
import { ReactComponent as ProgressTrackerIcon } from '../../assets/images/ProgressTrackerIcon.svg';
import { ReactComponent as AdminPanel } from '../../assets/images/admin_panel.svg';
import { ReactComponent as RoleManagement } from '../../assets/images/role_manage.svg';
import { ReactComponent as Report } from '../../assets/images/Reporting.svg';
import { ReactComponent as CustomerIcon } from '../../assets/images/customer.svg';
import { ReactComponent as IngredientIcon } from '../../assets/images/ingredient_logo.svg';
import { ReactComponent as MasterIcon } from '../../assets/images/master_icon.svg';
import { ReactComponent as TempalteIcon } from '../../assets/images/template.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSearchQuery, updateQuery } from '../../Store/ClientTableSlice';
import logo from '../../assets/images/logo.svg';
import { WarningMessage } from '../Authentication/WarningMessage';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';

function Sidebar({ userData, token }) {
  let location = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  // const { ChevronRightIcon } = require('@radix-ui/react-icons');

  const [IsOpen, setIsOpen] = useState(false);
  const [ActiveTab, setActiveTab] = useState('');
  const [OpenWarning, setOpenWarning] = useState(false);
  const [AuthToken, setAuthToken] = useState('');
  const [type, setType] = useState('');
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const customer = permission?.role_permissions?.find(
    (permission) => permission.name === 'Customer Dashboard'
  );
  const verification = permission?.role_permissions?.find(
    (permission) => permission.name === 'Verification Panel'
  );
  const progress = permission?.role_permissions?.find(
    (permission) => permission.name === 'Progress Tracker'
  );
  const roles = permission?.role_permissions?.find(
    (permission) => permission.name === 'Role Management'
  );
  const nutritionIngredient = permission?.role_permissions?.find(
    (permission) => permission.name === 'Ingredients Panel'
  );
  const nutritionRecipe = permission?.role_permissions?.find(
    (permission) => permission.name === 'Recipe Panel'
  );
  const masterMeasurement = permission?.role_permissions?.find(
    (permission) => permission.name === 'Master Measurement Panel'
  );
  const repotsXOXO = permission?.role_permissions?.find(
    (permission) => permission.name === 'Reports'
  );
  const templates = permission?.role_permissions?.find(
    (permission) => permission.name === 'Diet Plan Templates'
  );
  const permissionNames = [
    'Organizations',
    'Fitness Videos',
    'Upload Reports',
    'DNA Report',
    'Blood Reports',
    'User Video Assessment',
    'Coupon',
    'Notifications',
    'Products',
    'Consultation',
    'Ingredients Panel',
    'Recipe Panel',
    'Add Barcode',
    'App Access',
  ];

  const hasViewPermission = permissionNames.reduce(
    (result, name) =>
      result || permission?.role_permissions?.find((p) => p.name === name)?.can_view,
    false
  );

  const Menus = [
    customer?.can_view
      ? {
          id: 'customerDashboard',
          icon: <CustomerIcon className="w-5 h-5" />,
          title: 'Customer Dashboard',
          path: '/customer/dashboard',
        }
      : null,
    verification?.can_view
      ? {
          id: 'verificationPanel',
          icon: <Nutrition className="w-5 h-5" />,
          title: 'Verification Panel',
          path: '/plan/verification',
        }
      : null,
    progress?.can_view
      ? {
          id: 'progrssTracker',
          icon: <ProgressTrackerIcon className="w-5 h-5" />,
          title: 'Progress Tracker',
          path: '/progress/tracker',
        }
      : null,
    hasViewPermission
      ? {
          id: 'adminPanel',
          icon: <AdminPanel className="w-5 h-5" />,
          title: 'Legacy Dashboard',
          path: `${process.env.REACT_APP_LOGIN_PAGE}?user=${token}`,
        }
      : null,
    roles?.can_view
      ? {
          id: 'roleManage',
          icon: <RoleManagement className="w-5 h-5" />,
          title: 'Role Management',
          path: `/role_management`,
        }
      : null,
    nutritionIngredient?.can_view || nutritionRecipe?.can_view
      ? {
          id: 'tracker',
          icon: <IngredientIcon className="w-6 h-6" />,
          title: 'Nutrition Panel',
          path: '/nutrition',
        }
      : null,
    masterMeasurement?.can_view
      ? {
          id: 'master',
          icon: <MasterIcon className="w-6 h-6" />,
          title: 'Master Panel',
          path: '/master',
        }
      : null,
    repotsXOXO?.can_view
      ? {
          id: 'report',
          icon: <Report className="w-6 h-6" />,
          title: 'Reports',
          path: '/reports',
        }
      : null,
    templates?.can_view
      ? {
          id: 'tmeplate',
          icon: <TempalteIcon className="w-6 h-6" />,
          title: 'Template',
          path: '/template',
        }
      : null,
  ].filter((item) => item !== null);

  const clicked = location.pathname;

  useEffect(() => {
    const isValidPath = Menus.some((menu) => menu.path === location.pathname);

    if (isValidPath) {
      setActiveTab(location.pathname);
    }
  }, [location, Menus]);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/user/logout`, '', {
        headers: headers,
      })
      .then((response) => {
        localStorage.clear();
        navigate('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={`w-[85px] h-screen  relative duration-300 bg-white shadow-xl z-[2]`}>
      <div className="w-30 h-30 flex justify-center items-center py-[20px]">
        <img
          src={logo}
          className={`2xl:h-[64px] 2xl:w-[64px] xl:h-[50px] xl:w-[50px] lg:h-[40px] lg:w-[40px]`}
          alt="Logo"
        />{' '}
        {/* <div className="2xl:text-[32px] xl:text-[25px] lg:text-[20px] ml-[10px]">unlock.fit</div> */}
      </div>
      <div className="menus mt-[37px]">
        {Menus.map((pages) => (
          <Tooltip title={<div className="text-[15px]">{pages.title}</div>} placement="right" arrow>
            <Link
              to={pages.path}
              target={pages.id === 'adminPanel' ? '_blank' : '_self'}
              onClick={() => {
                localStorage.removeItem('setPage');
                localStorage.removeItem('setNameForSearch');
                localStorage.removeItem('scrollToId');
                setActiveTab(pages.id === 'adminPanel' ? clicked : pages.path);
                dispatch(setSearchQuery({ searchQuery: '', isNewSearch: true }));
                dispatch(updateQuery(''));
              }}
              className="flex mb-[10px] items-center w-full"
              style={{
                borderRight:
                  ActiveTab === pages.path ? '2px solid #D2A72B' : '2px solid transparent',
                transform: ActiveTab === pages.path ? 'translateY(-3px)' : 'translateY(0)',
                transition: 'transform 0.3s ease-in-out, border-right 0.3s ease-in-out',
              }}
            >
              <div
                className={`flex justify-center items-center w-full h-10 ${
                  ActiveTab === pages.path ? 'bg-[#FFFFFF] transition-all duration-300' : ''
                } rounded-[9px] mx-[10px]`}
                style={{
                  boxShadow: ActiveTab === pages.path ? '-1px 1px 5px 0px rgba(0, 0, 0, 0.20)' : '',
                }}
              >
                {pages.icon}
              </div>
              {/* <div
              className={`${
                ActiveTab === pages.path ? 'text-[#D6A725]' : 'text-[#B7B7B7]'
              } 2xl:text-[14px] xl:text-[12px] lg:text-[10px] w-[80%]`}
            >
              {pages.title}
            </div> */}
            </Link>
          </Tooltip>
        ))}
      </div>
      <div className="userDetails absolute bottom-0 p-[10px] w-full 2xl:text-[14px] xl:text-[12px] lg:text-[10px]">
        <div
          className="flex items-center justify-center cursor-pointer w-full"
          onClick={() => {
            setIsOpen(!IsOpen);
          }}
        >
          <Tooltip
            title={
              <>
                <div className="text-[15px]">{userData.name}</div>
                <div className="text-[12px]">{userData.role_name}</div>
              </>
            }
            placement="right"
            arrow
          >
            <div
              className={`2xl:h-[60px] 2xl:w-[60px]  xl:h-[53.33px] xl:w-[53.33px] lg:h-[44.44px] lg:w-[44.44px] 
          flex justify-center items-center rounded-full border-brand-color bg-[#D9D9D9] border-4`}
              style={{ backgroundColor: '#BD9528', color: '#FFF' }}
            >
              <span className="inline-block 2xl:text-[25px] xl:text-1xl lg:text-xl md:text-lg">
                {userData?.name?.charAt(0)?.toUpperCase()}
              </span>
            </div>
          </Tooltip>
          {/* <div className="flex items-center ml-2 justify-between">
            <div>{userData.name}</div>{' '}
            <div
              className={`transition-transform duration-300 transform ${IsOpen ? 'rotate-90' : ''}`}
            >
              <ChevronRightIcon />
            </div>
          </div> */}
        </div>
        <div className="w-full">
          {/* <div className="2xl:w-[60px] xl:w-[53.33px] lg:w-[44.44px]" /> */}
          <div
            className={`transition-max-height text-[#D2A62C] mt-2 text-[10px] duration-300 overflow-hidden max-h-0 ${
              IsOpen ? 'max-h-[200px]' : ''
            }`}
          >
            <div
              className="cursor-pointer transition-colors hover:bg-[#F3EBD6] rounded-[8px] py-1 px-2"
              onClick={() => {
                setType('reset');
                setAuthToken(token);
                setOpenWarning(true);
              }}
            >
              Change Password
            </div>
            <div
              className="cursor-pointer transition-colors hover:bg-[#F3EBD6] rounded-[8px] py-1 px-2"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      <WarningMessage
        setAuthToken={setAuthToken}
        AuthToken={AuthToken}
        OpenWarning={OpenWarning}
        setOpenWarning={setOpenWarning}
        type={type}
        change="change"
      />
    </div>
  );
}

export default Sidebar;
