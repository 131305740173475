import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as Tag } from '../../../assets/images/tagged.svg';
import { ReactComponent as UnTag } from '../../../assets/images/untagged.svg';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';

export default function NPSScore({ headers, userId }) {
  const initialRows = Array(15).fill({
    name: -1,
    score: -1,
    feedback: -1,
  });
  const [Payment, setPayment] = useState({
    columns: [
      { id: 0, column_name: 'Customer Name', map_column: 'name', type: 'string' },
      { id: 1, column_name: `Given Score`, map_column: 'score', type: 'string' },
      { id: 1, column_name: `Event Name`, map_column: 'event_name', type: 'string' },
      { id: 1, column_name: `Saved At`, map_column: 'created_at', type: 'string' },
      { id: 1, column_name: 'Feedback', map_column: 'feedback', type: 'string' },
    ],
    rows: initialRows,
  });
  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const { columns } = Payment;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Payment]);

  const table = useReactTable({
    data: Payment.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchPayment = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/nps_score/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        setPayment({ ...Payment, rows: finalData });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return (
    <div className="w-full">
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            Net Promoter Score (NPS) of Customers
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      ${
                        header.column.id === 'name'
                          ? 'w-[15%]'
                          : header.column.id === 'score'
                          ? 'w-[12%]'
                          : header.column.id === 'event_name'
                          ? 'w-[12%]'
                          : header.column.id === 'created_at'
                          ? 'w-[15%]'
                          : header.column.id === 'feedback'
                          ? 'w-[58%]'
                          : ''
                      }
                      }`}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 sale-table">
            {table.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    ${
                      cell.column.id === 'name'
                        ? 'w-[15%]'
                        : cell.column.id === 'score'
                        ? 'w-[12%]'
                        : cell.column.id === 'event_name'
                        ? 'w-[12%]'
                        : cell.column.id === 'created_at'
                        ? 'w-[15%]'
                        : cell.column.id === 'feedback'
                        ? 'w-[58%]'
                        : ''
                    }
                    `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
