import React, { useEffect, useRef, useState } from 'react';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as ArrayBack } from '../../assets/images/arrow_back.svg';
import { ReactComponent as Add } from '../../assets/images/add.svg';
import classNames from 'classnames';
import EnergyDistribution from '../layout/EnergyDistribution';
import { getToken } from '../../Utils/Authenticate.js';
import TempletDisclose from '../shared/TempletDisclose';
import DialogModel from '../layout/ApporeModal';
import Button from '../common/Button';
import SaveTempletModal from '../layout/SaveTempletModal';
import axios from 'axios';
import ViewNotes from '../layout/ViewNotes';
import { useParams } from 'react-router-dom';
import { ReactComponent as Apply } from '../../assets/images/check.svg';
import { ReactComponent as Cancel } from '../../assets/images/cancle.svg';
import { ClipLoader, HashLoader } from 'react-spinners';
import { ReactComponent as NoData } from '../../assets/images/noDataWithText.svg';
import { toast } from 'react-toastify';
import moment from 'moment';
import UserDetailsPanel from '../shared/UserDetailsPanel';
import Skeleton from 'react-loading-skeleton';
import MealNoteModal from '../layout/MealNoteModal';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { EatTime } from '../layout/EatTime.js';

const CreateDietSchedule = () => {
  const [EditMeal, setEditMeal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [temopen, setSavOpen] = useState(false);
  const [viewnote, setViewNote] = useState(false);
  const [refreshdata, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  let { userId, planId } = useParams();
  const [userdata, setUserData] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const [openmeal, setOpenMeal] = useState(false);
  const [addNewMeal, setAddNewMeal] = useState(false);
  const [lastMealRef, setLastMealRef] = useState(useRef(null));
  const [templates, setTemplates] = useState([]);
  const [UserDetail, setUserDetail] = useState([]);
  const [selectedTemp, setSelectedTemp] = useState();
  const [tempQuery, setTempQuery] = useState('');
  const [refreshTemp, setRefreshTemp] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);
  const [sectionAdded, setSectionAdded] = useState(false);
  const [isOpenForAdd, setIsOpenForAdd] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [edit, setEdit] = useState(true);
  const userPanelRef = useRef(null);
  const headingRef = useRef(null);
  const editLabelRef = useRef(null);

  const [overlay, setOverlay] = useState(false);
  const [newLabel, setNewLabel] = useState('');
  useEffect(() => {
    if (planDetails) {
      setNewLabel(planDetails.label);
    }
  }, [planDetails]);

  useEffect(() => {
    if (!edit) {
      editLabelRef.current.value = newLabel;
    }
  }, [edit]);

  const handleResize = () => {
    let userPanelHeight = 0;
    if (userPanelRef.current) {
      userPanelHeight = userPanelRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--plan-diet-user-height', userPanelHeight);
    }
    let planHeadingHeight;
    if (headingRef.current) {
      planHeadingHeight = headingRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--plan-diet-heading-height', planHeadingHeight);
    }
  };
  const handleRightIconClick = () => {
    setOverlay(true);
    let token = getToken();

    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/edit_label/${planId}`,
      method: 'patch',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: editLabelRef.current.value,
      },
    };
    axios
      .request(config)
      .then(
        (data) => {
          setNewLabel(editLabelRef.current.value);
          setEdit(true);
          toast.success(data.data.message);
          setOverlay(false);
        },
        (err) => {
          toast.error(err.response.data.message);
          setOverlay(false);
        }
      )
      .catch((err) => {
        toast.error(err);
        setOverlay(false);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(handleResize, 30);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (addNewMeal) {
      lastMealRef.current?.scrollIntoView();
    }
  }, [addNewMeal, lastMealRef]);

  const [CallApi, setCallApi] = useState(0);

  useEffect(() => {
    if (planId) {
      setOverlay(true);
      // setLoading(true);
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/user_plan`,
        method: 'POST',
        data: {
          plan_id: planId,
          user_id: userId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
      };
      axios
        .request(config)
        .then(({ data }) => {
          if (data?.result === 'success') {
            setPlanDetails(data.data[0].plan_details);
            setNewLabel(data.data[0].plan_details.label);
            let diet_plans = data.data[0].plan_details.recipes.map((ele) => ({
              ...ele,
            }));
            diet_plans.sort((a, b) => {
              let timeA = new Date('1970/01/01 ' + a.meal_time);
              let timeB = new Date('1970/01/01 ' + b.meal_time);
              return timeA - timeB;
            });
            setUserData(diet_plans);
            setEnableApprove(data.data[0].plan_details?.is_approved);
            if (diet_plans.success == false) {
              setUserData([]);
            }
            setOverlay(false);
            // setLoading(false);
          }
        })
        .catch((err) => {
          setOverlay(false);
          // setLoading(false);
          const error = err.response.data;
          toast.error(error.message);
        });
    }
  }, [planId, sectionAdded, userId, CallApi]);

  function OpenViewNote() {
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/notes?user_id=${userId}&plan_id=${planId}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then(
        ({ data }) => {
          setViewNote(true);
        },
        (err) => {
          opneMealNote();
        }
      )
      .catch((err) => {
        toast.error(err);
      });
  }
  function CloseViewNote() {
    setViewNote(false);
  }

  function OpenSaveTem() {
    setSectionAdded((old) => !old);
    setSavOpen(true);
  }
  function CloseSavTem() {
    setSavOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function opneMealNote() {
    setOpenMeal(true);
  }

  function CloseNoteModal() {
    setOpenMeal(false);
  }

  useEffect(() => {
    const controller = new AbortController();
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan?search=${tempQuery}`,
      method: 'get',
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (tempQuery) {
      axios
        .request(config)
        .then(({ data }) => {
          setTemplates(data.data);
          //data.data.length > 0 ? setSelectedTemp(data.data[0]) : "";
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    return () => {
      controller.abort();
    };
  }, [tempQuery, refreshTemp]);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      const params = userId.split('/');
      const _userId = params[params.length - 1];
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/plan_details?user_id=${_userId}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then(({ data }) => {
          setLoading(false);
          let diet_plans = data.data[0];
          setUserDetail(diet_plans.user);
        })
        .catch((err) => {
          setLoading(false);
          toast(err);
        });
    }
  }, [userId]);

  //----------------------------- Use Effect For Importing templates ----------------------

  useEffect(() => {
    if (selectedTemp) {
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/add_to_plan`,
        method: 'POST',
        data: {
          template_id: selectedTemp?.id,
          plan_id: planId,
        },

        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then(({ data }) => {
          // console.log("importTempData", data.data[0])
          // setRefreshData(!refreshdata);
          // setSelectedTemp('');
          // // setImportTemp(!importTemp)
          // setPlanDetails(data.data[0].plan_details);
          // console.log('dt', data.data[0]);
          let diet_plans = data.data[0].recipes;
          // console.log('dp', diet_plans);
          diet_plans.sort((a, b) => {
            let timeA = new Date('1970/01/01 ' + a.meal_time);
            let timeB = new Date('1970/01/01 ' + b.meal_time);
            return timeA - timeB;
          });
          setEnableApprove(data.data[0]?.is_approved);
          setUserData(diet_plans);
          setRefreshData((old) => !old);
          // toast.success(data.message);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [selectedTemp]);

  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    if (resizing) {
      const handleResize = (event) => {
        const layout = document.querySelector('.resizable-layout');
        const mouseX = event.clientX;
        const layoutRect = layout.getBoundingClientRect();
        const leftColumn = layout.querySelector('.resizable-column:first-child');
        const rightColumn = layout.querySelector('.resizable-column:last-child');
        const verticalHandle = layout.querySelector('.vertical');

        if (!verticalHandle) return;

        const totalWidth = layoutRect.width;
        const dividerWidth = verticalHandle.getBoundingClientRect().width;
        const leftWidth = mouseX - layoutRect.left - dividerWidth / 2;
        const rightWidth = totalWidth - leftWidth - dividerWidth;

        leftColumn.style.width = `${leftWidth}px`;
        rightColumn.style.width = `${rightWidth}px`;

        if (rightWidth > 500) {
          setIsFull(true);
        } else {
          setIsFull(false);
        }
      };

      const handleResizeEnd = () => {
        setResizing(false);
      };

      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);

      return () => {
        document.removeEventListener('mousemove', handleResize);
        document.removeEventListener('mouseup', handleResizeEnd);
      };
    }
  }, [resizing]);

  const handleResizeStart = () => {
    setResizing(true);
  };

  return (
    <>
      {overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
        </div>
      )}
      {/* <PagesHeader name="Create Diet Plan" array={<ArrayBack className="cursor-pointer" />} /> */}
      <UserDetailsPanel
        name={'Update Diet Plan'}
        secondHeading={'Diet Plan'}
        thirdHeading={'Update Diet Plan'}
        userData={UserDetail}
        setUserData={setUserDetail}
      />

      <div>
        <div className=" rounded-medium bg-body-color-3 shadow-card-shadow">
          {/* // h-[calc(100vh_-_335px)] */}
          <div
            ref={headingRef}
            className="flex justify-between 2xl:px-5 lg:pt-[13.3px] lg:pl-[14.81px] lg:pr-[15.19px] md:py-[13px] md:px-3 border-b second-div"
          >
            <div className="flex items-center 2xl:gap-9 xl:gap-5 lg:gap-[14px]">
              <div className="flex flex-col">
                <div className="flex items-center gap-3">
                  <div className="border-b border-chalice md:w-[180px]">
                    {loading ? (
                      <Skeleton width="150px" baseColor="#e9d39633"></Skeleton>
                    ) : edit ? (
                      <span className=" 2xl:text-xl 2xl:leading-[30px] xl:text-[17.78px] lg:text-[14.81px] font-normal text-black  ">
                        {newLabel}
                      </span>
                    ) : (
                      <input
                        type="text"
                        // value={newLabel}
                        // onChange={(e) => {
                        //   setNewLabel(e.target.value);
                        // }}
                        ref={editLabelRef}
                        placeholder="Enter Value"
                        className="outline-none md:w-[124px]"
                      />
                    )}
                  </div>
                  <div className="cursor-pointer flex items-center md:gap-[10px]">
                    {edit && (
                      <div
                        onClick={() => {
                          setEdit(!edit);
                        }}
                      >
                        <Edit />
                      </div>
                    )}
                    {!edit && (
                      <div className="flex md:gap-[10px]">
                        <Apply onClick={handleRightIconClick} />
                        <Cancel onClick={() => setEdit(!edit)} />
                      </div>
                    )}
                  </div>
                </div>

                <span className="text-body-color-2 font-Regular md:pt-1  2xl:text-xs xl:text-[10.67px] lg:text-[8.89px] ">
                  {loading ? (
                    <Skeleton width="150px" baseColor="#e9d39633"></Skeleton>
                  ) : (
                    <>
                      {moment(planDetails?.date, 'DD MM YYYY').format('DD MMM YYYY')}, Day{' '}
                      {planDetails.day_number},&nbsp;{planDetails?.day}
                    </>
                  )}
                </span>
              </div>
              <div className="flex items-center">
                <Button
                  className={classNames(
                    'bg-brand-color  text-white flex items-center 2xl:ml-5  2xl:px-5 2xl:py-2 xl:px-[15px] lg:px-[10px] lg:py-[5.93px] md:py-[4px] 2xl:rounded-[10px] lg:rounded-lg md:rounded-md border-rad-9px  gap-[10px] text-base font-Regular'
                  )}
                  onClick={() => {
                    setEditMeal(false);
                    // console.log('user', userdata[userdata.length - 1]);
                    // if (
                    //   userdata[userdata.length - 1]?.editable &&
                    //   userdata.at(-1)?.suggested_recipes?.length === 0
                    // ) {
                    //   toast.warning('Please Fill Up The Last Meal Section');
                    //   // console.log("ALert", userdata)
                    //   return;
                    // } else {
                    // }
                    // setUserData((old) => [
                    //   ...old,
                    //   { editable: true, suggested_recipes: [], nutrition_profile: [] },
                    // ]);
                    setIsOpenForAdd(true);
                  }}
                  // onClick={() => {
                  //   // setIsEditMode(true);
                  //   setOpenTimeModal(true);
                  // }}
                >
                  <span>
                    <Add className=" 2xl:h-6 2xl:w-6 lg:w-[16px] lg:h-[16px] md:w-[14px] md:h-[14px]" />
                  </span>
                  Add New Meal
                </Button>
              </div>
              <div className={` border-b ${classNames}`}>
                <TempletDisclose
                  className={'2xl:text-base xl:text-[14.22px] lg:text-[11.85px]'}
                  heading={'Import Template'}
                  data={templates}
                  selected={selectedTemp}
                  setSelected={setSelectedTemp}
                  query={tempQuery}
                  setQuery={setTempQuery}
                  type="plan"
                  setRefreshTemp={setRefreshTemp}
                  setTemplates={setTemplates}
                />
              </div>
              <div className="text-shades-2   text-base">
                <div onClick={() => OpenSaveTem()} className="cursor-pointer text-base">
                  Save As Template
                </div>
              </div>
              <div className="text-shades-2    text-base">
                <span onClick={() => OpenViewNote()} className="cursor-pointer">
                  View Notes
                </span>
              </div>
            </div>
            {/* <div className="flex items-center">
              <button
                className={`${enableApprove === true ? 'cursor-not-allowed' : 'cursor-pointer'}
                  bg-brand-color text-white flex 2xl:px-10 2xl:py-2  lg:px-[30px] md:px-[25px] lg:py-[6px] md:py-[4px] 2xl:rounded-[10px]  lg:rounded-lg md:rounded-md text-base font-Regular `}
                onClick={() => openModal()}
                disabled={enableApprove}
              >
                Approve
              </button>
            </div> */}
          </div>
          <div className={`flex resizable-layout lg:gap-[27px] md:gap-[22px] content-diet-table`}>
            <div
              style={{ width: '60%', minWidth: 600 }}
              className={`resizable-column custom-scrollbar h-100 overflow-auto border-r-2`}
            >
              {loading ? (
                <>
                  <div className="ml-9 mt-9">
                    <Skeleton width="140px" baseColor="#e9d39633"></Skeleton>
                    <Skeleton width="180px" baseColor="#e9d39633"></Skeleton>
                    <Skeleton width="200px" baseColor="#e9d39633"></Skeleton>
                  </div>
                  <div className="ml-9 mt-9">
                    <Skeleton width="140px" baseColor="#e9d39633"></Skeleton>
                    <Skeleton width="180px" baseColor="#e9d39633"></Skeleton>
                    <Skeleton width="200px" baseColor="#e9d39633"></Skeleton>
                  </div>
                </>
              ) : (
                <EatTime
                  setOverlay={setOverlay}
                  userdata={userdata}
                  setCallApi={setCallApi}
                  CallApi={CallApi}
                  planDetails={planDetails}
                  setEditMeal={setEditMeal}
                  EditMeal={EditMeal}
                  planId={planDetails?.plan_id}
                  isOpen={isOpenForAdd}
                  setIsOpen={setIsOpenForAdd}
                  setUserData={setUserData}
                  refreshdata={refreshdata}
                  setRefreshData={setRefreshData}
                />
              )}
            </div>
            <div
              className={`vertical ${resizing ? 'resizing' : ''}`}
              onMouseDown={handleResizeStart}
            ></div>
            <div
              style={{ width: '40%', minWidth: 400 }}
              className={`resizable-column overflow-auto border-l border-r`}
            >
              <EnergyDistribution
                userdata={userdata}
                isFull={isFull}
                setIsFull={setIsFull}
                userId={userId}
                planId={planId}
                setRefreshData={setRefreshData}
                refreshdata={refreshdata}
                setSelectedTemp={setSelectedTemp}
              />
            </div>
          </div>
        </div>
      </div>
      <DialogModel
        openis={isOpen}
        closeis={closeModal}
        setCallApi={setCallApi}
        CallApi={CallApi}
        TimeDiet={moment(planDetails.date, 'DD MM YYYY').format('DD MMM YYYY')}
      />
      <SaveTempletModal
        isOpen={temopen}
        closeModal={CloseSavTem}
        data={userdata}
        planId={planId}
        type="Plan Wise"
      />

      <ViewNotes
        isOpen={viewnote}
        closeModal={CloseViewNote}
        userId={userId}
        planId={planId}
        type={'Meal Note Wise'}
        setOpenNote={setViewNote}
      />

      <MealNoteModal
        Openis={openmeal}
        Closeis={CloseNoteModal}
        type={'Meal Note Wise'}
        // setRefresh={setrefresh}
        setOpenNote={setViewNote}
      />
    </>
  );
};

export default CreateDietSchedule;
