import { useEffect, Fragment, useRef, useState } from 'react';
import axios from 'axios';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { ReactComponent as Expand } from '../../../assets/images/expand_more_dark.svg';
import { ReactComponent as ArrayBack } from '../../../assets/images/arrow_back.svg';
import { ReactComponent as Edit } from '../../../assets/images/smalledit.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete_icon.svg';
import { ReactComponent as Apply } from '../../../assets/images/check.svg';
import { ReactComponent as Cancel } from '../../../assets/images/cancle.svg';
import { ReactComponent as NoData } from '../../../assets/images/noDataWithText.svg';
import { ReactComponent as Plus } from '../../../assets/images/countadd.svg';
import { ReactComponent as Incres } from '../../../assets/images/remove.svg';
import { Listbox, Transition } from '@headlessui/react';
import { HashLoader } from 'react-spinners';
import Button from '../../common/Button';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getToken } from '../../../Utils/Authenticate';
import { toast } from 'react-toastify';
import PagesHeader from '../../shared/PagesHeader';
import DietPlaneTable from '../DietPlaneTable';
import EnergyDistribution from './EnergyDistributionTemplate';
import InpDropDown from './InpDropDown';
import UpdateModal from '../UpdateModal';
import RacipeForm from '../RacipeForm';

export default function TemplateEdit() {
  let token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let { templateId, planId } = useParams();

  const editLabelRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenForAdd, setIsOpenForAdd] = useState(false);
  const [EditMeal, setEditMeal] = useState(false);
  const [openRecipeModal, setOpenRecipeModal] = useState(false);
  const [inputMealVal, setInputMealVal] = useState('');
  const [inputTimeVal, setInputTimeVal] = useState('');
  const [data, setData] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedIng, setSelectedIng] = useState('');
  const [fetched, setFetched] = useState(['']);
  const [fetchedIng, setFetchedIng] = useState(['']);
  const [query, setQuery] = useState('');
  const [QueryForInp, setQueryForInp] = useState('');
  const [queryIng, setQueryIng] = useState('');
  const [RefreshData, setRefreshData] = useState(false);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [IsEditing, setIsEditing] = useState(false);
  const [selectedMesurement, setSelectedMesurement] = useState();
  const [NewLabel, setNewLabel] = useState('');
  const [meal_id, setmeal_id] = useState('');
  const [CallApi, setCallApi] = useState(0);
  const [ingredientData, setIngredientData] = useState([]);
  const [nutritionData, setNutritionData] = useState([]);
  const [recipeName, setRecipeName] = useState('');
  const [TemplateEditable, setTemplateEditable] = useState({
    plan_id: '',
    recipes: [],
  });

  function closeRecipeModal() {
    setOpenRecipeModal(!openRecipeModal);
  }

  useEffect(() => {
    if (selected && selected.meal_time && selected.meal_name) {
      setInputTimeVal(selected.meal_time);
      setInputMealVal(selected.meal_name);
    }
  }, [selected]);

  useEffect(() => {
    if (IsEditing) {
      editLabelRef.current.value = NewLabel;
    }
  }, [IsEditing]);

  const handleRightIconClick = () => {};

  const getTemplate = () => {
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/view/plan`,
        {
          template_id: templateId,
          plan_id: planId,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data[0];
        setLoader(false);
        setNewLabel(finalData.plan_details.label);
        setTemplateEditable({
          plan_id: finalData.plan_details.plan_id,
          recipes: finalData.plan_details.recipes,
        });
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  const deleteMeal = (mealId) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/remove/plan/item/bulk?meal_type_id=${mealId}&plan_id=${planId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        getTemplate();
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  const deleteARecipe = (recipe, meal) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/remove/plan/item?meal_type_id=${meal}&recipe_id=${recipe}&plan_id=${planId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const finalData = response.data.data[0];
        getTemplate();
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
      });
  };

  const updateName = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/edit_label/${planId}`,
        {
          template_id: templateId,
          name: NewLabel,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setIsEditing(false);
      })
      .catch((error) => {
        setLoader(false);
        const finalError = error.response.data;
        toast.error(finalError.message);
      });
  };

  useEffect(() => {
    getTemplate();
  }, [templateId, planId]);

  const formattedNutrients = (nutritionProfile) => {
    if (
      !nutritionProfile ||
      nutritionProfile.length === 0 ||
      nutritionProfile.every((nutrient) => !nutrient.nutritent_name)
    ) {
      return (
        <>
          <span>Calories- 0kcal, Carbs - 0g, Proteins- 0g, Fat- 0g</span>
        </>
      );
    } else {
      return nutritionProfile.map((nutrient, index) => {
        const { nutritent_name, consumed_value, unit } = nutrient;
        const formattedValue = consumed_value || 0;
        const displayUnit = unit ? ` ${unit}` : '';

        return (
          <span key={index}>
            {nutritent_name} - {formattedValue}
            {displayUnit}
            {index !== nutritionProfile.length - 1 && ', '}
          </span>
        );
      });
    }
  };

  const [isFull, setIsFull] = useState(false);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    if (resizing) {
      const handleResize = (event) => {
        const layout = document.querySelector('.resizable-layout');
        const mouseX = event.clientX;
        const layoutRect = layout.getBoundingClientRect();
        const leftColumn = layout.querySelector('.resizable-column:first-child');
        const rightColumn = layout.querySelector('.resizable-column:last-child');
        const verticalHandle = layout.querySelector('.vertical');

        if (!verticalHandle) return;

        const totalWidth = layoutRect.width;
        const dividerWidth = verticalHandle.getBoundingClientRect().width;
        const leftWidth = mouseX - layoutRect.left - dividerWidth / 2;
        const rightWidth = totalWidth - leftWidth - dividerWidth;

        leftColumn.style.width = `${leftWidth}px`;
        rightColumn.style.width = `${rightWidth}px`;

        if (rightWidth > 500) {
          setIsFull(true);
        } else {
          setIsFull(false);
        }
      };

      const handleResizeEnd = () => {
        setResizing(false);
      };

      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);

      return () => {
        document.removeEventListener('mousemove', handleResize);
        document.removeEventListener('mouseup', handleResizeEnd);
      };
    }
  }, [resizing]);

  const handleResizeStart = () => {
    setResizing(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe_data?search=${QueryForInp}&page=1&size=200`,
      method: 'get',
      signal: controller.signal,
      headers: headers,
    };
    if (QueryForInp) {
      axios
        .request(config)
        .then(({ data }) => {
          setFetched(data.data[0].rows);
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    return () => {
      controller.abort();
    };
  }, [QueryForInp]);

  useEffect(() => {
    const controller = new AbortController();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/ingredientV1?search=${queryIng}&page=1&size=200`,
      method: 'get',
      signal: controller.signal,
      headers: headers,
    };
    if (queryIng) {
      axios
        .request(config)
        .then(({ data }) => {
          setFetchedIng(data.data);
          // console.log('dat', data.data);
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    return () => {
      controller.abort();
    };
  }, [queryIng]);

  const onQueryChange = (e) => {
    setQueryForInp(e.target.value);
  };
  const onQueryChangeIng = (e) => {
    setQueryIng(e.target.value);
  };

  const filteredData =
    QueryForInp === ''
      ? fetched
      : fetched.filter((rec) =>
          rec.name
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(QueryForInp?.toLowerCase().replace(/\s+/g, ''))
        );

  const filteredIng =
    queryIng === ''
      ? fetchedIng
      : fetchedIng.filter((ing) =>
          ing.name
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(queryIng?.toLowerCase().replace(/\s+/g, ''))
        );

  const editMealTime = () => {
    const data = {
      plan_id: planId,
      template_id: templateId,
      meal_id: meal_id ? meal_id : selected.id,
      meal_name: inputMealVal,
      meal_time: inputTimeVal,
      type: EditMeal ? 'edit' : 'add',
    };
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}/admin/template/v1/edit/plan/meal`, data, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        const timeArray = finalData.meal_time.split(' ');
        const time = timeArray[0];
        const period = timeArray[1];
        const [hours, minutes] = time.split(':');
        let formattedTime = parseInt(hours);
        if (period === 'PM') {
          formattedTime += 12;
        }
        formattedTime = formattedTime * 60 + parseInt(minutes);

        if (EditMeal) {
          getTemplate();
        } else {
          setTemplateEditable((prevUserData) => {
            const updatedUserData = [...prevUserData.recipes, finalData];
            updatedUserData.sort((a, b) => {
              const timeArrayA = a.meal_time.split(' ');
              const timeArrayB = b.meal_time.split(' ');
              const timeA = timeArrayA[0];
              const timeB = timeArrayB[0];
              const periodA = timeArrayA[1];
              const periodB = timeArrayB[1];
              const [hoursA, minutesA] = timeA.split(':');
              const [hoursB, minutesB] = timeB.split(':');
              let formattedTimeA = parseInt(hoursA);
              let formattedTimeB = parseInt(hoursB);

              if (periodA === 'PM' && formattedTimeA !== 12) {
                formattedTimeA += 12;
              } else if (periodA === 'AM' && formattedTimeA === 12) {
                formattedTimeA = 0;
              }

              if (periodB === 'PM' && formattedTimeB !== 12) {
                formattedTimeB += 12;
              } else if (periodB === 'AM' && formattedTimeB === 12) {
                formattedTimeB = 0;
              }

              formattedTimeA = formattedTimeA * 60 + parseInt(minutesA);
              formattedTimeB = formattedTimeB * 60 + parseInt(minutesB);
              return formattedTimeA - formattedTimeB;
            });
            return {
              ...prevUserData,
              recipes: updatedUserData,
            };
          });
        }
        setIsOpenForAdd(false);
        setInputMealVal('');
        setInputTimeVal('');
        setSelected('');
        setRefreshData((old) => !old);
      })
      .catch((err) => {
        const error = err.response;
        toast.error(error);
        setLoader(false);
        if (error) {
          setInputMealVal('');
          setInputTimeVal('');
          setSelected({ meal_time: '', meal_name: '' });
          setQuery('');
        }
      });
  };

  useEffect(() => {
    if (query) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/meal_types?search=${query}`,
          { headers: headers }
        )
        .then(({ data }) => {
          // console.log('data', data.data);
          setData(data.data);
          //data.data.length > 0 ? setSelectedTemp(data.data[0]) : "";
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [query]);

  return (
    <div>
      {Loader && (
        <div className="fixed h-screen w-screen z-[99] inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]"></div>
        </div>
      )}
      <PagesHeader
        name={'Edit Template'}
        array={<ArrayBack className="cursor-pointer" />}
        secondHeading={'Edit Template'}
        thirdHeading={'Edit Template'}
      />
      <div
        id="shadow-Dropdown-Shadow"
        className="shadow-Dropdown-Shadow bg-[#FFF] rounded-[20px] ingredient-box"
      >
        <div className="flex items-center justify-between py-[10px] px-4 border-b">
          <div className="flex items-center 2xl:gap-9 xl:gap-5 lg:gap-[14px]">
            <div className="flex flex-col">
              <div className="flex items-center gap-3">
                <div className="border-b border-chalice md:w-[180px]">
                  {!IsEditing ? (
                    <span className=" 2xl:text-xl 2xl:leading-[30px] xl:text-[17.78px] lg:text-[14.81px] font-normal text-black  ">
                      {NewLabel}
                    </span>
                  ) : (
                    <input
                      type="text"
                      value={NewLabel}
                      onChange={(e) => {
                        setNewLabel(e.target.value);
                      }}
                      ref={editLabelRef}
                      placeholder="Enter Value"
                      className="outline-none md:w-[124px] 2xl:text-xl 2xl:leading-[30px] xl:text-[17.78px] lg:text-[14.81px] font-normal text-black"
                    />
                  )}
                </div>
                <div className="cursor-pointer flex items-center md:gap-[10px]">
                  {!IsEditing ? (
                    <div
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      <Edit />
                    </div>
                  ) : (
                    <div className="flex md:gap-[10px]">
                      <Apply onClick={updateName} /> {/* Save changes */}
                      <Cancel onClick={() => setIsEditing(false)} /> {/* Cancel editing */}
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  <Button
                    className={classNames(
                      'bg-brand-color  text-white flex items-center 2xl:ml-5  2xl:px-5 2xl:py-2 xl:px-[15px] lg:px-[10px] lg:py-[5.93px] md:py-[4px] 2xl:rounded-[10px] lg:rounded-lg md:rounded-md border-rad-9px  gap-[10px] text-base font-Regular'
                    )}
                    onClick={() => {
                      setEditMeal(false);
                      setIsOpenForAdd(true);
                    }}
                  >
                    <span>
                      <Add className=" 2xl:h-6 2xl:w-6 lg:w-[16px] lg:h-[16px] md:w-[14px] md:h-[14px]" />
                    </span>
                    Add New Meal
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`flex lg:gap-[27px] md:gap-[22px] content-diet-table`}>
          <div className={`flex resizable-layout lg:gap-[27px] md:gap-[22px] content-diet-table`}>
            <div
              style={{ width: '60%', minWidth: 600 }}
              className="resizable-column w-[60%]] custom-scrollbar content-diet-plan3 overflow-auto  border-r-2"
            >
              {TemplateEditable.recipes.length === 0 && (
                <div className="flex items-center justify-center  bg-white w-full h-[80vh] p-12 rounded-2xl ">
                  <NoData className="object-cover w-full h-full" />
                </div>
              )}
              {TemplateEditable.recipes?.map((mealWithTime, index) => {
                const processedRecipes = mealWithTime?.suggested_recipes?.map((rec) => {
                  const matchedMeasurement = rec?.master_measurements?.find(
                    (measurement) => measurement.measurement_unit === rec?.measurement
                  );
                  return { ...rec, selectedMeasurement: matchedMeasurement };
                });
                return (
                  <>
                    <div className="MealSection 2xl:px-[20px] xl:px-[17px] lg:px-[14px] border-b">
                      <div className="flex justify-between lg:py-[8px] xl:py-[13px] 2xl:py-[16px] items-center">
                        <div className="flex items-center  lg:gap-[14px] md:gap-[10px] gap-10px ">
                          <div className="MealInput flex items-center gap-2 ">
                            <div
                              className={`2xl:text-base xl:text-[12.22px] lg:text-[11.85px] font-medium`}
                            >
                              <span className="inline-flex border-b">
                                <span
                                  className={`truncate-custom overflow-hidden text-ellipsis whitespace-nowrap `}
                                >
                                  {mealWithTime.meal_type}
                                </span>
                                <span>
                                  {!mealWithTime.meal_time ? '' : ' - '}
                                  {mealWithTime.meal_time}
                                </span>
                              </span>
                            </div>
                            <button
                              className="cursor-pointer"
                              onClick={() => {
                                setEditMeal(true);
                                setmeal_id(mealWithTime?.meal_type_id);
                                setInputMealVal(mealWithTime?.meal_type);
                                setInputTimeVal(mealWithTime?.meal_time);
                                setIsOpenForAdd(true);
                              }}
                            >
                              {<Edit />}
                            </button>
                          </div>
                        </div>
                        <div
                          className={`text-[4px] sm:text-[4px] md:text-[4px] lg:text-[8px] xl:text-xs 2xl:text-xs`}
                        >
                          {formattedNutrients(mealWithTime.nutrition_profile)}
                        </div>
                        <div className="flex items-center  lg:gap-[14px] md:gap-[10px] gap-16px ">
                          <div className="flex  lg:gap-[14px] md:gap-[10px] gap-16px  ">
                            <div className="cursor-pointer">
                              <Delete
                                onClick={() => {
                                  deleteMeal(mealWithTime?.meal_type_id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {processedRecipes?.map((rec, indx) => {
                      return (
                        <div
                          className=" 2xl:px-[20px] xl:px-[17px] lg:px-[14px] 2xl:py-[13px] xl:py-[9px] lg:py-[7px]  "
                          key={indx}
                        >
                          <div className="border-b ">
                            <div className="flex items-center justify-between ">
                              <div className="flex items-center justify-between w-1/2">
                                {/* 2xl:py-[13px] lg:py-[10px] md:py-[8px] */}
                                <div
                                  className="  2xl:text-xs xl:text-[10px] lg:leading-0 lg:text-[8px]   font-Regular  cursor-pointer min-w-fit"
                                  onClick={() => {
                                    const matchingMeasurement = rec?.master_measurements.find(
                                      (item) => item.measurement_unit === rec?.measurement
                                    );
                                    let data = {
                                      recipe_id: rec?.recipe_id,
                                      serving: rec?.serving,
                                      value_in_gm: matchingMeasurement?.value_in_gm,
                                    };
                                    let config = {
                                      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe/breakdown`,
                                      method: 'POST',
                                      data: data,
                                      headers: headers,
                                    };
                                    axios
                                      .request(config)
                                      .then(
                                        ({ data }) => {
                                          setIngredientData(data.data[0]?.ingredients);
                                          setNutritionData(data.data[0]?.nutrition_profile);
                                          setRecipeName(data.data[0]?.recipe_name);

                                          setOpenRecipeModal(!openRecipeModal);
                                        },
                                        (err) => {
                                          setOpenRecipeModal(!openRecipeModal);
                                        }
                                      )
                                      .catch((err) => {
                                        setOpenRecipeModal(!openRecipeModal);
                                        toast(err, {
                                          position: 'top-center',
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: 'light',
                                        });
                                      });
                                  }}
                                >
                                  <span
                                    className={`truncate-custom overflow-hidden text-ellipsis whitespace-break-spaces font-bold lg:max-w-[90px] xl:max-w-[160px] 2xl:max-w-[230px]`}
                                  >
                                    {rec?.recipe_name}
                                  </span>
                                </div>
                                <div className="flex items-center 2xl:py-[6px] 2xl:px-2 lg:py-[4px] lg:px-[6px] md:px-1 ">
                                  <button
                                    className="border-t border-l border-b 2xl:py-[15px] 2xl:px-[15px] lg:py-[11px] lg:px-[8px] md:px-[11px] md:py-[10px] rounded-Count-Radius cursor-pointer"
                                    onClick={() => {
                                      if (rec?.serving > 0.0) {
                                        const matchingMeasurement = rec?.master_measurements.find(
                                          (item) => item.measurement_unit === rec?.measurement
                                        );
                                        setLoader(true);
                                        let token = getToken();
                                        let config = {
                                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/v1/update_recipe_serving`,
                                          method: 'PATCH',
                                          headers: headers,
                                          data: {
                                            plan_id: planId,
                                            meal_type_id: rec?.meal_type_id,
                                            recipe_id: rec?.recipe_id,
                                            serving: rec?.serving - 1.0,
                                            type: 'measurement',
                                            unit: rec?.measurement,
                                            measurement: matchingMeasurement,
                                          },
                                        };
                                        axios
                                          .request(config)
                                          .then((response) => {
                                            // setCallApi(CallApi + 1);
                                            const finalData = response.data.data[0];
                                            setTemplateEditable((prevUserData) => {
                                              return {
                                                ...prevUserData,
                                                recipes: prevUserData.recipes.map((recipe) => {
                                                  if (
                                                    recipe.meal_type_id === finalData.meal_type_id
                                                  ) {
                                                    return {
                                                      ...recipe,
                                                      nutrition_profile: finalData.nutrient_profile,
                                                      suggested_recipes:
                                                        recipe.suggested_recipes.map(
                                                          (suggestedRecipe) => {
                                                            if (
                                                              suggestedRecipe.recipe_id ===
                                                              rec?.recipe_id
                                                            ) {
                                                              return {
                                                                ...suggestedRecipe,
                                                                serving: rec?.serving - 1.0,
                                                              };
                                                            }
                                                            return suggestedRecipe;
                                                          }
                                                        ),
                                                    };
                                                  }
                                                  return recipe;
                                                }),
                                              };
                                            });
                                            setLoader(false);
                                            setRefreshData((old) => !old);
                                          })
                                          .catch((err) => {
                                            toast(err);
                                            setLoader(false);
                                          });
                                      } else {
                                        toast.error('serving cannot be less than zero');
                                        return;
                                      }
                                    }}
                                  >
                                    <Incres />
                                  </button>
                                  <div className="border-l border-b border-t 2xl:py-[8px] xl:py-[4.5px] lg:py-[6px] flex justify-center items-center">
                                    <input
                                      type="number"
                                      onChange={(e) => {
                                        const inputValue = parseFloat(e.target.value);

                                        // Optimistically update the UI
                                        setTemplateEditable((prevUserData) => ({
                                          ...prevUserData,
                                          recipes: prevUserData.recipes.map((recipe) => {
                                            if (recipe.meal_type_id === rec?.meal_type_id) {
                                              return {
                                                ...recipe,
                                                suggested_recipes: recipe.suggested_recipes.map(
                                                  (suggestedRecipe) => {
                                                    if (
                                                      suggestedRecipe.recipe_id === rec?.recipe_id
                                                    ) {
                                                      return {
                                                        ...suggestedRecipe,
                                                        serving: inputValue,
                                                      };
                                                    }
                                                    return suggestedRecipe;
                                                  }
                                                ),
                                              };
                                            }
                                            return recipe;
                                          }),
                                        }));

                                        const matchingMeasurement = rec?.master_measurements.find(
                                          (item) => item.measurement_unit === rec?.measurement
                                        );

                                        setLoader(true);

                                        // Make API request to update the serving
                                        const config = {
                                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/v1/update_recipe_serving`,
                                          method: 'PATCH',
                                          headers: headers,
                                          data: {
                                            plan_id: planId,
                                            meal_type_id: rec?.meal_type_id,
                                            recipe_id: rec?.recipe_id,
                                            serving: inputValue,
                                            type: 'measurement',
                                            unit: rec?.measurement,
                                            measurement: matchingMeasurement,
                                          },
                                        };

                                        axios
                                          .request(config)
                                          .then((response) => {
                                            const finalData = response.data.data[0];
                                            setTemplateEditable((prevUserData) => ({
                                              ...prevUserData,
                                              recipes: prevUserData.recipes.map((recipe) => {
                                                if (
                                                  recipe.meal_type_id === finalData.meal_type_id
                                                ) {
                                                  return {
                                                    ...recipe,
                                                    nutrition_profile: finalData.nutrient_profile,
                                                    suggested_recipes: recipe.suggested_recipes.map(
                                                      (suggestedRecipe) => {
                                                        if (
                                                          suggestedRecipe.recipe_id ===
                                                          rec?.recipe_id
                                                        ) {
                                                          return {
                                                            ...suggestedRecipe,
                                                            serving: finalData.serving,
                                                          };
                                                        }
                                                        return suggestedRecipe;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return recipe;
                                              }),
                                            }));
                                            setLoader(false);
                                            setRefreshData((old) => !old);
                                          })
                                          .catch((err) => {
                                            toast(err.message);
                                            setLoader(false);
                                          });
                                      }}
                                      onFocus={() => setFocused(true)}
                                      onBlur={() => setFocused(false)}
                                      value={
                                        focused
                                          ? rec?.serving
                                          : rec?.serving == parseInt(rec?.serving)
                                          ? rec?.serving + '.0'
                                          : rec?.serving
                                      }
                                      className="2xl:min-w-10 lg:min-w-[34px] md:w-8 px-2 outline-none 2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular"
                                    />
                                  </div>

                                  <button
                                    className="border 2xl:py-[11px] 2xl:px-[12px] lg:py-[7px] lg:px-[8px] md:px-[8px] md:py-[5px] rounded-Incres-radius cursor-pointer"
                                    onClick={() => {
                                      const matchingMeasurement = rec?.master_measurements.find(
                                        (item) => item.measurement_unit === rec?.measurement
                                      );
                                      setLoader(true);
                                      let config = {
                                        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/v1/update_recipe_serving`,
                                        method: 'PATCH',
                                        headers: headers,
                                        data: {
                                          plan_id: planId,
                                          meal_type_id: rec?.meal_type_id,
                                          recipe_id: rec?.recipe_id,
                                          serving: rec?.serving + 1.0,
                                          type: 'measurement',
                                          unit: rec?.measurement,
                                          measurement: matchingMeasurement,
                                        },
                                      };
                                      axios
                                        .request(config)
                                        .then((response) => {
                                          const finalData = response.data.data[0];
                                          setTemplateEditable((prevUserData) => {
                                            return {
                                              ...prevUserData,
                                              recipes: prevUserData.recipes.map((recipe) => {
                                                if (
                                                  recipe.meal_type_id === finalData.meal_type_id
                                                ) {
                                                  return {
                                                    ...recipe,
                                                    nutrition_profile: finalData.nutrient_profile,
                                                    suggested_recipes: recipe.suggested_recipes.map(
                                                      (suggestedRecipe) => {
                                                        if (
                                                          suggestedRecipe.recipe_id ===
                                                          rec?.recipe_id
                                                        ) {
                                                          return {
                                                            ...suggestedRecipe,
                                                            serving: rec?.serving + 1.0,
                                                          };
                                                        }
                                                        return suggestedRecipe;
                                                      }
                                                    ),
                                                  };
                                                }
                                                return recipe;
                                              }),
                                            };
                                          });
                                          setLoader(false);
                                          setRefreshData((old) => !old);
                                        })
                                        .catch((err) => {
                                          toast(err);
                                          setLoader(false);
                                        });
                                    }}
                                  >
                                    <Plus />
                                  </button>
                                </div>
                              </div>

                              <div className="flex items-center justify-between  w-1/2 ml-5 2xl:py-[6px] 2xl:px-2 lg:py-[4px]">
                                <div className={` border rounded  `}>
                                  <div>
                                    <div className="lg:w-36 md:w-32">
                                      <Listbox
                                        value={rec.selectedMeasurement}
                                        onChange={(value) => {
                                          setLoader(true);
                                          let config = {
                                            baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/v1/update_recipe_serving`,
                                            method: 'PATCH',
                                            headers: headers,
                                            data: {
                                              plan_id: planId,
                                              meal_type_id: rec?.meal_type_id,
                                              recipe_id: rec?.recipe_id,
                                              serving: rec?.serving,
                                              type: 'measurement',
                                              unit: value.measurement_unit,
                                              measurement: value,
                                            },
                                          };
                                          axios
                                            .request(config)
                                            .then((response) => {
                                              const finalData = response.data.data[0];
                                              setTemplateEditable((prevUserData) => {
                                                return {
                                                  ...prevUserData,
                                                  recipes: prevUserData.recipes.map((recipe) => {
                                                    if (
                                                      recipe.meal_type_id === finalData.meal_type_id
                                                    ) {
                                                      return {
                                                        ...recipe,
                                                        nutrition_profile:
                                                          finalData.nutrient_profile,
                                                        suggested_recipes:
                                                          recipe.suggested_recipes.map(
                                                            (suggestedRecipe) => {
                                                              if (
                                                                suggestedRecipe.recipe_id ===
                                                                rec?.recipe_id
                                                              ) {
                                                                return {
                                                                  ...suggestedRecipe,
                                                                  measurement:
                                                                    value?.measurement_unit,
                                                                };
                                                              }
                                                              return suggestedRecipe;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return recipe;
                                                  }),
                                                };
                                              });
                                              setLoader(false);
                                              setRefreshData((old) => !old);
                                            })
                                            .catch((err) => {
                                              toast(err);
                                              setLoader(false);
                                            });
                                        }}
                                      >
                                        <div className="relative">
                                          <Listbox.Button className="relative w-full cursor-default rounded bg-white 2xl:py-[7px] xl:py-[4px] lg:py-[4px] md:px-[6px] text-left 2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular">
                                            <div className="flex justify-between items-center truncate">
                                              <div>
                                                {rec?.selectedMeasurement?.measurement_unit}
                                              </div>
                                              <div>
                                                <Expand className="ui-open:transform ui-open:rotate-180 lg:w-3 lg:h-3 md:w-1 md:h-1" />
                                              </div>
                                            </div>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                              <div className="text-gray-400" aria-hidden="true" />
                                            </span>
                                          </Listbox.Button>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute mt-1 border-b z-10 w-full overflow-auto rounded bg-white 2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular">
                                              {rec?.master_measurements?.map((value, idx) => (
                                                <Listbox.Option
                                                  key={idx}
                                                  className={({ active }) =>
                                                    `relative cursor-default select-none lg:py-[8px] lg:px-4 md:py-[6px] md:px-3 ${
                                                      active
                                                        ? 'bg-shades-2/20 text-black'
                                                        : 'text-gray-900'
                                                    }`
                                                  }
                                                  value={value}
                                                >
                                                  {({ selected }) => (
                                                    <>
                                                      <span
                                                        className={`block truncate ${
                                                          selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                      >
                                                        {value.measurement_unit}
                                                      </span>
                                                      {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center text-amber-600">
                                                          <div className="" aria-hidden="true" />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                      </Listbox>
                                    </div>
                                  </div>
                                </div>

                                <div className=" cursor-pointer">
                                  <Delete
                                    onClick={() => {
                                      deleteARecipe(rec?.recipe_id, mealWithTime?.meal_type_id);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex justify-center items-center lg:gap-[14px] md:gap-[10px] gap-16px  md:px-3 lg:px-4 lg:pb-[8px] md:pb-[6px] border-b ">
                      <InpDropDown
                        heading={'Type Recipe Name'}
                        onChange={onQueryChange}
                        records={filteredData}
                        query={QueryForInp}
                        onSetQuery={() => setQueryForInp('')}
                        selected={selected}
                        setSelected={setSelected}
                        planId={planId}
                        meal_type_id={mealWithTime.meal_type_id}
                        type="recipe"
                        setLoader={setLoader}
                        editable={mealWithTime?.editable}
                        time={mealWithTime?.meal_time}
                        meal={mealWithTime?.meal_type}
                        setData={mealWithTime?.suggested_recipes}
                        setnutritionProfile={mealWithTime?.nutrition_profile}
                        setRefreshData={setRefreshData}
                        userdata={TemplateEditable.recipes}
                        setUserData={setTemplateEditable}
                        templateId={templateId}
                        setOverlay={setLoader}
                      />
                      <InpDropDown
                        heading={'Type Ingredients Name'}
                        onChange={onQueryChangeIng}
                        records={filteredIng}
                        query={queryIng}
                        onSetQuery={() => setQueryIng('')}
                        selected={selectedIng}
                        setSelected={setSelectedIng}
                        planId={planId}
                        meal_type_id={mealWithTime.meal_type_id}
                        type="ingredient"
                        setLoader={setLoader}
                        editable={mealWithTime?.editable}
                        time={mealWithTime?.meal_time}
                        meal={mealWithTime?.meal_type}
                        setData={mealWithTime?.suggested_recipes}
                        setnutritionProfile={mealWithTime?.nutrition_profile}
                        setRefreshData={setRefreshData}
                        userdata={TemplateEditable.recipes}
                        setUserData={setTemplateEditable}
                        templateId={templateId}
                        setOverlay={setLoader}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div
              className={`vertical ${resizing ? 'resizing' : ''}`}
              onMouseDown={handleResizeStart}
            ></div>
            <div
              style={{ width: '40%', minWidth: 400 }}
              className={`resizable-column overflow-auto border-l border-r content-diet-plan3`}
            >
              <EnergyDistribution
                userdata={TemplateEditable.recipes}
                isFull={isFull}
                setIsFull={setIsFull}
                userId={templateId}
                planId={planId}
                // setRefreshData={setRefreshData}
                refreshData={RefreshData}
              />
            </div>
          </div>
        </div>
      </div>
      <UpdateModal
        isOpen={isOpenForAdd}
        closeModal={() => {
          setInputMealVal('');
          setInputTimeVal('');
          setSelected('');
          setIsOpenForAdd(false);
        }}
        data={data}
        inputMealVal={inputMealVal}
        setInputMealVal={setInputMealVal}
        inputTimeVal={inputTimeVal}
        setInputTimeVal={setInputTimeVal}
        selected={selected}
        setSelected={setSelected}
        query={query}
        editMealTime={editMealTime}
        mealtype={EditMeal ? inputMealVal : ''}
        setQuery={setQuery}
      />
      <RacipeForm
        isOpen={openRecipeModal}
        isclose={closeRecipeModal}
        ingredientData={ingredientData}
        nutritionData={nutritionData}
        recipeName={recipeName}
      />
    </div>
  );
}
