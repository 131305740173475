import React, { useEffect, useState, useRef, useMemo } from 'react';
import _ from 'underscore';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as Search } from '../../assets/images/ic_search.svg';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import { ReactComponent as Delete } from '../../assets/images/delete.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment } from 'react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';
import { Menu, Combobox, Transition } from '@headlessui/react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SortIcon from '../../assets/images/des.png';
import { useNavigate } from 'react-router';
import { sortOrganizations } from '../../Utils/SortSearch';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import Confirmation from '../layout/Templates/Confirmation';

const columnHelper = createColumnHelper();

export default function TemplateList({ templates }) {
  const navigate = useNavigate();

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const openedJson = localStorage.getItem('setPage');
  const user = localStorage.getItem('user');
  const permission_only_admin = JSON.parse(user);
  const roles = permission_only_admin.role_name === 'SuperAdmin';

  const [indexbutton, setIndexButton] = useState([
    {
      id: 1,
    },
  ]);
  const [OpenDelete, setOpenDelete] = useState(false);
  const [DeletingData, setDeletingData] = useState('');
  const [sorting, setSorting] = useState([]);
  const [TotalCount, setTotalCount] = useState({ final: '', showing: '' });
  const [SearchInput, setSearchInput] = useState('');
  const [currentIndex, setCurrentIndex] = useState(openedJson ? Number(openedJson) : 1);
  const initialRows = Array(12).fill({
    template_id: -1,
    name: -1,
    created_by: -1,
    updated_by: -1,
    created_at: -1,
    updated_at: -1,
    in_use: -1,
    delete: -1,
  });
  const [Data, setData] = useState({
    columns: [
      { id: 0, column_name: 'Template ID', map_column: 'template_id', type: 'string' },
      { id: 1, column_name: `Template Name`, map_column: 'name', type: 'string' },
      { id: 1, column_name: 'Created By', map_column: 'created_by', type: 'string' },
      { id: 1, column_name: 'Creation Date', map_column: 'created_at', type: 'string' },
      { id: 1, column_name: 'Last Edited By', map_column: 'updated_by', type: 'string' },
      { id: 1, column_name: 'Last Modified Date', map_column: 'updated_at', type: 'string' },
      // { id: 1, column_name: 'In Use', map_column: 'in_use', type: 'string' },
      roles ? { id: 1, column_name: 'Action', map_column: 'delete', type: 'string' } : null,
    ].filter((item) => item !== null),
    rows: initialRows,
  });

  useEffect(setPagination, [TotalCount.final]);

  function setPagination() {
    if (Data.length <= 0 || TotalCount.final <= 0) {
      return;
    }
    let no_of_pages = Math.ceil(TotalCount.final / 100);
    // console.log('no_of_pages', totalCount, data.length, no_of_pages);
    setIndexButton(
      Array(no_of_pages)
        .fill()
        .map((_, index) => ({ id: index + 1 }))
    );

    setCurrentIndex(openedJson ? Number(openedJson) : 1);
  }

  const deletTemplate = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/admin/template/v1/remove?template_id=${id}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data[0];
        setOpenDelete(false);
        setDeletingData('');
        FetchTemplate();
      })
      .catch((error) => {
        const finalError = error.response.data;
      });
  };

  const columns = useMemo(() => {
    const { columns } = Data;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) => {
          const value = info.getValue();
          return value === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span
              className={`${
                column.map_column === 'client_email' || column.map_column === 'client_name'
                  ? 'break-all'
                  : 'capitalize'
              }`}
            >
              {value}
            </span>
          );
        },
        header: () => (
          <div>
            <span className="">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Data]);

  useEffect(() => {
    const scrollToId = localStorage.getItem('scrollToId');
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        console.log('Scrolling to element:', scrollToId);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        localStorage.removeItem('scrollToId');
      } else {
        console.log('Element with ID', scrollToId, 'not found in DOM.');
      }
    }
  }, [Data]);

  const table = useReactTable({
    data: Data.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const FetchTemplate = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/template/v1/list?page=${currentIndex}&size=100&search=${SearchInput}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const storeActivity = response.data.data;
        setData((prevLog) => ({
          ...prevLog,
          rows: storeActivity.templates,
        }));
        setTotalCount({
          ...TotalCount,
          final: storeActivity.total_count,
          showing: storeActivity.given_records,
        });
      })
      .catch((error) => {
        if (error.response.data.code === '500') {
          localStorage.clear();
          navigate('/login');
        }
      });
  };

  useEffect(() => {
    FetchTemplate();
    setData((prevLog) => ({
      ...prevLog,
      rows: initialRows,
    }));
  }, [currentIndex, SearchInput]);

  const scroll = { y: 0 };
  function handleScroll(e) {
    scroll.y = e.target.scrollTop;
  }

  const tableHeader = useRef(null);
  const tableFilter = useRef(null);
  const headingRef = useRef(null);
  const handleResize = () => {
    let tableHeaderHeight = 0;
    let tableFilterHeight = 0;
    if (tableHeader.current) {
      tableHeaderHeight = tableHeader.current.getBoundingClientRect().height;
    }
    if (tableFilter.current) {
      tableFilterHeight = tableFilter.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      '--table-header-height',
      tableHeaderHeight + tableFilterHeight
    );
    let headerHeight = 0;
    if (headingRef.current) {
      headerHeight = headingRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--table-heading-height', headerHeight);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector('.active-button');
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [currentIndex]);

  return (
    <div>
      <PagesHeader
        name={'Template Dashboard'}
        //   array={<ArrowBack className="cursor-pointer" />}
        secondHeading={'Template Dashboard'}
        thirdHeading={'Template Dashboard'}
      />
      <div className="px-5 bg-white shadow-card-shadow rounded-medium table-card">
        {/* <div ref={tableHeader} className="items-center border-b divide-y ">
          <div className=" font-Regular   xl:text-2xl lg:text-xl md:text-[12px] md:leading-5 text-body-color-2   py-[10px] md:py-2  ">
            List Of Customer
          </div>
        </div> */}
        <div
          ref={tableFilter}
          className=" xl lg:pt-[10px] md:pt-2 justify-between flex flex-wrap gap-3"
        >
          <div />
          <div className="relative flex ">
            <div className="flex items-center ">
              <Search className="absolute left-[10px] " />
              <input
                placeholder="Search by Name or ID"
                value={SearchInput}
                className=" pl-[36px] py-[10px] h-[48px] shadow--shadow border outline-none border-secondary-3  lg:rounded-lg md:rounded-md   lg:w-[223px] xl:w-[267px] 2xl:w-[300px] 2xl:text-base xl:text-sm lg:text-xs  md:text-[9px] "
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setCurrentIndex(1);
                  localStorage.setItem('setPage', 1);
                }}
              />
            </div>
          </div>
        </div>
        <div className={`lg:mt-[5px] table-client`}>
          {Data.rows.length > 0 ? (
            <>
              <div className="tableFixHead">
                <table className="min-w-[-webkit-fill-available] client-table table-margin ">
                  <thead ref={headingRef} className="sticky top-0 items-center thead">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="overflow-hidden flex ">
                        {headerGroup.headers.map((header, index, array) => {
                          return (
                            <th
                              key={header.id}
                              className={`text-start ${
                                roles ? `col-${index}-temp` : `col-${index}-temp1`
                              } bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-5 last-of-type:border-r-0  py-[9px]  font-medium   2xl:text-xl xl:text-sm lg:text-xs  ${
                                header.id === 'updated_at' && 'cursor-pointer'
                              }`}
                              onClick={
                                header.id === 'updated_at'
                                  ? header.column.getToggleSortingHandler()
                                  : null
                              }
                            >
                              <div className="flex items-center justify-between w-full">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header)}
                                {header.id !== 'updated_at'
                                  ? null
                                  : {
                                      asc: (
                                        <img
                                          src={SortIcon}
                                          // height={20}
                                          className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          style={{ transform: 'rotate(180deg)' }}
                                          alt="SortIcon"
                                        />
                                      ),
                                      desc: (
                                        <img
                                          src={SortIcon}
                                          className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          alt="SortIcon"
                                        />
                                      ),
                                      false: (
                                        <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                      ),
                                    }[header.column.getIsSorted()]}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="duration-1000 tbody-cust">
                    {table.getRowModel().rows.map((row, ind) => (
                      <tr
                        key={row.id}
                        id={`row-${row.id}`}
                        className="cursor-pointer clienttable_row even:bg-secondary-3/20 mb-1 flex w-full"
                      >
                        {row.getVisibleCells().map((cell, ind) => (
                          <td
                            key={cell.id}
                            className={`duration-1000 text-left col-${ind}-temp lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3 text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-sm xl:text-xs lg:text-xs  `}
                            onClick={() => {
                              if (cell.column.id === 'delete') return;
                              let templateId = row.original.id;
                              navigate(`/template/${templateId}`);
                              localStorage.setItem('setPage', currentIndex);
                              localStorage.setItem('scrollToId', `row-${row.id}`);
                            }}
                          >
                            {cell.column.id === 'delete' ? (
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  onClick={() => {
                                    setDeletingData(row.original.id);
                                    setOpenDelete(true);
                                  }}
                                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                                >
                                  <Delete />
                                </button>
                              </div>
                            ) : (
                              flexRender(cell.column.columnDef.cell, cell.getContext())
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-between md:py-3 text-secondary-1  ">
                <div className="flex ">
                  <span className="text-body-color-1 font-Regular 2xl:text-base lg:text-xs">
                    Showing {TotalCount.showing} of {TotalCount.final} entries
                  </span>
                </div>
                <div className="flex items-center max-w-[50%]">
                  <button
                    disabled={currentIndex === 1}
                    className={`${
                      currentIndex !== 1 ? ' hover:bg-shades-2  hover:text-white ' : 'diabledBtnBg '
                    }duration-200 buttons border first-of-type:rounded-s-[10px]   2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex > 1) {
                        setCurrentIndex(currentIndex - 1);
                        localStorage.setItem('setPage', currentIndex - 1);
                      }
                    }}
                  >
                    Previous
                  </button>
                  <div
                    className="flex overflow-auto"
                    ref={scrollContainerRef}
                    style={{ scrollSnapType: 'x proximity' }}
                  >
                    {indexbutton.map((value, id) => (
                      <button
                        key={id}
                        className={`${
                          currentIndex === value.id
                            ? ' bg-shades-2 text-white duration-1000 active-button'
                            : 'hover:bg-gray-100 duration-300'
                        }  duration-200    buttons border-t border-b  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                        onClick={() => {
                          setCurrentIndex(value.id);
                          localStorage.setItem('setPage', value.id);
                        }}
                        style={{
                          scrollSnapAlign: currentIndex === value.id ? 'start' : 'none',
                        }}
                      >
                        <div className="flex justify-center items-center 2xl:w-[16px] 2xl:h-[24px] xl:w-[19.18px] xl:h-[18px] lg:w-[16px] lg:h-[18px]">
                          {value.id}
                        </div>
                      </button>
                    ))}
                  </div>
                  <button
                    disabled={currentIndex === indexbutton.length}
                    className={`${
                      currentIndex !== indexbutton.length
                        ? ' hover:bg-shades-2  hover:text-white '
                        : 'diabledBtnBg'
                    } duration-200    border nxtbutton  last-of-type:rounded-e-[10px]  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex < indexbutton.length) {
                        setCurrentIndex(currentIndex + 1);
                        localStorage.setItem('setPage', currentIndex + 1);
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-full">
              <ClientError className="w-full h-full" />
            </div>
          )}
        </div>
        <Confirmation
          TitleForModal={'Delete'}
          Sureity={'Are you sure you want to delete this?'}
          Sureity2={"You won't be able to restore it once it is deleted."}
          OpenCall={OpenDelete}
          CloseCall={() => {
            setOpenDelete(false);
            setDeletingData('');
          }}
          deleteCall={() => deletTemplate(DeletingData)}
        />
      </div>
    </div>
  );
}
