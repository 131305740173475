import React, { useState, useRef, useEffect } from 'react';
import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';

const InpDropDown = ({
  heading,
  onChange,
  records,
  query,
  onSetQuery,
  selected,
  setSelected,
  planId,
  refreshdata,
  setRefreshData,
  meal_type_id,
  type,
  setOverlay,
  editable,
  time,
  meal,
  disabled,
  setMealAdded,
  setData,
  setEnableApprove,
  setnutritionProfile,
  setUserData,
  userdata,
  setCallApi,
  CallApi,
  setSectionAdded,
}) => {
  const addData = (obj) => {
    let data = {};
    if (editable) {
      if (meal && time) {
        if (time === '00:00 AM') {
          toast.warning('Please Specify Meal Time');
          return;
        }
        data = {
          type: type,
          plan_id: planId,
          recipe_id: obj.id,
          serving: obj.serving || 1,
          measurement: 'Bowl',
          meal_name: meal,
          meal_time: time,
          is_add_new: true,
        };
        setSectionAdded((old) => !old);
      } else {
        // setOverlay((old) => !old);
        // setOverlay(true);
        // console.log('meal', meal, time);
        toast.warning('Please Specify Meal Time and Type');
        return;
      }
    } else {
      data = {
        type: obj.type,
        plan_id: obj.plan_id,
        meal_type_id: obj.meal_type_id,
        recipe_id: obj.id,
        serving: obj.serving || 1,
        measurement: 'Bowl',
      };
    }

    let token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/add_recipe_to_meal`, data, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data[0];
        // setCallApi(CallApi + 1);
        setRefreshData((old) => !old);
        setUserData((prevUserData) =>
          prevUserData.map((user) => {
            if (user.meal_type_id === finalData.meal_type_id) {
              return {
                ...user,
                nutrition_profile: finalData.nutrient_profile,
                suggested_recipes: [
                  ...user.suggested_recipes,
                  {
                    dangerous_message: finalData.dangerous_message,
                    has_measurements: finalData.has_measurements,
                    is_dangerous: finalData.is_dangerous,
                    master_measurements: finalData.master_measurements,
                    meal_type_id: finalData.meal_type_id,
                    measurement: finalData.measurement,
                    recipe_id: finalData.recipe_id,
                    recipe_name: finalData.recipe_name,
                    serving: finalData.serving,
                  },
                ],
              };
            }
            return user;
          })
        );

        // setRefreshData(!refreshdata);
        // setData((prev) =>
        //   Array.isArray(prev) ? [...prev, data.data[0]] : new Array(data.data[0])
        // );
        // setnutritionProfile(data.data[0].nutrient_profile);
        // setEnableApprove(data.data[0]?.is_approved);
        setOverlay((old) => !old);
        setSelected('');
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
        setOverlay((old) => !old);
        setSelected('');
      });
  };

  const comboboxRef = useRef(null);

  const openDirection = () => {
    if (comboboxRef.current) {
      const comboboxRect = comboboxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceAbove = comboboxRect.top;
      const spaceBelow = windowHeight - comboboxRect.bottom;

      const threshold = 200;

      if (spaceBelow < threshold && spaceAbove > spaceBelow) {
        return 'top';
      } else {
        return 'bottom';
      }
    }
  };

  return (
    <>
      <div className=" border rounded  lg:w-[230px] md:w-[190px] lg:mt-[4px] md:mt-[2px]">
        <Combobox
          value={selected}
          ref={comboboxRef}
          onChange={(data) => {
            // console.log('qdata', data.name);
            setOverlay((old) => !old);
            if (editable) {
              if (type === 'template' && time && meal && editable) {
                setSelected(data);
              } else if (type !== 'template') {
                setSelected(data);
              } else if (editable && (!time || !meal)) {
                // console.log(time, ' ', meal);
                setOverlay((old) => !old);
                toast.error('Please Specify The Time and Meal');
                onSetQuery('');
                setSelected('');
                return;
              }
            } else {
              data['meal_type_id'] = meal_type_id;
              setSelected(data);
            }

            if (type === 'template') {
              setOverlay((old) => !old);
            } else {
              let filteredRecords = records.filter((rec) => rec.name === data.name);
              filteredRecords[0]['plan_id'] = planId;
              filteredRecords[0]['measurement'] = 'Bowl';
              filteredRecords[0]['meal_type_id'] = meal_type_id;
              filteredRecords[0].type = type;
              delete filteredRecords[0].name;
              addData(filteredRecords[0]);
            }
          }}
          disabled={disabled}
        >
          <div className="relative ">
            <div className="relative w-full cursor-default overflow-hidden  bg-white text-left  ">
              <Combobox.Input
                className="w-full   lg:py-[6px] pl-[6px] md:py-[4px] md:pl[4px]   2xl:text-xs xl:text-[10px] lg:text-[8px] rounded  text-body-color-2  outline-none"
                displayValue={(rec) => rec?.name}
                onChange={onChange}
                placeholder={heading}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <div className=" lg:h-4 lg:w-3 md:w-1 md:h-2 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Combobox.Options}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => onSetQuery()}
            >
              {query !== '' ? (
                <Combobox.Options
                  className={`absolute rounded-2xl shadow-xl mt-1 max-h-60 w-full overflow-auto  bg-white  text-xs  z-[1300]   2xl:text-xs xl:text-[10px] lg:text-[8px]  ${
                    openDirection() === 'top' ? 'bottom-[40px]' : 'top-[40px]'
                  }`}
                >
                  {records?.length === 0 ? (
                    <div className="relative cursor-default select-none  text-gray-700 ">
                      Nothing found.
                    </div>
                  ) : (
                    records?.map((recipe_name, id) => (
                      <Combobox.Option
                        key={id}
                        className={({ active }) =>
                          `relative  select-none cursor-pointer   lg:py-[8px] lg:px-4 md:py-[6px] md:px-3 border-b  ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={recipe_name}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate  ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {recipe_name.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }`}
                              >
                                <div className=" lg:h-4 lg:w-3 md:w-1 md:h-2" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              ) : null}
            </Transition>
          </div>
        </Combobox>
      </div>
    </>
  );
};

export default InpDropDown;
