import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import MultiSelectDropDown from '../shared/MultiSelectDropDown';
import AddNewAllergyModal from './AddNewAllergyModal';

const DietSection = ({
  title1,
  view,
  icon,
  title,
  className,
  show,
  heading1,
  Click,
  has_report,
  edit,
  userId,
  qid,
  editabl,
  setCallApi,
  CallApi,
  verification,
  setChangeInDetails,
}) => {
  const [val, setVal] = useState(title1);
  const [editable, setEditable] = useState(false);
  const inputRef = useRef(null);

  const [allergyList, setAllergyList] = useState([]);

  const [selectedAllergies, setSelectedAllergies] = useState([title1]);

  const [openAddModal, setOpenAddModal] = useState(false);

  const [save, setSave] = useState(true);

  useEffect(() => {
    if (heading1 === 'Known Allergy') {
      if (title1?.indexOf(',') === -1) {
        setSelectedAllergies([title1]);
      } else {
        let allergy = title1?.split(',');
        allergy = allergy?.map((ele) => ele?.trim());
        // console.log("allergy", allergy, title1)
        setSelectedAllergies(allergy);
      }
      localStorage.setItem('allergy', selectedAllergies?.join(', '));
    } else if (heading1 === 'Medical Condition') {
      localStorage.setItem('medicalCondition', title1);
    }
  }, [title1]);

  useEffect(() => {
    if (selectedAllergies?.length == 0) {
      setSelectedAllergies(['None']);
    } else if (selectedAllergies?.length > 1 && selectedAllergies[0] === 'None') {
      setSelectedAllergies((old) => old.slice(1));
    }
  }, [selectedAllergies]);

  // useEffect(() => {
  //   if (heading1 === "Known Allergy") {
  //     const allergy = title1?.split(",");
  //     console.log("allergy", allergy, title1)
  //     setSelectedAllergies(allergy);
  //   }
  // }, [heading1])

  useEffect(() => {
    setVal(title1);
  }, [title1]);
  // useEffect(() => {
  //   const resizeNormalFont = () => {
  //     // Resolution-font size pairs
  //     const r1 = 1728 * 1117;
  //     const f1 = 16;
  //     const r2 = 1680 * 1080;
  //     const f2 = 14;

  //     const currentResolution = window.innerWidth * window.innerHeight;

  //     // linear interpolation formula
  //     const fontSize = f1 + ((f2 - f1) * (currentResolution - r1)) / (r2 - r1);
  //     const clampedFontSize = Math.max(Math.min(fontSize, f1), f2);

  //     // get elements with the class and apply font size
  //     const elements = document.getElementsByClassName('normalFont');
  //     for (let i = 0; i < elements.length; i++) {
  //       elements[i].style.fontSize = `${clampedFontSize}px`;
  //     }
  //   };

  //   const resizeHeaderFont = () => {
  //     // Resolution-font size pairs
  //     const r1 = 1728 * 1117;
  //     const f1 = 20;
  //     const r2 = 1680 * 1080;
  //     const f2 = 18;

  //     const currentResolution = window.innerWidth * window.innerHeight;

  //     // linear interpolation formula
  //     const fontSize = f1 + ((f2 - f1) * (currentResolution - r1)) / (r2 - r1);
  //     const clampedFontSize = Math.max(Math.min(fontSize, f1), f2);

  //     // get elements with the class and apply font size
  //     const elements = document.getElementsByClassName('headerFont');
  //     for (let i = 0; i < elements.length; i++) {
  //       elements[i].style.fontSize = `${clampedFontSize}px`;
  //     }
  //   };

  //   const resizeSmallFont = () => {
  //     // Resolution-font size pairs
  //     const r1 = 1728 * 1117;
  //     const f1 = 12;
  //     const r2 = 1680 * 1080;
  //     const f2 = 12;

  //     const currentResolution = window.innerWidth * window.innerHeight;

  //     // linear interpolation formula
  //     const fontSize = f1 + ((f2 - f1) * (currentResolution - r1)) / (r2 - r1);
  //     const clampedFontSize = Math.max(Math.min(fontSize, f1), f2);

  //     // get elements with the class and apply font size
  //     const elements = document.getElementsByClassName('smallFont');
  //     for (let i = 0; i < elements.length; i++) {
  //       elements[i].style.fontSize = `${clampedFontSize}px`;
  //     }
  //   };

  //   window.addEventListener('resize', resizeNormalFont);
  //   window.addEventListener('resize', resizeHeaderFont);
  //   window.addEventListener('resize', resizeSmallFont);
  //   resizeNormalFont(); // initialize font size
  //   resizeHeaderFont();
  //   resizeSmallFont();
  //   return () => {
  //     window.removeEventListener('resize', resizeNormalFont);
  //     window.removeEventListener('resize', resizeHeaderFont);
  //     window.removeEventListener('resize', resizeSmallFont);
  //   };
  // }, []);

  const valueChanged = () => {
    let data = {
      user_id: userId,
      answer: val,
      question_id: qid,
    };

    let oldData = localStorage.getItem('medicalCondition');
    if (!oldData) {
      localStorage.setItem('medicalCondition', title1);
      oldData = localStorage.getItem('medicalCondition');
    }
    if (val === oldData) {
      return;
    }

    localStorage.setItem('medicalCondition', val);

    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/edit_filter_conditions`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const data1 = response.data;
        if (data1) {
          toast.success(data1.message);
          setChangeInDetails(true);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (heading1 === 'Known Allergy') {
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/allergies`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then(
          ({ data }) => {
            data = data?.data?.map((obj) => obj?.name);
            setAllergyList(data);
          },
          (err) => {}
        )
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [heading1, openAddModal]);

  const saveChange = () => {
    // console.log("focused")
    // debugger;
    setEditable(false);

    let answer = selectedAllergies?.map((allergy) => allergy).join(', ');
    let oldData = localStorage?.getItem('allergy');
    if (!oldData) {
      localStorage.setItem('allergy', selectedAllergies?.join(', '));
      oldData = localStorage?.getItem('allergy');
    }
    if (!save) {
      if (oldData != undefined) {
        let allergy = oldData?.split(',');
        allergy = allergy?.map((ele) => ele?.trim());
        // console.log("allergy", allergy, title1)
        setSelectedAllergies(allergy);
      } else {
        // console.log("focused ")
        if (title1?.indexOf(',') === -1) {
          setSelectedAllergies([title1]);
        } else {
          let allergy = title1?.split(',');
          allergy = allergy?.map((ele) => ele?.trim());
          // console.log("allergy", allergy, title1)
          setSelectedAllergies(allergy);
        }
      }
      setSave(true);
      return;
    }
    if (answer?.trim()?.length < 1) {
      answer = '';
    }
    // if (((answer == title1 || answer == oldData) && answer != "None") || (answer === "None" && oldData === "None")) {
    //   console.log("focused", answer, "  |", oldData, " | ", title1)
    //   return;
    // }
    if (answer == oldData) {
      return;
    } else {
      let data = {
        user_id: userId,
        answer: answer,
        question_id: qid,
      };
      localStorage.setItem('allergy', answer);
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/edit_filter_conditions`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          const data1 = response.data;
          if (data1) {
            toast.success(data1.message);
            setChangeInDetails(true);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const closeAddModal = () => {
    setOpenAddModal(false);
  };

  const addNew = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <div className={`flex   ${className}  break-all break-words`}>
        <div className="flex flex-col justify-center 2xl:py-[10px] max-w-[335px] 2xl:px-5 xl:px-[17.78px] xl:py-3  lg:px-[14.81px] lg:py-[11px] md:py-3  md:pl-2 ">
          {/* <div style={{ display: 'inline-block' }}> */}
          <div className="flex items-center gap-1">
            {show ? (
              <div style={{ display: 'inline-block' }}>
                <h1 className="2xl:text-xl xl:text-[17px] lg:text-[14.81px] font-medium headerFont break-words">
                  {show}
                </h1>
              </div>
            ) : (
              <div style={{ display: 'inline-block' }}>
                <h1
                  className={`2xl:text-base xl:text-sm lg:text-[11.85px] font-Regular text-body-color-2 normalFont whitespace-nowrap ${
                    heading1 == 'Known Allergy' ? 'lg:-mb-1 xl:mb-0' : ''
                  }`}
                >
                  {heading1}
                </h1>
              </div>
            )}
            <div className={`${heading1 == 'Known Allergy' ? 'lg:-mb-1 xl:mb-0' : ''}`}>
              {verification?.can_create && editabl && icon && (
                <Edit
                  className="lg:w-[9px] lg:h-[9px] cursor-pointer"
                  onClick={() => {
                    if (heading1 !== 'Known Allergy') {
                      setEditable(true);
                      setTimeout(() => {
                        inputRef.current.focus();
                      }, 500);
                    } else {
                      setEditable(true);
                    }
                  }}
                />
              )}
            </div>
            {/* </div> */}
          </div>
          <span className="2xl:text-xs 2xl:leading-[18px] xl:text-[10.67px] lg:text-[8.89px] text-body-color-2 2xl:mt-[8px] mt-[6px] smallFont whitespace-nowrap">
            {title}
          </span>
          {view ? (
            <div
              className={`flex items-center font-medium  2xl:text-base xl:text-sm lg:text-[11.85px] text-tint-1 normalFont ${
                has_report ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
              onClick={Click}
            >
              {view}
            </div>
          ) : (
            <div className="flex">
              {heading1 === 'Known Allergy' ? (
                <>
                  <MultiSelectDropDown
                    open={editable}
                    allergyList={allergyList}
                    selectedAllergies={selectedAllergies}
                    setSelectedAllergies={setSelectedAllergies}
                    saveChange={saveChange}
                    addNew={addNew}
                  />
                </>
              ) : editable ? (
                <input
                  type="text"
                  ref={inputRef}
                  value={val}
                  onChange={(e) => {
                    setVal(e.target.value);
                  }}
                  onFocus={() => {
                    if (val === 'No Medical Condition' || val === 'None') {
                      setVal('');
                    }
                  }}
                  className="items-center break-words border-b font-Medium 2xl:text-base xl:text-sm lg:text-[11.85px] w-full truncate text-secondary-4 normalFont focus:outline-none"
                  onBlur={() => {
                    if (val?.length == 0) {
                      // toast.error('Answer Is Mandatory Feild..!');
                      // inputRef.current.focus();
                      let oldData = localStorage.getItem('medicalCondition');

                      setVal(oldData);
                      return;
                    }
                    setEditable(false);
                    valueChanged();
                  }}
                />
              ) : (
                <span
                  className="items-center border-b font-Medium 2xl:text-base xl:text-sm lg:text-[11.85px] w-full text-secondary-4 normalFont text-ellipsis break-words"
                  title={val}
                >
                  {typeof val === 'string' && val.length > 20
                    ? val.split(' ').slice(0, 10).join(' ') + ' ......'
                    : val}
                </span>
              )}
            </div>
          )}
        </div>
        <AddNewAllergyModal
          isOpen={openAddModal}
          isClose={closeAddModal}
          setSelectedAllergies={setSelectedAllergies}
          setSave={setSave}
        />
      </div>
    </>
  );
};

export default DietSection;
