import React, { useMemo, useState, useEffect } from 'react';
import pdfjsVersion from 'pdfjs-dist/package.json';
import { Worker, Viewer, ViewMode, SpecialZoomLevel, ProgressBar } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { selectionModePlugin, SelectionMode } from '@react-pdf-viewer/selection-mode';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import { HashLoader } from 'react-spinners';
import axios from 'axios';

export default function DnaReportPDF({ url }) {
  const zoomPluginInstance = zoomPlugin({
    enableShortcuts: true,
  });
  const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;
  const selectionModePluginInstance = selectionModePlugin({
    selectionMode: SelectionMode.Hand,
  });
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;

  const renderLoader = (percentages) => (
    <div style={{ width: '240px' }}>
      <ProgressBar progress={Math.round(percentages)} />
    </div>
  );

  return (
    <div className="content-rda">
      <div
        className="dna-reports p-7"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
          <ZoomIn>
            {(props) => (
              <button
                type="button"
                className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                onClick={props.onClick}
              >
                Zoom In
              </button>
            )}
          </ZoomIn>
          <ZoomOut>
            {(props) => (
              <button
                type="button"
                className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                onClick={props.onClick}
              >
                Zoom Out
              </button>
            )}
          </ZoomOut>
          <Zoom>
            {(props) => (
              <button
                type="button"
                className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                onClick={() => props.onZoom(SpecialZoomLevel.PageFit)}
              >
                Reset Zoom
              </button>
            )}
          </Zoom>
        </div>
        <div className="dnas-report" style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: '200px',
              borderRight: '1px solid #ddd',
              overflowY: 'auto',
            }}
          >
            <Thumbnails />
          </div>

          <div
            style={{ flex: 1, overflowY: 'auto' }}
            // onWheel={handleScrollZoom}
          >
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={url}
                // fileUrl="https://prod-client-api-bucket.s3.ap-south-1.amazonaws.com/unlockFit_1683013538590.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYEKI5ZAKLQDRD23X/20250110/ap-south-1/s3/aws4_request&X-Amz-Date=20250110T085434Z&X-Amz-Expires=100000&X-Amz-Signature=4f41bf9e27be891cfabb2174471531651c5960fea65114215193d8de6090c7a2&X-Amz-SignedHeaders=host&response-content-disposition=inline&response-content-type=application/pdf"
                viewMode={ViewMode.SinglePage}
                defaultScale={SpecialZoomLevel.PageFit}
                plugins={[thumbnailPluginInstance, zoomPluginInstance, selectionModePluginInstance]}
              />
            </Worker>
          </div>
        </div>
      </div>
    </div>
  );
}
