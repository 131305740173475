import React, { useEffect, useState, useRef, useMemo } from 'react';
import _ from 'underscore';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as Search } from '../../assets/images/ic_search.svg';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment } from 'react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';
import { Menu, Combobox, Transition } from '@headlessui/react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SortIcon from '../../assets/images/des.png';
import { useNavigate } from 'react-router';
import { sortOrganizations } from '../../Utils/SortSearch';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import classNames from 'classnames';

const columnHelper = createColumnHelper();

export default function CustomerList() {
  const navigate = useNavigate();

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const openedJson = localStorage.getItem('setPage');
  const lastName = localStorage.getItem('setNameForSearch');

  const [indexbutton, setIndexButton] = useState([
    {
      id: 1,
    },
  ]);
  const [show, setShow] = useState(false);
  const [SearchQuery, setSearchQuery] = useState('');
  const [showProd, setShowProd] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState('All Organization');
  const [organizations, setOrganizations] = useState([]);
  const [FilterProduct, setFilterProduct] = useState([]);
  const [selectedProd, setSelectedProd] = useState('');
  const [Orgid, setOrgid] = useState('');
  const [ProdId, setProdId] = useState('');
  const [sorting, setSorting] = useState([]);
  const [TotalCount, setTotalCount] = useState({ final: '', showing: '' });
  const [SearchInput, setSearchInput] = useState(lastName ? lastName : '');
  const [currentIndex, setCurrentIndex] = useState(openedJson ? Number(openedJson) : 1);
  const initialRows = Array(12).fill({
    client_id: -1,
    client_name: -1,
    client_email: -1,
    client_phone: -1,
    created_on: -1,
    plan_name: -1,
    org_name: -1,
    source: -1,
  });
  const [Data, setData] = useState({
    columns: [
      { id: 0, column_name: 'Client ID', map_column: 'client_id', type: 'string' },
      { id: 1, column_name: `Client Name`, map_column: 'client_name', type: 'string' },
      { id: 1, column_name: 'Client Email', map_column: 'client_email', type: 'string' },
      { id: 1, column_name: 'Client Phone', map_column: 'client_phone', type: 'string' },
      { id: 1, column_name: 'Plan Name', map_column: 'plan_name', type: 'string' },
      { id: 1, column_name: 'Creation Date', map_column: 'created_on', type: 'string' },
      { id: 1, column_name: 'Source', map_column: 'source', type: 'string' },
      { id: 1, column_name: 'Organization', map_column: 'org_name', type: 'string' },
    ],
    rows: initialRows,
  });

  useEffect(setPagination, [TotalCount.final]);

  function setPagination() {
    if (Data.length <= 0 || TotalCount.final <= 0) {
      return;
    }
    let no_of_pages = Math.ceil(TotalCount.final / 100);
    // console.log('no_of_pages', totalCount, data.length, no_of_pages);
    setIndexButton(
      Array(no_of_pages)
        .fill()
        .map((_, index) => ({ id: index + 1 }))
    );

    setCurrentIndex(openedJson ? Number(openedJson) : 1);
  }

  const columns = useMemo(() => {
    const { columns } = Data;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span
              className={`${
                column.map_column === 'client_email' || column.map_column === 'client_name'
                  ? 'break-all'
                  : 'capitalize'
              }`}
            >
              {info.getValue()}
            </span>
          ),
        header: () => (
          <div>
            <span className="">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Data]);

  useEffect(() => {
    const scrollToId = localStorage.getItem('scrollToId');
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        console.log('Scrolling to element:', scrollToId);
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        localStorage.removeItem('scrollToId');
      } else {
        console.log('Element with ID', scrollToId, 'not found in DOM.');
      }
    }
  }, [Data]);

  const table = useReactTable({
    data: Data.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const FetchCustomer = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/v2/customer?page=${currentIndex}&size=100&org_id=${Orgid}&search=${SearchInput}&plan_id=${ProdId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const storeActivity = response.data.data[0];
        setData((prevLog) => ({
          ...prevLog,
          rows: storeActivity.customers,
        }));
        setTotalCount({
          ...TotalCount,
          final: storeActivity.total_count,
          showing: storeActivity.given_records,
        });
      })
      .catch((error) => {
        if (error.response.data.code === '500') {
          localStorage.clear();
          navigate('/login');
        }
      });
  };

  useEffect(() => {
    FetchCustomer();
    setData((prevLog) => ({
      ...prevLog,
      rows: initialRows,
    }));
  }, [SearchInput, Orgid, ProdId]);

  useEffect(() => {
    FetchCustomer(); // Keep this separate if you want FetchCustomer to run for other dependencies
  }, [currentIndex]);

  const fetchProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/v2/customer/products?org_id=${Orgid}&plan_name=${SearchQuery}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const storeActivity = response.data.data;
        const newObject = { plan_id: '', plan_name: 'All' };
        storeActivity.unshift(newObject);
        setFilterProduct(storeActivity);
      })
      .catch((error) => {
        setFilterProduct([]);
        setSelectedProd('');
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [Orgid, SearchQuery]);

  const FetchOrganization = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/getOrgList`, {
        headers: headers,
      })
      .then((res) => {
        let { data: response } = res;

        if (response.code && response.code === '401') {
          window.location.assign('/auth-token');
        }

        if (response.result && response.result.toLowerCase() === 'success') {
          response.data.sort(sortOrganizations);
          response.data.push({
            is_archived: false,
            created_at: '',
            updated_at: '',
            id: '',
            org_name: 'ALL Organization',
            logo_url: '',
            website: 'ALL',
            auth_domain: 'ALL',
            org_code: '',
            mou_url: '',
            schedule_id: null,
            is_deleted: 1,
            naf_id: null,
          });
          setOrganizations(response.data);
        }
      })
      .catch((err) => {
        if (err.response.data.code === '401' || err.response.data.message === 'Invalid token !!') {
          // localStorage.clear();
          // navigate('/login');
          // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
        }
      });
  };

  useEffect(() => {
    FetchOrganization();
  }, []);

  const scroll = { y: 0 };
  function handleScroll(e) {
    scroll.y = e.target.scrollTop;
  }
  const [open, setOpen] = useState(false);

  const tableHeader = useRef(null);
  const tableFilter = useRef(null);
  const headingRef = useRef(null);
  const handleResize = () => {
    let tableHeaderHeight = 0;
    let tableFilterHeight = 0;
    if (tableHeader.current) {
      tableHeaderHeight = tableHeader.current.getBoundingClientRect().height;
    }
    if (tableFilter.current) {
      tableFilterHeight = tableFilter.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      '--table-header-height',
      tableHeaderHeight + tableFilterHeight
    );
    let headerHeight = 0;
    if (headingRef.current) {
      headerHeight = headingRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--table-heading-height', headerHeight);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector('.active-button');
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [currentIndex]);

  return (
    <div>
      <PagesHeader
        name={'Customer Dashboard'}
        //   array={<ArrowBack className="cursor-pointer" />}
        secondHeading={'Customer Dashboard'}
        thirdHeading={'Customer Dashboard'}
      />
      <div className="px-5 bg-white shadow-card-shadow rounded-medium table-card">
        {/* <div ref={tableHeader} className="items-center border-b divide-y ">
          <div className=" font-Regular   xl:text-2xl lg:text-xl md:text-[12px] md:leading-5 text-body-color-2   py-[10px] md:py-2  ">
            List Of Customer
          </div>
        </div> */}
        <div
          ref={tableFilter}
          className=" xl lg:pt-[10px] md:pt-2 justify-between flex flex-wrap gap-3"
        >
          <div className="flex gap-1">
            <div>
              <Menu as="div" className="relative inline-block text-right " open={open}>
                <div>
                  <Menu.Button className="  w-auto justify-center  2xl:py-[10px] 2xl:px-3 lg:py-[13px] lg:px-[10px] md:py-[4px] md:px-[6px]  shadow-input-shadow border border-secondary-3 2xl:rounded-small lg:rounded-lg md:rounded-md hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    <div
                      className="flex justify-between items-center "
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      <div>
                        <span className=" lg:w-[135px] md:w-[90px] justify-start flex  2xl:text-base xl:text-sm lg:text-xs text-body-color-2 ">
                          <span
                            className=" text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden"
                            style={{ width: '95%' }}
                          >
                            {selectedOrg}
                          </span>
                        </span>
                      </div>
                      <div>
                        <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
                      </div>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    onFocus={(e) => (e.target.scrollTop = scroll.y)}
                    onScroll={(e) => handleScroll(e)}
                    className=" overflow-y-scroll absolute z-10  mt-[2px] 2xl:w-[200px] 2xl:h-[220px]  lg:w-[170px] lg:h-[185px] md:w-[130px] md:h-[150px] origin-top-center  divide-gray-100 rounded-md  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className=" md:px-[2px] md:py-[2px] ">
                      {organizations.map((organization, index) => {
                        return (
                          <>
                            <Menu.Item
                              key={index}
                              className="text-ellipsis whitespace-nowrap w-full border-b"
                            >
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    // console.log(organization.org_name, organization.id);
                                    setOrgid(organization.id);
                                    setProdId('');
                                    setSelectedProd('All');
                                    setSelectedOrg(organization.org_name);
                                    setSearchInput('');
                                    if (organization.id !== Orgid) {
                                      setFilterProduct([]);
                                      setCurrentIndex(1);
                                      localStorage.setItem('setPage', 1);
                                    }
                                  }}
                                  className={`${
                                    active
                                      ? ' duration-150 hover:bg-shades-2/20 hover:text-black'
                                      : 'text-gray-900'
                                  }  flex w-full items-start justify-center 2xl:rounded-md  md:rounded  md:px-1   `}
                                >
                                  <button
                                    className={classNames(
                                      'flex text-left w-full text-ellipsis   whitespace-nowrap overflow-hidden justify-start my-1  items-center'
                                    )}
                                  >
                                    <span
                                      className={`${
                                        index === organizations.length - 1 && ' text-blue-500  '
                                      } ${
                                        organization.org_name === selectedOrg && ' text-shades-2 '
                                      } hover:text-black text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden   2xl:text-base xl:text-sm lg:text-xs md:font-normal`}
                                      style={{ width: '95%' }}
                                    >
                                      {organization.org_name}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </Menu.Item>
                          </>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div>
              <Combobox
                value={selectedProd !== 'All' ? selectedProd : ''}
                open={showProd}
                onChange={(data) => {
                  setProdId(data.plan_id);
                  setSelectedProd(data.plan_name);
                  setSearchQuery('');
                }}
              >
                <div className="relative">
                  <div
                    className="relative flex w-full items-center text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] 2xl:py-[10px] 2xl:px-3 lg:py-[13px] lg:px-[10px] md:py-[4px] md:px-[6px]  "
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                  >
                    <Combobox.Input
                      displayValue={(data) => (selectedProd !== 'All' ? selectedProd : '')}
                      onChange={(event) => {
                        setSearchQuery(event.target.value);
                      }}
                      placeholder={
                        FilterProduct.length !== 0 ? 'Select an Plan' : 'Select an Organization'
                      }
                      className="w-full outline-none 2xl:text-[16px] xl:text-[14px] lg:text-[12px] text-[#666666] mr-6"
                      autocomplete="off"
                    />
                    {FilterProduct.length !== 0 && (
                      <Combobox.Button
                        onClick={(e) => {
                          if (FilterProduct.length !== 0) setShowProd(!showProd);
                        }}
                        className="absolute top-0 bottom-0 right-3"
                      >
                        <UpArray
                          className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
                        />
                      </Combobox.Button>
                    )}
                  </div>
                  <Transition
                    as={Combobox.Options}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    style={{
                      borderRadius: '0px 0px 10px 10px',
                      boxShadow: '4px 4px 8px -2px #0000001F',
                    }}
                    className={`absolute max-h-60 z-10 w-full overflow-auto font-normal bg-white 2xl:text-[16px] xl:text-[14px] lg:text-[12px]`}
                    afterLeave={() => {
                      setShowProd(!open);
                      setSearchQuery('');
                    }}
                  >
                    <>
                      {FilterProduct?.map((data, id) => (
                        <Combobox.Option
                          key={id}
                          className={({ active }) =>
                            `  select-none cursor-pointer 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] border-b  ${
                              active ? 'bg-[#D2A62C] text-[#FFFFFF]' : 'text-body-color-2'
                            }`
                          }
                          value={data}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate `} title={data.plan_name}>
                                {data.plan_name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-teal-600'
                                  }`}
                                  title={data.plan_name}
                                >
                                  <div
                                    className=" lg:h-4 lg:w-3 md:w-1 md:h-2"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
          <div className="relative flex ">
            <div className="flex items-center ">
              <Search className="absolute left-[10px] " />
              <input
                placeholder="Search by Name or email id"
                value={SearchInput}
                className=" pl-[36px] py-[10px] h-[48px] shadow--shadow border outline-none border-secondary-3  lg:rounded-lg md:rounded-md   lg:w-[223px] xl:w-[267px] 2xl:w-[300px] 2xl:text-base xl:text-sm lg:text-xs  md:text-[9px] "
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setCurrentIndex(1);
                  localStorage.setItem('setPage', 1);
                  localStorage.setItem('setNameForSearch', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={`lg:mt-[5px] table-client`}>
          {Data.rows.length > 0 ? (
            <>
              <div className="tableFixHead">
                <table className="min-w-[-webkit-fill-available] client-table table-margin ">
                  <thead ref={headingRef} className="sticky top-0 items-center thead">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="overflow-hidden flex ">
                        {headerGroup.headers.map((header, index, array) => {
                          return (
                            <th
                              key={header.id}
                              className={`text-start col-${index}-cust bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-5 last-of-type:border-r-0  py-[9px]  font-medium   2xl:text-xl xl:text-sm lg:text-xs  ${
                                header.id === 'Email Address' && ''
                              }`}
                              onClick={
                                header.id === 'client_email'
                                  ? header.column.getToggleSortingHandler()
                                  : console.log('not allowed')
                              }
                            >
                              <div className="flex items-center justify-between w-full">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header)}
                                {header.id === 'client_id' ||
                                header.id === 'client_name' ||
                                header.id === 'client_phone' ||
                                header.id === 'created_on' ||
                                header.id === 'org_name' ||
                                header.id === 'plan_name' ||
                                header.id === 'source'
                                  ? null
                                  : {
                                      asc: (
                                        <img
                                          src={SortIcon}
                                          // width={20}
                                          // height={20}
                                          className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          style={{ transform: 'rotate(180deg)' }}
                                          alt="SortIcon"
                                        />
                                      ),
                                      desc: (
                                        <img
                                          src={SortIcon}
                                          className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          alt="SortIcon"
                                        />
                                      ),
                                      false: (
                                        <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                      ),
                                    }[header.column.getIsSorted()]}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>

                  <tbody className="duration-1000 tbody-cust">
                    {table.getRowModel().rows.map((row, ind) => (
                      <tr
                        key={row.id}
                        id={`row-${row.id}`}
                        className="cursor-pointer clienttable_row even:bg-secondary-3/20 mb-1 flex w-full"
                        onClick={() => {
                          let userId = row.original.id;
                          navigate(`/customerDetails/${userId}`);
                          localStorage.setItem('setPage', currentIndex);
                          localStorage.setItem('scrollToId', `row-${row.id}`);
                        }}
                      >
                        {row.getVisibleCells().map((cell, ind) => (
                          <td
                            key={cell.id}
                            className={`duration-1000 text-left col-${ind}-cust lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3 text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-sm xl:text-xs lg:text-xs  `}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-between md:py-3 text-secondary-1  ">
                <div className="flex ">
                  <span className="text-body-color-1 font-Regular 2xl:text-base lg:text-xs">
                    Showing {TotalCount.showing} of {TotalCount.final} entries
                  </span>
                </div>
                <div className="flex items-center max-w-[50%]">
                  <button
                    disabled={currentIndex === 1}
                    className={`${
                      currentIndex !== 1 ? ' hover:bg-shades-2  hover:text-white ' : 'diabledBtnBg '
                    }duration-200 buttons border first-of-type:rounded-s-[10px]   2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex > 1) {
                        setCurrentIndex(currentIndex - 1);
                        localStorage.setItem('setPage', currentIndex - 1);
                      }
                    }}
                  >
                    Previous
                  </button>
                  <div
                    className="flex overflow-auto"
                    ref={scrollContainerRef}
                    style={{ scrollSnapType: 'x proximity' }}
                  >
                    {indexbutton.map((value, id) => (
                      <button
                        key={id}
                        className={`${
                          currentIndex === value.id
                            ? ' bg-shades-2 text-white duration-1000 active-button'
                            : 'hover:bg-gray-100 duration-300'
                        }  duration-200    buttons border-t border-b  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                        onClick={() => {
                          setCurrentIndex(value.id);
                          localStorage.setItem('setPage', value.id);
                        }}
                        style={{
                          scrollSnapAlign: currentIndex === value.id ? 'start' : 'none',
                        }}
                      >
                        <div className="flex justify-center items-center 2xl:w-[16px] 2xl:h-[24px] xl:w-[19.18px] xl:h-[18px] lg:w-[16px] lg:h-[18px]">
                          {value.id}
                        </div>
                      </button>
                    ))}
                  </div>
                  <button
                    disabled={currentIndex === indexbutton.length}
                    className={`${
                      currentIndex !== indexbutton.length
                        ? ' hover:bg-shades-2  hover:text-white '
                        : 'diabledBtnBg'
                    } duration-200    border nxtbutton  last-of-type:rounded-e-[10px]  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex < indexbutton.length) {
                        setCurrentIndex(currentIndex + 1);
                        localStorage.setItem('setPage', currentIndex + 1);
                      }
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-full">
              <ClientError className="w-full h-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
