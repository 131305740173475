import React, { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ConfirmDelete = ({
  OpenError,
  setOpenError,
  Access,
  setOtpVerfiy,
  setSent,
  setNumber,
  setselectedCountryCode,
  setName,
  setOTP,
}) => {
  let isLoggingIn = false;

  const deleteAccount = () => {
    if (isLoggingIn) {
      return;
    }
    isLoggingIn = true;
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/user/remove`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Access}`,
        },
      })
      .then((response) => {
        const Data = response.data;
        setOpenError(false);
        setOtpVerfiy(false);
        setSent(false);
        setselectedCountryCode({ code: '+91', name: 'India' });
        setNumber('');
        setName('');
        setOTP('');
        toast.success('All the details associated with this account has been deleted');
      })
      .catch((error) => {
        const Data = error.response.data;
        setOpenError(false);
        toast.error(Data.message);
      })
      .finally(() => {
        isLoggingIn = false;
      });
  };
  return (
    <Transition appear show={OpenError} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99999]"
        onClose={() => {
          // setOpenError(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[90%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Close
                  className="absolute cursor-pointer right-2 top-2"
                  onClick={() => {
                    setOpenError(false);
                  }}
                /> */}
                <div className={`flex justify-center items-center flex-col`}>
                  <br />
                  <div className="flex items-center justify-center flex-col">
                    <span className="text-[15px] text-[#575757] text-center">
                      You will lose all your health-related data on the app permanently and can't be
                      restored. Are you sure you want to delete your account?
                    </span>
                    <br />
                    <div className="flex justify-between w-full">
                      <button
                        onClick={() => {
                          setOpenError(false);
                        }}
                        className="p-[5px] w-full rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                      >
                        Cancel
                      </button>

                      <button
                        onClick={() => {
                          deleteAccount();
                        }}
                        className="p-[5px] w-full ml-3 rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
