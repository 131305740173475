import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { OinformationData, Details, Cuisine } from './Customedata';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../Utils/Authenticate';

const HealthAssessmentModal = ({ OpenIs, CloseIS, userId }) => {
  const [dietInfo, setDietInfo] = useState([]);
  const [cuisineInfo, setCuisineInfo] = useState([]);
  const [otherInfo, setOtherInfo] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  const userPanelRef = useRef(null);

  const handleResize = () => {
    let userPanelHeight = 0;
    if (userPanelRef.current) {
      userPanelHeight = userPanelRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--user-info', userPanelHeight);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleResize, 30);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // const params = userId.split('/');
    //   const _userId = params[params.length - 1];
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/user/v2/user_naf?id=${userId}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (OpenIs) {
      axios
        .request(config)
        .then(
          ({ data }) => {
            setDietInfo(data?.data[0]?.diet_inforamtion?.diet_preference);
            setCuisineInfo(data?.data[0]?.diet_inforamtion?.cuisine_preference);
            setOtherInfo(data?.data[0]?.other_information);
            setUserDetails(data?.data[0]?.user_details);
          },
          (err) => {
            toast.error(err);
          }
        )
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [OpenIs]);

  const userData = useMemo(() => {
    let data = new Array();
    let keys = Object.entries(userDetails);
    keys = keys.map((arr) => {
      if (arr[0] === 'date_of_birth') {
        arr[0] = 'Date of Birth';
      }
      return arr;
    });
    const idealWeightEntry = keys.find((entry) => entry[0] === 'ideal weight');
    keys = keys.filter((entry) => entry[0] !== 'ideal weight');
    if (idealWeightEntry) {
      keys.push(idealWeightEntry);
    }
    // console.log(keys, 'userdata');
    keys.shift();
    return keys;
  }, [userDetails]);

  return (
    <>
      <div>
        <Transition appear show={OpenIs} as={Fragment}>
          <Dialog className="relative z-10" onClose={CloseIS}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-70" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto ">
              <div className="flex min-h-full items-center justify-center p-4 ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="2xl:w-[1117px] xl:w-[864px] lg:w-[720px] h-[90vh] rounded-2xl bg-white shadow-xl">
                    <Dialog.Title>
                      <div>
                        <div className="flex justify-between items-center">
                          <div></div>
                          <div className="text-shades-2 2xl:text-LargeTitleText xl:text-[18.564px] xl:leading-[28px]  lg:text-[15.47px] lg:leading-[23px] font-Medium 2xl:py-[20px] xl:pt-[15.47px] xl:pb-[15.32px] lg:pt-[12.89px] lg:pb-[13.1px]">
                            Health Assessment Form
                          </div>
                          <div className="pr-10 2xl:h-[24px] 2xl:w-[24px] xl:h-[18.56px] xl:w-[18.56px] lg:h-[15.47px] lg:w-[15.47px]">
                            <Close onClick={CloseIS} className="cursor-pointer" />
                          </div>
                        </div>
                        <div
                          ref={userPanelRef}
                          className="flex justify-between items-center border-b border-t bg-[#E6E6E633] 2xl:px-[40px] 2xl:py-[20px] xl:px-[30px] xl:py-[15px] lg:px-[20px] lg:py-[10px]"
                        >
                          {userData?.map((data, indx) => {
                            return (
                              <>
                                <div
                                  key={indx}
                                  className={`${indx === 0 || indx === 1 ? 'w-[16%]' : ''}`}
                                >
                                  <div className="font-normal capitalize lg:mb-[2.24px] 2xl:text-base xl:text-[12.376px] xl:leading-[19px] lg:text-[10.3133px] lg:leading-[15px] text-[#666666]">
                                    {data[0]}
                                  </div>
                                  <div className="font-medium  2xl:text-base xl:text-[12.376px] xl:leading-[19px] lg:text-[10.3133px] lg:leading-[15px] break-words text-black">
                                    {data[1] ? data[1] : 'NA'}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <div className="2xl:px-10 xl:px-[30.94px] lg:px-[25.78px] overflow-auto content-haf">
                          <div className="2xl:text-SmallBodyDisplayText lg:text-[12.89px] xl:text-[15.47px] lg:leading-[19.43px] xl:leading-[23.21px]  text-body-color-1 font-medium  2xl:pt-5  xl:pt-[15.47px]  lg:pt-[12.89px]">
                            Diet Information
                          </div>

                          <div className="">
                            <div className="flex 2xl:gap-10 xl:gap-[31.55px] lg:gap-[25.8px] items-center mt-5">
                              {dietInfo?.map((value, id) => {
                                return (
                                  <>
                                    <div
                                      className="flex 2xl:gap-5 xl:gap-[15.47px] lg:gap-[12.89px] items-center"
                                      key={id}
                                    >
                                      <input
                                        id="c1"
                                        type="checkbox"
                                        checked={value?.is_checked}
                                        className="2xl:w-[17px] 2xl:h-[17px] xl:w-[13.15px] xl:h-[13.15px] lg:h-[10.96px] lg:w-[10.96px] "
                                        style={{ cursor: 'default' }}
                                      />
                                      <span className="2xl:text-SmallerBodyDisplayText xl:text-[12.376px] lg:text-[10.3133px] font-medium">
                                        {value?.name}
                                      </span>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          <div className=" 2xl:mt-10 xl:mt-[25.98px] lg:mt-[20.78px]">
                            <div className="flex gap-[26px] flex-wrap">
                              {cuisineInfo?.map((val, ind) => {
                                return (
                                  <>
                                    <div className="flex flex-col" key={ind}>
                                      <div className="text-body-color-2 2xl:text-SmallerBodyDisplayText xl:text-[12.376px] lg:text-[10.3133px] font-Regular">
                                        Cuisine Preference {ind + 1}
                                      </div>
                                      <div className="font-medium 2xl:text-SmallBodyDisplayText xl:text-base lg:text-sm text-body-color-1 ">
                                        <span className=" border-b">{val}</span>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>

                          <div className="font-medium 2xl:text-SmallBodyDisplayText lg:text-[12.89px] xl:text-[15.47px] lg:leading-[19.43px] xl:leading-[23.21px] text-body-color-1  2xl:mt-10 xl:mt-[25.98px] lg:mt-[20.78px]">
                            Other Information
                          </div>

                          <div className="2xl:mt-6 xl:mt-[18.81px] lg:mt-[15.81px] flex">
                            <div className="grid grid-cols-4 gap-[26px] w-full">
                              {otherInfo.map((value, id) => {
                                return (
                                  <>
                                    <div className="flex flex-col" key={id}>
                                      <div className="text-body-color-2  2xl:text-SmallerBodyDisplayText xl:text-[12.376px] lg:text-[10.3133px] font-Regular">
                                        {value?.label}
                                      </div>
                                      <div
                                        className="font-medium w-full truncate 2xl:text-base xl:text-[12.376px] xl:leading-[19px] lg:text-[10.3133px] lg:leading-[15px] text-black border-b break-words "
                                        title={value?.answer}
                                      >
                                        {/* {value?.answer?.length > 20
                                          ? value?.answer.slice(0, 24) + '...'
                                          : value?.answer} */}
                                        {value?.answer}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Title>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default HealthAssessmentModal;
